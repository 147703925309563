import FormComponent from "../../../shared/form-component";
import FormConstants from "../../../../constants/form.constants";
import AddressFormComponent from "../address-form-component";
import './index.scss';
import {message} from 'antd';
import {connect, useSelector} from "react-redux";
import {updateVendorFormStateValues} from "../../../../store/actions/vendors.action";
import {addressInitialState} from "../../../../store/reducers/vendors.reducer";

const BusinessContactFormComponent = ({idx, updateVendorFormStateValues}) => {
    const handleChange = ({name, value}) => {
        updateVendorFormStateValues({
            contacts: contacts.map(contact => {
                if (contact.idx === idx) {
                    return {
                        ...contact,
                        [name]: value,
                    }
                } else {
                    return {
                        ...contact,
                    }
                }
            })
        });
    }

    const handlePrimaryContactChange = ({name, value}) => {
        // If any contact is made primary other than idx = 0 move it to the top
        let updatedContacts;
        if (value && idx) {
            updatedContacts = [{
                ...contacts[idx],
                [name]: value,
                idx: 0,
            }, ...contacts.filter(contact => contact.idx !== idx).map((contact, idx) => ({
                ...contact,
                idx: idx + 1,
                isPrimaryContact: false,
                address: contact.isPrimaryContact ?  addressInitialState : contact.address,
            }))];
            message.info('Moved the primary contact to top!');
        } else {
            updatedContacts = contacts.map(contact => {
                if (contact.idx === idx) {
                    return {
                        ...contact,
                        [name]: value,
                    }
                } else {
                    return {
                        ...contact,
                    }
                }
            });
        }
        updateVendorFormStateValues({
            contacts: updatedContacts,
        });
    }

    const handleAddressChange = ({name, value}) => {
        updateVendorFormStateValues({
            contacts: contacts.map(contact => {
                if (contact.idx === idx) {
                    return {
                        ...contact,
                        address: {
                            ...contact.address,
                            [name]: value,
                        }
                    }
                } else {
                    return {
                        ...contact,
                    }
                }
            })
        });
    }

    const {formFields, contactItem, contacts, isEditing} = useSelector(({vendorsState}) => {
        const contactItem = vendorsState.selectedVendorInputs.contacts.find(item => item.idx === idx)
        console.log({contactItem, idx})
        const formFields = [{
            ...FormConstants.fullName,
            onChange: handleChange,
            value: contactItem.title,
            label: 'Title',
            name: 'title',
        }, {
            ...FormConstants.checkbox,
            value: contactItem.isPrimaryContact,
            onChange: handlePrimaryContactChange,
            label: 'Primary contact',
            name: 'isPrimaryContact',
            className: 'center-aligned',
        }, {
            ...FormConstants.fullName,
            value: contactItem.firstName,
            onChange: handleChange,
            label: 'First Name',
            name: 'firstName',
        }, {
            ...FormConstants.fullName,
            value: contactItem.lastName,
            onChange: handleChange,
            label: 'Last Name',
            name: 'lastName',
        }, {
            ...FormConstants.email,
            value: contactItem.email,
            onChange: handleChange,
        }, {
            ...FormConstants.fullName,
            value: contactItem.extension,
            onChange: handleChange,
            name: 'extension',
            label: 'Extension',
            span: 3,
        }, {
            ...FormConstants.phone,
            value: contactItem.phone,
            onChange: handleChange,
            span: 7,
        },  {
            ...FormConstants.website,
            onChange: handleChange,
            value: contactItem.websiteLink,
        }, {
            ...FormConstants.checkbox,
            label: 'Same address as business',
            onChange: handleChange,
            name: 'isAddressSameAsVendor',
            value: contactItem.isAddressSameAsVendor,
            className: 'center-aligned',
        }]

        return {formFields, contactItem, contacts: vendorsState.selectedVendorInputs.contacts, isEditing: vendorsState.isEditing};
    });

    return (
        <div className='business-contact-component'>
            <FormComponent formFields={formFields} colSpan={12} viewOnly={!isEditing}/>
            {
                !contactItem.isAddressSameAsVendor && (
                    <AddressFormComponent onChange={handleAddressChange} address={contactItem.address} />
                )
            }
            <div className="divider light-divider"></div>
        </div>
    )
}

export default connect(null, {
    updateVendorFormStateValues,
})(BusinessContactFormComponent);
