import {
    GET_TOKEN_INFO, GET_TOKEN_INFO_FAIL, GET_TOKEN_INFO_SUCCESS,
    LOGIN, LOGIN_FAIL, LOGIN_SUCCESS, SIGN_OUT, SIGN_OUT_FAIL, SIGN_OUT_SUCCESS, UPDATE_VALUES
} from '../action-types/auth.action-type.js';
import {LocalStorageConstant} from "../../constants/local-storage.constant";

export const updateAuthValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const loginAction = (payload) => {
    return {
        type: LOGIN,
        payload,
    };
};

export const loginSuccessAction = (payload) => {
    // set the value in localstorage and then dispatch the success action
    localStorage.setItem(LocalStorageConstant.AMZWARE_TOKEN, payload.user.token);
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailAction = (payload) => {
    return {
        type: LOGIN_FAIL,
        payload,
    };
};


export const getTokenInfoAction = () => {
    return {
        type: GET_TOKEN_INFO,
    };
};

export const tokenInfoSuccessAction = (payload) => {
    return {
        type: GET_TOKEN_INFO_SUCCESS,
        payload,
    };
};

export const tokenInfoFailAction = (payload) => {
    return {
        type: GET_TOKEN_INFO_FAIL,
        payload,
    };
};


export const signOutAction = () => {
    return {
        type: SIGN_OUT,
    };
};

export const signOutSuccessAction = (payload) => {
    localStorage.removeItem(LocalStorageConstant.AMZWARE_TOKEN);
    return {
        type: SIGN_OUT_SUCCESS,
        payload
    };
};

export const signOutFailAction = (payload) => {
    return {
        type: SIGN_OUT_FAIL,
        payload,
    };
};
