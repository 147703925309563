import {
    GET_LEAD_TIMES_FOR_VENDOR,
    GET_LEAD_TIMES_FOR_VENDOR_FAIL,
    GET_LEAD_TIMES_FOR_VENDOR_SUCCESS,
    POST_LEAD_TIMES_FOR_VENDOR,
    POST_LEAD_TIMES_FOR_VENDOR_FAIL,
    POST_LEAD_TIMES_FOR_VENDOR_SUCCESS,
    RESET_FORM_VALUES,
    UPDATE_FORM_VALUES,
    UPDATE_VALUES,
} from "../action-types/lead-times.action-type";

export const leadTimeInitialState = {
    step: null,
    duration: 0,
    fromLabel: '',
    toLabel: '',
    isFBA: false,
    isFBM: false,
    order: 0,
    idx: 0,
}

const initialState = {
    selectedVendorsLeadTimes: {
        fbm: [],
        fba: [],
    },
    vendorsLeadTimeInputs: {
        fbm: {
            del: [],
            updates: [{...leadTimeInitialState, isFBM: true}]
        },
        fba: {
            del: [],
            updates: [{...leadTimeInitialState, isFBA: true}]
        },
    },
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const LeadTimesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_FORM_VALUES:
            state = { ...state, vendorsLeadTimeInputs: { ...state.vendorsLeadTimeInputs, ...payload} };
            break;
        case RESET_FORM_VALUES:
            state = {
                ...state,
                vendorsLeadTimeInputs: state.selectedVendorsLeadTimes.fba.length || state.selectedVendorsLeadTimes.fbm.length ? {
                    fbm: {
                        del: [],
                        updates: [...state.selectedVendorsLeadTimes.fbm]
                    },
                    fba: {
                        del: [],
                        updates: [...state.selectedVendorsLeadTimes.fba]
                    },
                } : initialState.vendorsLeadTimeInputs,
                isEditing: false
            };
            break;
        case GET_LEAD_TIMES_FOR_VENDOR:
            state = { ...state, loading: true };
            break;
        case GET_LEAD_TIMES_FOR_VENDOR_SUCCESS:
            state = { ...state, selectedVendorsLeadTimes: {fbm: payload.fbm, fba: payload.fba}, vendorsLeadTimeInputs: {
                    fbm: {
                        del: [],
                        updates: [...payload.fbm, {...leadTimeInitialState, idx: payload.fbm.length, isFBM: true}],
                    },
                    fba: {
                        del: [],
                        updates: [...payload.fba, {...leadTimeInitialState, idx: payload.fba.length, isFBA: true}],
                    },
                }, loading: false };
            break;
        case GET_LEAD_TIMES_FOR_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_LEAD_TIMES_FOR_VENDOR:
            state = { ...state, loading: true };
            break;
        case POST_LEAD_TIMES_FOR_VENDOR_SUCCESS:
            state = { ...state, selectedVendorsLeadTimes: payload.rows, loading: false, success: 'lead times for vendor updated successfully', isEditing: false };
            break;
        case POST_LEAD_TIMES_FOR_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default LeadTimesReducer;
