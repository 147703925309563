export default class UsersParserService {
    static parseUser (user) {
        let selectedPermissions = {};
        user.UsersPermissions.filter(perm => perm.Permission).forEach((perm => {
            selectedPermissions = {
                ...selectedPermissions,
                [perm.Permission.name]: selectedPermissions[perm.Permission.name]
                    ? [...selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    : [perm.AccessType.name]
            }
        }));
        console.log({selectedPermissions})
        return {
            ...user,
            selectedPermissions,
            groups: user.UserGroups.map((group => ({
                key: group.id,
                value: group.id,
                label: group.name,
            }))),
            roles: user.Roles.map((role => ({
                key: role.id,
                value: role.id,
                label: role.name,
            }))),
        }
    }

    static parseUsersPermissionList (UsersPermissions) {
        let parsedUsersPermissionMap = {};
        UsersPermissions.filter(perm => perm.Permission).forEach((perm => {
            parsedUsersPermissionMap = {
                ...parsedUsersPermissionMap,
                [perm.User.id]: parsedUsersPermissionMap[perm.User.id] ? {
                    ...parsedUsersPermissionMap[perm.User.id],
                    selectedPermissions: {
                        [perm.Permission.name]: [...parsedUsersPermissionMap[perm.User.id].selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    }
                } :  {
                    User: perm.User,
                    selectedPermissions: {
                        [perm.Permission.name]: [perm.AccessType.name]
                    }
                }
            }
        }));
        console.log({parsedUsersPermissionMap})
        return {
            parsedUsersPermissionMap,
        }
    }
}