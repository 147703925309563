import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row} from 'antd'
import PermissionSelector from "../shared/permission-selector/PermissionSelector";


const RoleForm = ({selectedRoleInputs, viewMode, isNew, id, onChangeInput, searchedPermissions, selectedPermissions}) => {
    const permissionChangeHandler = (selectedPermissions) => {
        onChangeInput({
            target: {
                name: 'selectedPermissions',
                value: selectedPermissions
            },
        });
    }

    return (
        <Row gutter={[12, 0]}>
            <Col span={10}>
                <Form.Item label="Name" labelCol={{ span: 24 }}>
                    <Input name="name" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedRoleInputs.name} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Description" labelCol={{ span: 24 }}>
                    <Input name="description" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedRoleInputs.description} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={20}>
                <Form.Item label="Permissions" labelCol={{ span: 24 }}>
                    <PermissionSelector
                        viewMode={viewMode}
                        onChange={permissionChangeHandler}
                        searchedPermissions={searchedPermissions}
                        selectedPermissions={selectedPermissions}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

RoleForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedRoleInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

RoleForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ permissionsState, groupsState, rolesState }) => {
    return {
        selectedRoleInputs: rolesState.selectedRoleInputs,
        searchedPermissions: permissionsState.searchedPermissions,
        selectedPermissions: rolesState.selectedRoleInputs.selectedPermissions,
    }
}

export default connect(mapStateToProps)(RoleForm)
