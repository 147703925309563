import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_CATEGORY,
    PUT_CATEGORY,
    GET_CATEGORIES,
    POST_CATEGORY,
} from '../action-types/category.action-type';

import CategoryBackendApiService from "../../services/backend-api/category.backend-api.service";
import {
    createNewCategoryFailAction,
    createNewCategorySuccessAction,
    getCategoryDetailsFailAction,
    getCategoryDetailsSuccessAction,
    getCategoriesListFailAction,
    getCategoriesListSuccessAction,
    updateCategoryDetailsFailAction,
    updateCategoryDetailsSuccessAction,
} from "../actions/category.action";

function* categoryDetails({payload}) {
    try {
        const response = yield call(CategoryBackendApiService.getCategoryDetail, payload);
        if (response.success) {
            yield put(getCategoryDetailsSuccessAction(response.data));
        } else {
            yield put(getCategoryDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getCategoryDetailsFailAction(error.response));
    }
}


function* getCategoriesList({payload}) {
    try {
        const response = yield call(CategoryBackendApiService.getCategoriesList, payload);
        if (response.success) {
            yield put(getCategoriesListSuccessAction(response.data));
        } else {
            yield put(getCategoriesListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getCategoriesListFailAction(error.response));
    }
}

function* updateCategoryDetails({payload}) {
    try {
        const response = yield call(CategoryBackendApiService.updateCategoryDetails, payload);
        if (response.success) {
            yield put(updateCategoryDetailsSuccessAction(response));
        } else {
            yield put(updateCategoryDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateCategoryDetailsFailAction(error.response));
    }
}


function* createNewCategory({payload}) {
    try {
        console.log({payload});
        const response = yield call(CategoryBackendApiService.createNewCategory, payload);
        if (response.success) {
            yield put(createNewCategorySuccessAction(response.data));
        } else {
            yield put(createNewCategoryFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewCategoryFailAction(error.response));
    }
}


function* CategorySaga() {
    yield takeLatest(GET_CATEGORY, categoryDetails);
    yield takeLatest(GET_CATEGORIES, getCategoriesList);
    yield takeLatest(POST_CATEGORY, createNewCategory);
    yield takeLatest(PUT_CATEGORY, updateCategoryDetails);
}

export default CategorySaga;