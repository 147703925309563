import {
    GET_SUPPLIER,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_SUCCESS,
    GET_SUPPLIERS,
    GET_SUPPLIERS_FAIL,
    GET_SUPPLIERS_SUCCESS,
    POST_SUPPLIER, POST_SUPPLIER_FAIL,
    POST_SUPPLIER_SUCCESS, PUT_SUPPLIER, PUT_SUPPLIER_FAIL, PUT_SUPPLIER_SUCCESS, RESET_FORM_VALUES,
    UPDATE_VALUES,
} from "../action-types/suppliers.action-type";

const initialState = {
    suppliers: [],
    selectedSupplier: {
        firstName: '',
        lastName: '',
        contactFirstName: '',
        contactLastName: '',
        websiteLink: '',
        location: '',
        email: '',
        phone: '',
    },
    selectedSupplierInputs: {
        firstName: '',
        lastName: '',
        contactFirstName: '',
        contactLastName: '',
        websiteLink: '',
        location: '',
        email: '',
        phone: '',
    },
    loading: false,
    error: '',
    success: '',
};

const SuppliersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedSupplierInputs: payload.isNew ? initialState.selectedSupplierInputs : state.selectedSupplier };
            break;
        case GET_SUPPLIER:
            state = { ...state, loading: true };
            break;
        case GET_SUPPLIER_SUCCESS:
            const parsedSupplier = {
                ...payload.supplier,
            }
            state = { ...state, selectedSupplier: parsedSupplier, selectedSupplierInputs: parsedSupplier, loading: false, success: payload.message };
            break;
        case GET_SUPPLIER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_SUPPLIERS:
            state = { ...state, loading: true };
            break;
        case GET_SUPPLIERS_SUCCESS:
            state = { ...state, suppliers: payload.rows, loading: false };
            break;
        case GET_SUPPLIERS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_SUPPLIER:
            state = { ...state, loading: true };
            break;
        case POST_SUPPLIER_SUCCESS:
            state = { ...state, selectedSupplier: payload.supplier, loading: false, success: 'New supplier created successfully!' };
            break;
        case POST_SUPPLIER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_SUPPLIER:
            state = { ...state, loading: true };
            break;
        case PUT_SUPPLIER_SUCCESS:
            state = { ...state, selectedSupplier: initialState.supplier, loading: false, success: 'supplier updated successfully' };
            break;
        case PUT_SUPPLIER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default SuppliersReducer;
