import './index.scss';
import LayoutComponent from "../../shared/layout/Layout";
import {Button, Card, message, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    getVendorsListAction,
    resetVendorInputsValues,
    updateVendorStateValues
} from "../../../store/actions/vendors.action";
import BreadcrumbComponent from "../../../components/shared/breadcrumb/Breadcrumb";

const VendorListingPage = ({
   vendors,
   loading,
   error,
   success,
   getVendorsListAction,
   updateVendorStateValues,
}) => {
    const navigate = useHistory();


    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')


    const handleViewVendor = (id) => {
        console.log(id);
        navigate.push(`/vendors/${id}`);
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/vendors/${id}`} key={id}>
                    {id}
                </Link>
            ),
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (value) => (value || '-')
        }, {
            title: 'Primary Contact Name',
            dataIndex: 'contactName',
            key: 'contact',
            render: (_, row) => row.contacts.length ? (`${row.contacts[0].firstName} ${row.contacts[0].lastName}`) : '-'
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('email')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('phone')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Pending Orders',
            dataIndex: 'pendingOrders',
            key: 'pendingOrders',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('pendingOrders')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Active Orders',
            dataIndex: 'activeOrders',
            key: 'activeOrders',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('activeOrders')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Open Claims',
            dataIndex: 'openClaims',
            key: 'openClaims',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('openClaims')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Credits',
            dataIndex: 'credits',
            key: 'credits',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('credits')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('balance')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Reseller Status',
            dataIndex: 'resellerStatus',
            key: 'resellerStatus',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('resellerStatus')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button className='margin-2' type='primary' onClick={() => handleViewVendor(record.id)}>View Vendor</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Vendor</Button>*/}
                </>
            ),
        }
    ]


    const createNewVendor = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        // resetUserInputsValues(true);
        navigate.push('/vendors/new');
    }

    const actionButtons = [{
        label: 'Add Vendor',
        type: 'primary',
        onClick: createNewVendor,
    }];


    useEffect(() => {
        // Call the API to fetch the vendors list
        getVendorsListAction({
            pageNo,
            pageLimit,
        });
    }, [getVendorsListAction, pageNo, pageLimit]);

    // Show Success / Failure Message
    useEffect(() => {
        if (!loading) {
            if (error) {
                message.error(error).then(() => {
                    updateVendorStateValues({
                        error: '',
                    })
                });
            } else if (success) {
                message.success(success).then(() => {
                    updateVendorStateValues({
                        error: '',
                    })
                });
            }
        }
    }, [loading, error, success, updateVendorStateValues]);

    return (
        <LayoutComponent>
            <div>
                <BreadcrumbComponent actionButtons={actionButtons} />
                <Card size="small">
                    <div className='vendor-listing-page'>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                            dataSource={vendors}
                            columns={columns}
                            loading={loading}
                            rowKey="id"
                            scroll={{x: 2000}}
                        />
                    </div>
                </Card>
            </div>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ vendorsState }) => {
    const {
        vendors, loading, error, success,
    } = vendorsState;

    return {
        vendors,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getVendorsListAction,
    updateVendorStateValues,
    resetVendorInputsValues,
})(VendorListingPage);
