import './index.scss';
import {ProductSearchItem} from "../product-search-item/index";

export const OrderProductCard = () => {

    const fbaMetaData = [{
        label: 'Margin',
        value: '12%',
    },{
        label: 'ROI',
        value: '9.8%',
    },{
        label: 'Cost/ Fees',
        value: '$5.40',
    },{
        label: 'Gross profit',
        value: '$4.50',
    },{
        label: 'Net Profit per sale',
        value: '$1.20',
    }]

    const fbmMetaData = [{
        label: 'Margin',
        value: '12%',
    },{
        label: 'ROI',
        value: '9.8%',
    },{
        label: 'Cost/ Fees',
        value: '$5.40',
    },{
        label: 'Gross profit',
        value: '$4.50',
    },{
        label: 'Net Profit per sale',
        value: '$1.20',
    }]

    const fbaFees = [{
        label: 'Referral',
        value: '12%',
    },{
        label: 'Variable Costing',
        value: '9.8%',
    },{
        label: 'Order handling',
        value: '$5.40',
    },{
        label: 'Weight Handling',
        value: '$4.50',
    },{
        label: 'Pick and Pack',
        value: '$1.20',
    }, {
        label: 'Total Fees',
        value: '$1.20',
    }]

    const fbmFees = [{
        label: 'Referral',
        value: '12%',
    },{
        label: 'Variable Costing',
        value: '9.8%',
    },{
        label: 'Pick and Pack',
        value: '$1.20',
    }, {
        label: 'Total Fees',
        value: '$1.20',
    }]

    const fbaCosts = [{
        label: 'Shipment',
        value: 2,
    },{
        label: 'Bubble Wrap',
        value: 2,
    },{
        label: 'Prep Cost',
        value: 2,
    },{
        label: 'Custom Cost 1',
        value: 2,
    }];

    const fbmCosts = [{
        label: 'Shipment',
        value: 2,
    },{
        label: 'Bubble Wrap',
        value: 2,
    },{
        label: 'Prep Cost',
        value: 2,
    },{
        label: 'Custom Cost 1',
        value: 2,
    }];

    const vendorData = [{
        label: 'item#',
        value: 14832,
    },{
        label: 'Case Quantity',
        value: 2,
    },{
        label: 'Unit Price',
        value: 2,
    },{
        label: 'Case price',
        value: 2,
    }];


    const forecast = [{
        label: 'Weeks of Supply',
        value: 2,
    },{
        label: 'Expiration Date',
        value: 2,
    }];


    const salesData = [{
        label: 'Sellers',
        value: 14832,
    },{
        label: 'Monthly Sales',
        value: 2,
    },{
        label: 'My Sales',
        value: 2,
    }];

    return (
        <div>
            <div className="product-detail-cont margin-8-bottom">
                <ProductSearchItem />
            </div>
            <div className='fba-detail-cont font-12 padding-8 margin-8-bottom'>
                <div className='fba-meta-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>FBA</span>
                    </div>
                    <div className='fba-meta-data-list d-flx gap-12 flx-1'>
                        {
                            fbaMetaData.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Net Profit</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className="fba-costs d-flx gap-12 margin-8-bottom">
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Costs</span>
                    </div>
                    <div className='cost-list d-flx gap-12 flx-1'>
                        {
                            fbaCosts.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Total Costs</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className='fba-fees-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Fees with FBA</span>
                    </div>
                    <div className='fba-fees-data-list d-flx gap-12 flx-1'>
                        {
                            fbaFees.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Gross Profit</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
            </div>
            <div className='fba-detail-cont font-12 padding-8 margin-8-bottom'>
                <div className='fba-meta-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>FBM</span>
                    </div>
                    <div className='fba-meta-data-list d-flx gap-12 flx-1'>
                        {
                            fbmMetaData.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Net Profit</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className="fba-costs d-flx gap-12 margin-8-bottom">
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Costs</span>
                    </div>
                    <div className='cost-list d-flx gap-12 flx-1'>
                        {
                            fbmCosts.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Total Costs</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className='fba-fees-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Fees with FBM</span>
                    </div>
                    <div className='fba-fees-data-list d-flx gap-12 flx-1'>
                        {
                            fbmFees.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Gross Profit</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
            </div>
            <div className='other-info-cont font-12 padding-8'>
                <div className='vendor-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Vendor</span>
                    </div>
                    <div className='fba-meta-data-list d-flx gap-12 flx-1'>
                        {
                            vendorData.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>ASIN Package Cost</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className="fba-costs d-flx gap-12 margin-8-bottom">
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Forecast</span>
                    </div>
                    <div className='cost-list d-flx gap-12 flx-1'>
                        {
                            forecast.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Total Units</span>
                        <span className='product-detail-col-value font-weight-bold'>$45.10</span>
                    </div>
                </div>
                <div className='fba-fees-data d-flx gap-12 margin-8-bottom'>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Sales and Revenue</span>
                    </div>
                    <div className='fba-fees-data-list d-flx gap-12 flx-1'>
                        {
                            salesData.map(detail => (
                                <div className='product-detail-col d-flx flx-d-clm flx-1'>
                                    <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                                    <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div className='product-detail-col d-flx flx-d-clm w-15'>
                        <span className='product-detail-col-label text-color-grey'>Monthly Revenue</span>
                        <span className='product-detail-col-value font-weight-bold'>$245.10</span>
                    </div>
                </div>
            </div>
        </div>

    )
}
