import formConstants from "../../../constants/form.constants";
import FormComponent from "../../../components/shared/form-component/index";
import {connect, useSelector} from "react-redux";
import {updateCostsFormStateValues} from "../../../store/actions/costs.action";

const CostFormItem = ({shipmentType, idx, costOptions, updateCostsFormStateValues}) => {

    const handleChange = ({name, value}) => {
        updateCostsFormStateValues({
            [shipmentType]: {
                del: deletes,
                updates: updates.map((v) => ({
                    ...v,
                    [name]: v.idx === idx ? value : v[name],
                }))
            }
        });
    }

    const handleRemove = () => {
        // item at idx has id => it will be deleted from the DB else just filter it out
        const deletedRow = updates.find(input => input.idx === idx);
        updateCostsFormStateValues({
            [shipmentType]: {
                del: deletedRow.id ? [...deletes, deletedRow.id] : deletes,
                updates: updates.filter(input => input.idx !== idx)
            }
        });
    }


    const {updates, deletes, formFields, isEditing} = useSelector(({costsState}) => {
        const values = costsState.vendorsCostInputs[shipmentType].updates.find(item => item.idx === idx);
        const isEditing = costsState.isEditing;
        return {
            updates: costsState.vendorsCostInputs[shipmentType].updates,
            deletes: costsState.vendorsCostInputs[shipmentType].del,
            formFields: [{
                ...formConstants.select,
                name: 'step',
                label: 'Step',
                showSearch: false,
                value: values.step,
                placeholder: 'Choose Step',
                showArrow: true,
                filterOption: true,
                onChange: handleChange,
                span: 5,
                options: costOptions,
            },{
                ...formConstants.fullName,
                name: 'amount',
                label: 'Amount',
                value: values.duration,
                onChange: handleChange,
                placeholder: 'Amount',
                span: 3,
            },{
                label: 'Remove',
                onClick: handleRemove,
                fieldType: formConstants.fieldTypes.Button,
                type: 'danger',
                hide: !isEditing,
                span: 3,
            }],
            isEditing,
        }
    })


    return (
        <div>
            <FormComponent formFields={formFields} colSpan={4} viewOnly={!isEditing}/>
        </div>
    )
}

export default connect(null, {
    updateCostsFormStateValues,
})(CostFormItem);
