import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {
    getProductsListAction,
    resetProductInputsValues,
    updateProductStateValues
} from "../../store/actions/products.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const ProductsPage = ({ getProductsListAction, products, loading, error, success, updateProductStateValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('priority')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showOrderModal, setShowPriorityModal] = useState(false)

    const [showProductDeleteModal, setShowProductDeleteModal] = useState(false)
    const [userToDelete, setProductToDelete] = useState({});

    const userProductsTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/products/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        }, {
            title: 'Upc',
            dataIndex: 'upc',
            key: 'upc',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Isbn',
            dataIndex: 'isbn',
            key: 'isbn',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Sku',
            dataIndex: 'sku',
            key: 'sku',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewProduct(record.id)}>View Product</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Product</Button>*/}
                </>
            ),
        }
    ]

    const handleViewProduct = (id) => {
        console.log(showOrderModal)
        navigate.push(`/products/${id}`);
    }

    const handleDeleteProduct = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection, setShowPriorityModal);
        setShowProductDeleteModal(false)
        // deleteProductAPI(userToDelete)
    }

    const cancelProductDelete = () => {
        setProductToDelete({})
        setShowProductDeleteModal(false)
    }

    const createUserProduct = () => {
        resetProductInputsValues(true);
        navigate.push('/products/new');
    }

    useEffect(() => {
        // Call the API to fetch the products list
        getProductsListAction({
            pageNo,
            pageLimit,
            orderKey,
            orderDirection,
        });
    }, [getProductsListAction, pageNo, pageLimit, orderKey, orderDirection]);

    useEffect(() => {
        // Call the API to fetch the products list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateProductStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateProductStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateProductStateValues]);

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createUserProduct}>Create New Product</Button>
            </div>
                <Table
                    dataSource={products}
                    columns={userProductsTableColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                />
            <Modal
                title={<b>Are you sure?</b>}
                visible={showProductDeleteModal}
                onOk={handleDeleteProduct}
                onCancel={cancelProductDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Product {userToDelete.name}?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ productsState }) => {
    const {
        products, loading, error, success,
    } = productsState;

    return {
        products,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getProductsListAction,
    updateProductStateValues,
})(ProductsPage);
