import RegexConstants from "./regex.constants";

class FormConstants {
    static fieldTypes = {
        Input: 'input',
        Checkbox: 'checkbox',
        Select: 'select',
        Button: 'button',
    }
    static fullName = {
        label: 'Name',
        type: 'text',
        fieldType: this.fieldTypes.Input,
        name: 'name',
        validations: [
            {
                name: 'pattern',
                regex: RegexConstants.firstName,
                message: 'Enter a valid name.'
            },
            {
                name: 'required',
                message: 'Name is required.'
            }
        ]
    };

    static phone = {
        label: 'Phone number',
        type: 'tel',
        fieldType: this.fieldTypes.Input,
        name: 'phone',
        maxlength: 10,
        validations: [
            {
                name: 'pattern',
                validator: RegexConstants.phone,
                message: 'Enter a valid number.'
            },
            {
                name: 'required',
                message: 'Mobile number is required.'
            }
        ]
    };

    static email = {
        label: 'Email',
        type: 'email',
        fieldType: this.fieldTypes.Input,
        name: 'email',
        validations: [
            {
                name: 'pattern',
                validator: RegexConstants.email,
                message: 'Enter a valid email.'
            },
            {
                name: 'required',
                message: 'Email is required.'
            }
        ]
    };

    static website = {
        label: 'Website',
        type: 'text',
        fieldType: this.fieldTypes.Input,
        name: 'websiteLink',
        validations: [
            {
                name: 'pattern',
                validator: RegexConstants.website,
                message: 'Enter a valid website.'
            }, {
                name: 'required',
                message: 'Website is required.'
            }
        ]
    };

    static checkbox = {
        label: 'Check if yes',
        type: 'checkbox',
        fieldType: this.fieldTypes.Checkbox,
        name: 'checkbox',
        validations: []
    };

    static select = {
        label: 'Choose an option',
        type: 'select',
        fieldType: this.fieldTypes.Select,
        name: 'select',
        options: [],
        validations: []
    };

}


export default FormConstants;
