import API from "../interceptor/axios.interceptor.service";

class CostOptionsBackendApiService {
    static async getCostOptions() {
        const url = `/api/v1/costs/options`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching the costs options!',
            }
        }
    }

    static async updateCostOptions(payload) {
        const url = `/api/v1/costs/options`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the list of cost options!',
            }
        }
    }
}

export default CostOptionsBackendApiService;
