import React, {useEffect} from 'react'
import LayoutComponent from "../../../components/shared/layout/Layout";
import './index.scss';
import VendorDetailsTabsComponent from "../components/vendor-details-tabs-component";
import VendorDetailInfoTabPaneComponent from "../components/vendor-detail-info-tab-pane-component";
import {createNewVendorAction, updateVendorStateValues} from "../../../store/actions/vendors.action";
import {connect, useSelector} from "react-redux";
import {message} from "antd";
import {useHistory} from "react-router-dom";

const VendorCreationPage = ({createNewVendorAction, updateVendorStateValues}) => {
    const navigate = useHistory();
    const handleSave = () => {
        createNewVendorAction();
    }
    const actionButtons = [{
        label: 'Save',
        type: 'primary',
        onClick: handleSave,
    }];
    const {isLoading, success, error, vendorId} = useSelector(({vendorsState}) => {
        return {
            isEditing: vendorsState.isEditing,
            isLoading: vendorsState.loading,
            success: vendorsState.success,
            error: vendorsState.error,
            vendorId: vendorsState.selectedVendor.id,
        }
    });

    useEffect(() => {
        if (!isLoading) {
            if (success) {
                message.success(success).then(() => {
                    navigate.push(`/vendors/${vendorId}`)
                    updateVendorStateValues({
                        success: '',
                    });
                })
            } else if (error) {
                message.error(error).then(() => {
                    updateVendorStateValues({
                        error: '',
                    });
                })
            }

        }
    }, [isLoading, success, error, navigate, updateVendorStateValues, vendorId])

    const tabs = [
        {
            key: 'info',
            label: 'Info',
            component: VendorDetailInfoTabPaneComponent,
        },{
            key: 'orders',
            disabled: true,
            label: 'Order',
        },{
            key: 'product',
            disabled: true,
            label: 'Product',
        },{
            key: 'tasks',
            disabled: true,
            label: 'Tasks',
        },{
            key: 'notes',
            disabled: true,
            label: 'Notes',
        },{
            key: 'claims',
            disabled: true,
            label: 'Claims',
        },{
            key: 'credits',
            disabled: true,
            label: 'Credits',
        },{
            key: 'costs',
            disabled: true,
            label: 'Costs',
        },{
            key: 'taxes',
            disabled: true,
            label: 'Taxes',
        },{
            key: 'payments',
            disabled: true,
            label: 'Payments',
        },{
            key: 'holidays',
            disabled: true,
            label: 'Holidays',
        },{
            key: 'lead-times',
            disabled: true,
            label: 'Lead Times',
        },{
            key: 'sourcing-rules',
            disabled: true,
            label: 'Sourcing Rules',
        },{
            key: 'access',
            disabled: true,
            label: 'Access',
        },{
            key: 'activity-log',
            disabled: true,
            label: 'Activity Log',
        },];

    return (
        <LayoutComponent breadCrumbLabel={'Vendors Creation Page'} actionButtons={actionButtons} loading={isLoading}>
           <div className="vendor-tab-cont">
               <VendorDetailsTabsComponent tabs={tabs} defaultActiveKey={'info'} />
           </div>
        </LayoutComponent>
    )
}

export default connect(null, {
    createNewVendorAction,
    updateVendorStateValues,
})(VendorCreationPage);
