import formConstants from "../../../constants/form.constants";
import FormComponent from "../../../components/shared/form-component/index";
import {connect, useSelector} from "react-redux";
import {updateLeadTimesFormStateValues} from "../../../store/actions/lead-times.action";

const LeadTimeFormItem = ({shipmentType, idx, leadTimeOptions, updateLeadTimesFormStateValues}) => {

    const handleChange = ({name, value}) => {
        updateLeadTimesFormStateValues({
            [shipmentType]: {
                del: deletes,
                updates: updates.map((v) => ({
                    ...v,
                    [name]: v.idx === idx ? value : v[name],
                }))
            }
        });
    }

    const handleRemove = () => {
        // item at idx has id => it will be deleted from the DB else just filter it out
        const deletedRow = updates.find(input => input.idx === idx);
        updateLeadTimesFormStateValues({
            [shipmentType]: {
                del: deletedRow.id ? [...deletes, deletedRow.id] : deletes,
                updates: updates.filter(input => input.idx !== idx)
            }
        });
    }


    const {updates, deletes, formFields, isEditing} = useSelector(({leadTimesState}) => {
        console.log()
        const values = leadTimesState.vendorsLeadTimeInputs[shipmentType].updates.find(item => item.idx === idx);
        const isEditing = leadTimesState.isEditing;
        return {
            updates: leadTimesState.vendorsLeadTimeInputs[shipmentType].updates,
            deletes: leadTimesState.vendorsLeadTimeInputs[shipmentType].del,
            formFields: [{
                ...formConstants.select,
                name: 'step',
                label: 'Step',
                showSearch: false,
                value: values.step,
                placeholder: 'Choose Step',
                showArrow: true,
                filterOption: true,
                onChange: handleChange,
                span: 5,
                options: leadTimeOptions,
            },{
                ...formConstants.fullName,
                name: 'duration',
                label: 'Duration',
                value: values.duration,
                onChange: handleChange,
                placeholder: 'Duration',
                span: 3,
            }, {
                ...formConstants.fullName,
                name: 'fromLabel',
                label: 'From',
                value: values.fromLabel,
                onChange: handleChange,
                placeholder: 'From...',
                span: 5,
            }, {
                ...formConstants.fullName,
                name: 'toLabel',
                label: 'To',
                value: values.toLabel,
                onChange: handleChange,
                placeholder: 'To...',
                span: 5,
            }, {
                ...formConstants.fullName,
                name: 'order',
                label: 'Order',
                value: values.order,
                onChange: handleChange,
                placeholder: 'Order',
                span: 3,
            }, {
                label: 'Remove',
                onClick: handleRemove,
                fieldType: formConstants.fieldTypes.Button,
                type: 'danger',
                hide: !isEditing,
                span: 3,
            }],
            isEditing,
        }
    })


    return (
        <div>
            <FormComponent formFields={formFields} colSpan={4} viewOnly={!isEditing}/>
        </div>
    )
}

export default connect(null, {
    updateLeadTimesFormStateValues,
})(LeadTimeFormItem);
