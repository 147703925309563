import {call, put, select, takeLatest} from "redux-saga/effects";

import {GET_LEAD_TIME_OPTIONS, POST_LEAD_TIME_OPTIONS} from '../action-types/lead-time-options.action-type';

import LeadTimeOptionsBackendApiService from "../../services/backend-api/lead-time-options.backend-api.service";
import {
    createNewLeadTimeOptionsFailAction,
    createNewLeadTimeOptionsSuccessAction,
    getLeadTimeOptionsFailAction,
    getLeadTimeOptionsSuccessAction
} from "../actions/lead-time-options.action";

function* getLeadTimeOptionsList({payload}) {
    try {
        const response = yield call(LeadTimeOptionsBackendApiService.getLeadTimeOptions, payload);
        if (response.success) {
            yield put(getLeadTimeOptionsSuccessAction({
                ...response.data,
                rows: response.data.rows.map((row) => {
                    return {
                        ...row,
                    }
                })
            }));
        } else {
            yield put(getLeadTimeOptionsFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getLeadTimeOptionsFailAction(error.response));
    }
}

function* createNewLeadTimeOptions() {
    try {
        const payload = yield select(({leadTimeOptionsState}) => leadTimeOptionsState.leadTimeOptionsInputs);
        console.log({payload});
        const response = yield call(LeadTimeOptionsBackendApiService.updateLeadTimeOptions, payload);
        if (response.success) {
            yield put(createNewLeadTimeOptionsSuccessAction(response));
        } else {
            yield put(createNewLeadTimeOptionsFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewLeadTimeOptionsFailAction(error.response));
    }
}

function* LeadTimesSaga() {
    yield takeLatest(GET_LEAD_TIME_OPTIONS, getLeadTimeOptionsList);
    yield takeLatest(POST_LEAD_TIME_OPTIONS, createNewLeadTimeOptions);
}

export default LeadTimesSaga;
