export const UPDATE_VALUES = "UPDATE_BRAND_VALUES";

export const RESET_FORM_VALUES = "RESET_BRAND_FORM_VALUES";

export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAIL = "GET_BRAND_FAIL";

export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL";

export const POST_BRAND = "POST_BRAND";
export const POST_BRAND_SUCCESS = "POST_BRAND_SUCCESS";
export const POST_BRAND_FAIL = "LOGIN_FAIL";

export const PUT_BRAND = "PUT_BRAND";
export const PUT_BRAND_SUCCESS = "PUT_BRAND_SUCCESS";
export const PUT_BRAND_FAIL = "PUT_BRAND_FAIL";