import {
    GET_COSTS_FOR_VENDOR,
    GET_COSTS_FOR_VENDOR_FAIL,
    GET_COSTS_FOR_VENDOR_SUCCESS,
    POST_COSTS_FOR_VENDOR,
    POST_COSTS_FOR_VENDOR_FAIL,
    POST_COSTS_FOR_VENDOR_SUCCESS,
    RESET_COST_FORM_VALUES,
    UPDATE_COST_FORM_VALUES,
    UPDATE_COST_VALUES,
} from "../action-types/costs.action-type";

export const costInitialState = {
    step: null,
    amount: 0,
    isFBA: false,
    isFBM: false,
    idx: 0,
}

const initialState = {
    selectedVendorsCosts: {
        fbm: [],
        fba: [],
    },
    vendorsCostInputs: {
        fbm: {
            del: [],
            updates: [{...costInitialState, isFBM: true}]
        },
        fba: {
            del: [],
            updates: [{...costInitialState, isFBA: true}]
        },
    },
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const CostsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_COST_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_COST_FORM_VALUES:
            state = { ...state, vendorsCostInputs: { ...state.vendorsCostInputs, ...payload} };
            break;
        case RESET_COST_FORM_VALUES:
            state = {
                ...state,
                vendorsCostInputs: state.selectedVendorsCosts.fba.length || state.selectedVendorsCosts.fbm.length ? {
                    fbm: {
                        del: [],
                        updates: [...state.selectedVendorsCosts.fbm]
                    },
                    fba: {
                        del: [],
                        updates: [...state.selectedVendorsCosts.fba]
                    },
                } : initialState.vendorsCostInputs,
                isEditing: false
            };
            break;
        case GET_COSTS_FOR_VENDOR:
            state = { ...state, loading: true };
            break;
        case GET_COSTS_FOR_VENDOR_SUCCESS:
            state = { ...state, selectedVendorsCosts: {fbm: payload.fbm, fba: payload.fba}, vendorsCostInputs: {
                    fbm: {
                        del: [],
                        updates: [...payload.fbm, {...costInitialState, idx: payload.fbm.length, isFBM: true}],
                    },
                    fba: {
                        del: [],
                        updates: [...payload.fba, {...costInitialState, idx: payload.fba.length, isFBA: true}],
                    },
                }, loading: false };
            break;
        case GET_COSTS_FOR_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_COSTS_FOR_VENDOR:
            state = { ...state, loading: true };
            break;
        case POST_COSTS_FOR_VENDOR_SUCCESS:
            state = { ...state, selectedVendorsCosts: payload.rows, loading: false, success: 'costs for vendor updated successfully', isEditing: false };
            break;
        case POST_COSTS_FOR_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default CostsReducer;
