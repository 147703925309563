import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {Tabs} from 'antd'
import UserForm from "../../components/users/UserForm";
import LayoutComponent from "../../components/shared/layout/Layout";
import {updateUserStateValues,} from "../../store/actions/users.action";
import {UserFormTabs} from "../../constants/users.constant";
import {useHistory} from "react-router-dom";
import UserPermissionForm from "../../components/users/UserPermissionsForm";
import UserGroupsForm from "../../components/users/UserGroupsForm";
import UserRolesForm from "../../components/users/UserRolesForm";

const CreateUserPage = ({loading}) => {
    const [activeTabPane, setActiveTabPane] = useState(useParams().tabName || UserFormTabs.USER_INFO)
    const idParam = useParams().id
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        setIsNew(idParam === 'new');
    }, [idParam])

    const navigate = useHistory();
    return (
        <LayoutComponent loading={loading}>
            <Tabs defaultActiveKey={activeTabPane} activeKey={activeTabPane} onChange={(tabId) => {
                setActiveTabPane(tabId)
                navigate.push(`/users/${idParam}/${tabId}`)
            }}>
                <Tabs.TabPane tab="User Info" key={UserFormTabs.USER_INFO}>
                    <UserForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Permissions" key={UserFormTabs.PERMISSIONS} disabled={isNew}>
                   <UserPermissionForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Roles" key={UserFormTabs.ROLES} disabled={isNew}>
                    <UserRolesForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Groups" key={UserFormTabs.GROUPS} disabled={isNew}>
                    <UserGroupsForm />
                </Tabs.TabPane>
            </Tabs>
        </LayoutComponent>
    )
}

CreateUserPage.propTypes = {
    loading: PropTypes.bool,
    success: PropTypes.string,
    id: PropTypes.number,
    error: PropTypes.string,
}


const mapStateToProps = ({ usersState }) => {
    return {
        loading: usersState.loading,
    }
};

export default
connect(mapStateToProps, {
    updateUserStateValues,
})(CreateUserPage)
