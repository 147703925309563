import {Button} from "antd";
import "./index.scss";

// className here is basically used to set alignment, gapClass, and margin
const ActionButtonsComponent = ({className, viewMode, isNew, hideEdit, labelMap, handleEdit, handleSave, handleCancel}) => {
    return (
        <div className={`${className} action-buttons-container`}>
            {(!viewMode || isNew) && <Button type={'primary'} onClick={handleSave}>{labelMap.save}</Button>}
            {(!viewMode && !isNew) && <Button type={'default'} onClick={handleCancel}>{labelMap.cancel}</Button>}
            {(viewMode && !isNew && !hideEdit) && (
                <Button type='outline' onClick={handleEdit}>{labelMap.edit}</Button>
            )}
        </div>
    )
}

export default ActionButtonsComponent;