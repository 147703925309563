import {Button, Card, message} from "antd";
import './index.scss';
import {connect} from "react-redux";

import BreadcrumbComponent from "../../../../components/shared/breadcrumb/Breadcrumb";
import {useCallback, useEffect, useState} from "react";
import LeadTimeOptionFormItem from "../../../../components/settings/components/lead-time-option-form-input";
import SettingsTab from "../../../../components/settings/components/settings-tabs";
import {
    createNewLeadTimeOptionsAction,
    getLeadTimeOptionsAction,
    resetLeadTimeOptionsInputsValues,
    updateLeadTimeOptionsFormStateValues,
    updateLeadTimeOptionsStateValues
} from "../../../../store/actions/lead-time-options.action";
import {leadTimeOptionInitialState} from "../../../../store/reducers/lead-time-options.reducer";

const LeadTimeOptionsTabPaneComponent = ({ getLeadTimeOptionsAction, createNewLeadTimeOptionsAction,resetLeadTimeOptionsInputsValues, updateLeadTimeOptionsStateValues, updateLeadTimeOptionsFormStateValues, isEditing, loading, success, error, updates, deletes}) => {
    const handleEdit = useCallback(() => {
        updateLeadTimeOptionsStateValues({
            isEditing: true,
        })
        setActionButtons([{
            label: 'Cancel',
            type: 'secondary',
            onClick: handleCancel,
        }, {
            label: 'Save',
            type: 'primary',
            onClick: handleSave,
        }])
        // eslint-disable-next-line
    }, [updateLeadTimeOptionsStateValues])

    const handleCancel = useCallback(() => {
        resetLeadTimeOptionsInputsValues();
        setActionButtons([{
            label: 'Edit',
            type: 'primary',
            onClick: handleEdit,
        }])
        // eslint-disable-next-line
    }, [resetLeadTimeOptionsInputsValues])

    const handleSave = useCallback(() => {
        createNewLeadTimeOptionsAction();
    }, [createNewLeadTimeOptionsAction])

    const [actionButtons, setActionButtons] = useState([{
        label: 'Edit',
        type: 'primary',
        onClick: handleEdit,
    }]);

    useEffect(() => {
        if (!loading) {
            if (success) {
                message.success(success).then(() => {
                    updateLeadTimeOptionsStateValues({
                        success: '',
                    });
                    setActionButtons([{
                        label: 'Edit',
                        type: 'primary',
                        onClick: handleEdit,
                    }])
                })
            } else if (error) {
                message.error(error).then(() => {
                    updateLeadTimeOptionsStateValues({
                        error: '',
                    });
                    setActionButtons([{
                        label: 'Cancel',
                        type: 'secondary',
                        onClick: handleCancel,
                    }, {
                        label: 'Save',
                        type: 'primary',
                        onClick: handleSave,
                    }])
                })
            }

        }
    }, [loading, success, error, handleCancel, handleEdit, handleSave, createNewLeadTimeOptionsAction, updateLeadTimeOptionsStateValues])

    useEffect(() => {
        // Fetch the list of lead time options
        getLeadTimeOptionsAction()
        // eslint-disable-next-line
    }, []);

    const handleAddOption = () => {
        updateLeadTimeOptionsFormStateValues({
            del: deletes,
            update: [
                ...updates,
                {...leadTimeOptionInitialState, idx: updates.length,},
            ]
        });
    }

    return (
        <div>
            <BreadcrumbComponent actionButtons={actionButtons} />
            <Card size="small">
                <SettingsTab />
                <div className='vendor-detail-info-tab-pane-component'>
                    <div className='vendor-detail-section'>
                        <div className='lead-time-option-info'>
                            <div className='lead-time-option-info-label detail-label'>
                                <div className='detail-label-content'>
                                    <div className="label">Lead time options</div>
                                    {
                                        isEditing && (
                                            <Button type='outline' onClick={handleAddOption}>+ Add option</Button>
                                        )
                                    }
                                </div>
                                <div className="divider"></div>
                            </div>
                            {
                                updates ? updates.map((value) => (
                                    <div className='lead-time-option-info-form'>
                                        <LeadTimeOptionFormItem idx={value.idx}/>
                                    </div>
                                )) : null
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = ({ leadTimeOptionsState }) => {
    return {
        loading: leadTimeOptionsState.loading,
        isEditing: leadTimeOptionsState.isEditing,
        success: leadTimeOptionsState.success,
        error: leadTimeOptionsState.error,
        updates: leadTimeOptionsState.leadTimeOptionsInputs.update,
        deletes: leadTimeOptionsState.leadTimeOptionsInputs.del,
    }
}

export default connect(mapStateToProps, {
    updateLeadTimeOptionsStateValues,
    updateLeadTimeOptionsFormStateValues,
    resetLeadTimeOptionsInputsValues,
    getLeadTimeOptionsAction,
    createNewLeadTimeOptionsAction,
})(LeadTimeOptionsTabPaneComponent);
