import {
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_FAIL,
    GET_CATEGORIES_SUCCESS,
    POST_CATEGORY,
    POST_CATEGORY_FAIL,
    POST_CATEGORY_SUCCESS,
    PUT_CATEGORY,
    PUT_CATEGORY_FAIL,
    PUT_CATEGORY_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/category.action-type";

const initialState = {
    categories: [],
    selectedCategory: {
        name: '',
        description: '',
    },
    searchedCategories: [],
    selectedCategoryInputs: {
        name: '',
        description: '',
    },
    loading: false,
    error: '',
    success: '',
};

const CategoryReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_CATEGORY:
            state = { ...state, loading: true };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedCategoryInputs: payload.isNew ? initialState.selectedCategoryInputs : state.selectedCategory };
            break;
        case GET_CATEGORY_SUCCESS:
            state = { ...state, selectedCategory: payload.category, selectedCategoryInputs: payload.category, loading: false, success: payload.message };
            break;
        case GET_CATEGORY_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_CATEGORIES:
            state = { ...state, loading: true };
            break;
        case GET_CATEGORIES_SUCCESS:
            state = { ...state, categories: payload.rows, loading: false };
            break;
        case GET_CATEGORIES_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_CATEGORY:
            state = { ...state, loading: true };
            break;
        case POST_CATEGORY_SUCCESS:
            state = { ...state, selectedCategory: payload.category, loading: false, success: 'New Category created successfully!' };
            break;
        case POST_CATEGORY_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_CATEGORY:
            state = { ...state, loading: true };
            break;
        case PUT_CATEGORY_SUCCESS:
            state = { ...state, selectedCategory: payload.category, loading: false, success: 'category updated successfully' };
            break;
        case PUT_CATEGORY_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default CategoryReducer;
