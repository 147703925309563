import {
    GET_ORDER,
    GET_ORDER_FAIL,
    GET_ORDER_SUCCESS,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_FAIL,
    GET_ALL_ORDERS_SUCCESS,
    POST_ORDER,
    POST_ORDER_FAIL,
    POST_ORDER_SUCCESS,
    PUT_ORDER,
    PUT_ORDER_FAIL,
    PUT_ORDER_SUCCESS,
    RESET_FORM_VALUES,
    UPDATE_FORM_VALUES,
    UPDATE_VALUES
} from "../action-types/orders.action-type";

export const updateOrderStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const updateOrderFormStateValues = (payload) => {
    return {
        type: UPDATE_FORM_VALUES,
        payload,
    };
};

export const resetOrderInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
})

export const getOrderDetailsAction = (payload) => {
    return {
        type: GET_ORDER,
        payload,
    };
};

export const getOrderDetailsSuccessAction = (payload) => {
    return {
        type: GET_ORDER_SUCCESS,
        payload,
    };
};

export const getOrderDetailsFailAction = (payload) => {
    return {
        type: GET_ORDER_FAIL,
        payload,
    };
};


export const updateOrderDetailsAction = () => {
    return {
        type: PUT_ORDER,
    };
};

export const updateOrderDetailsSuccessAction = (payload) => {
    return {
        type: PUT_ORDER_SUCCESS,
        payload,
    };
};

export const updateOrderDetailsFailAction = (payload) => {
    return {
        type: PUT_ORDER_FAIL,
        payload,
    };
};


export const createNewOrderAction = () => {
    console.log('POST VENDOR');
    return {
        type: POST_ORDER,
    };
};

export const createNewOrderSuccessAction = (payload) => {
    return {
        type: POST_ORDER_SUCCESS,
        payload
    };
};

export const createNewOrderFailAction = (payload) => {
    return {
        type: POST_ORDER_FAIL,
        payload,
    };
};

export const getOrdersListAction = (payload) => {
    console.log('Inside Action');
    return {
        type: GET_ALL_ORDERS,
        payload
    }
};

export const getOrdersListSuccessAction = (payload) => {
    return {
        type: GET_ALL_ORDERS_SUCCESS,
        payload
    };
};

export const getOrdersListFailAction = (payload) => {
    return {
        type: GET_ALL_ORDERS_FAIL,
        payload,
    };
};
