import {
    GET_LEAD_TIME_OPTIONS,
    GET_LEAD_TIME_OPTIONS_FAIL,
    GET_LEAD_TIME_OPTIONS_SUCCESS,
    POST_LEAD_TIME_OPTIONS,
    POST_LEAD_TIME_OPTIONS_FAIL,
    POST_LEAD_TIME_OPTIONS_SUCCESS,
    RESET_OPTION_FORM_VALUES,
    UPDATE_OPTION_FORM_VALUES,
    UPDATE_OPTION_VALUES,
} from "../action-types/lead-time-options.action-type";


export const leadTimeOptionInitialState = {
    id: '',
    name: '',
    description: '',
    idx: 0,
}

const initialState = {
    leadTimeOptionsInputs: {
        del: [],
        update: [{...leadTimeOptionInitialState}]
    },
    leadTimeOptions: [],
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const LeadTimeOptionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_OPTION_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_OPTION_FORM_VALUES:
            state = { ...state, leadTimeOptionsInputs: { ...state.leadTimeOptionsInputs, ...payload} };
            break;
        case RESET_OPTION_FORM_VALUES:
            state = { ...state,  leadTimeOptionsInputs: state.leadTimeOptions.length ? {del: [], update: state.leadTimeOptions} : initialState.leadTimeOptionsInputs, isEditing: false };
            break;
        case GET_LEAD_TIME_OPTIONS:
            state = { ...state, loading: true };
            break;
        case GET_LEAD_TIME_OPTIONS_SUCCESS:
            state = { ...state, leadTimeOptions: payload.rows, leadTimeOptionsInputs: {del: [], update: payload.rows.map((v,idx) => ({...v, idx}))}, loading: false };
            break;
        case GET_LEAD_TIME_OPTIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_LEAD_TIME_OPTIONS:
            state = { ...state, loading: true };
            break;
        case POST_LEAD_TIME_OPTIONS_SUCCESS:
            state = { ...state, leadTimeOptions: payload.rows, loading: false, success: 'lead time options updated successfully', isEditing: false };
            break;
        case POST_LEAD_TIME_OPTIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default LeadTimeOptionsReducer;
