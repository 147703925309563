import {
    GET_SUPPLIER,
    GET_SUPPLIER_FAIL,
    GET_SUPPLIER_SUCCESS, GET_SUPPLIERS, GET_SUPPLIERS_FAIL, GET_SUPPLIERS_SUCCESS, POST_SUPPLIER, POST_SUPPLIER_FAIL, POST_SUPPLIER_SUCCESS,
    PUT_SUPPLIER, PUT_SUPPLIER_FAIL,
    PUT_SUPPLIER_SUCCESS, RESET_FORM_VALUES,
    UPDATE_VALUES
} from "../action-types/suppliers.action-type";

export const updateSupplierStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const resetSupplierInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
})

export const getSupplierDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_SUPPLIER,
        payload,
    };
};

export const getSupplierDetailsSuccessAction = (payload) => {
    return {
        type: GET_SUPPLIER_SUCCESS,
        payload,
    };
};

export const getSupplierDetailsFailAction = (payload) => {
    return {
        type: GET_SUPPLIER_FAIL,
        payload,
    };
};


export const updateSupplierDetailsAction = (payload) => {
    return {
        type: PUT_SUPPLIER,
        payload,
    };
};

export const updateSupplierDetailsSuccessAction = (payload) => {
    return {
        type: PUT_SUPPLIER_SUCCESS,
        payload,
    };
};

export const updateSupplierDetailsFailAction = (payload) => {
    return {
        type: PUT_SUPPLIER_FAIL,
        payload,
    };
};


export const createNewSupplierAction = (payload) => {
    console.log({payload});
    return {
        type: POST_SUPPLIER,
        payload,
    };
};

export const createNewSupplierSuccessAction = (payload) => {
    return {
        type: POST_SUPPLIER_SUCCESS,
        payload
    };
};

export const createNewSupplierFailAction = (payload) => {
    return {
        type: POST_SUPPLIER_FAIL,
        payload,
    };
};

export const getSuppliersListAction = (payload) => ({
    type: GET_SUPPLIERS,
    payload
});

export const getSuppliersListSuccessAction = (payload) => {
    return {
        type: GET_SUPPLIERS_SUCCESS,
        payload
    };
};

export const getSuppliersListFailAction = (payload) => {
    return {
        type: GET_SUPPLIERS_FAIL,
        payload,
    };
};
