import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Select, Table} from "antd";
import {Link} from "react-router-dom";
import {getPermissionsListAction, updatePermissionStateValues} from "../../store/actions/permissions.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {PERMISSIONS_TYPES} from "../../constants/Permissions.constant";
import Text from "antd/es/typography/Text";

const { Option } = Select;

const typeFilterOptions = Object.values(PERMISSIONS_TYPES).map(p => (<Option value={p}>{p}</Option>));

const PermissionsPage = ({ getPermissionsListAction, permissions, loading, error, success, updatePermissionStateValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [typeFilter, setTypeFilter] = useState(null)

    const permissionTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/permissions/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: val => (
                <div className='tag warning'>
                    {val}
                </div>
            )
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewPermission(record.id)}>View Permission</Button>
                </>
            ),
        }
    ]

    const handleViewPermission = (id) => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        navigate.push(`/permissions/${id}`);
    }

    const onTypeFilterChange = (val) => {
        setTypeFilter(val);
    }

    useEffect(() => {

        // Call the API to fetch the permissions list
        getPermissionsListAction({
            pageNo,
            pageLimit,
            type: typeFilter,
        });
    }, [getPermissionsListAction, pageNo, pageLimit, typeFilter]);

    useEffect(() => {
        // Call the API to fetch the permissions list
        if (!loading) {
            if (error) {
                message.error(error).then(() => {
                    updatePermissionStateValues({
                        error: '',
                    })
                });
            } else if (success) {
                message.success(success).then(() => {
                    updatePermissionStateValues({
                        error: '',
                    })
                });
            }
        }
    }, [loading, error, success, updatePermissionStateValues]);

    return (
        <LayoutComponent>
            <Text style={{ fontSize: '16px' }} type="secondary">Permission Type: </Text>
            <Select defaultValue="all" style={{ width: 180, marginBottom: '16px' }} onChange={onTypeFilterChange}>
                <Option value="all">ALL</Option>
                {typeFilterOptions}
            </Select>
            <Table
                dataSource={permissions}
                columns={permissionTableColumns}
                loading={loading}
                rowKey="id"
            />
        </LayoutComponent>
    )
}

const mapStateToProps = ({ permissionsState }) => {
    const {
        permissions, loading, error, success,
    } = permissionsState;

    return {
        permissions,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getPermissionsListAction,
    updatePermissionStateValues,
})(PermissionsPage);
