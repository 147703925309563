import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router-dom";

const { TabPane } = Tabs;

const tabs = [
    {
        key: 'info',
        label: 'Info',
    },{
        key: 'orders',
        label: 'Order',
    },{
        key: 'product',
        label: 'Product',
    },{
        key: 'tasks',
        label: 'Tasks',
    },{
        key: 'notes',
        label: 'Notes',
    },{
        key: 'claims',
        label: 'Claims',
    },{
        key: 'credits',
        label: 'Credits',
    },{
        key: 'costs',
        label: 'Costs',
    },{
        key: 'taxes',
        label: 'Taxes',
    },{
        key: 'payments',
        label: 'Payments',
    },{
        key: 'holidays',
        label: 'Holidays',
    },{
        key: 'lead-times',
        label: 'Lead Times',
    },{
        key: 'sourcing-rules',
        label: 'Sourcing Rules',
    },{
        key: 'access',
        label: 'Access',
    },{
        key: 'activity-log',
        label: 'Activity Log',
    },];

const VendorsTabs = () => {
    const [activeKey, setActiveKey] = useState('info');
    const [vendorId, setVendorId] = useState(null);
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        const pathKeys = location.pathname.split('/');
        if (pathKeys[3]) {
            setActiveKey(pathKeys[3]);
        }
        if (pathKeys[2]) {
            setVendorId(pathKeys[2]);
        }
    }, [location]);

    const handleTabChange = (selectedKey) => {
        history.push(`/vendors/${vendorId}/${selectedKey}`)
        setActiveKey(selectedKey);
    }

    return (
        <Tabs defaultActiveKey={'info'} activeKey={activeKey} onChange={handleTabChange}>
            {
                tabs.map(tab => (
                    <TabPane tab={tab.label} key={tab.key} disabled={tab.disabled}/>
                ))
            }
        </Tabs>
    )
}

export default VendorsTabs;
