import React, {useState} from 'react';
import {Route} from "react-router-dom";
import {Redirect, Switch} from "react-router";
import {Layout} from "antd";
import "./app.scss";
import SignInComponent from "./pages/signin/SignInComponent";
import AccountInviteComponent from "./pages/account-invite/AccountInviteComponent";
import UsersPage from "./pages/users/UsersPage";
import CreateUserPage from "./pages/users/CreateUserPage";
import SuppliersPage from "./pages/suppliers/SuppliersPage";
import CreateSupplierPage from "./pages/suppliers/CreateSupplierPage";
import RolesPage from "./pages/roles/RolesPage";
import CreateRolePage from "./pages/roles/CreateRolePage";
import GroupsPage from "./pages/groups/GroupsPage";
import CreateGroupPage from "./pages/groups/CreateGroupPage";
import PermissionsPage from "./pages/permissions/PermissionsPage";
import PermissionsDetailPage from "./pages/permissions/PermissionsDetailsPage";
import BrandsPage from "./pages/brands/BrandsPage";
import CreateBrandPage from "./pages/brands/CreateBrandPage";
import CategoryPage from "./pages/category/CategoryPage";
import CreateCategoryPage from "./pages/category/CreateCategoryPage";
import ProductsPage from "./pages/products/ProductsPage";
import CreateProductPage from "./pages/products/CreateProductPage";
import VendorListingPage from "./components/vendors/listing";
import VendorDetailPage from "./components/vendors/details";
import VendorCreationPage from "./components/vendors/create";
import SettingsPage from "./components/settings";
import OrdersPage from "./components/orders";

function App() {
    const [loggedIn] = useState(true); // TODO fix this
    return (
        <Layout>
            <Switch>
                <Route path="/sign-in" exact component={SignInComponent}/>
                <Route path="/account-invite/:id" exact component={AccountInviteComponent}/>
                <Route path="/users" exact component={UsersPage}/>
                {/* TODO break the id and new page into separate pages */}
                <Route path="/users/:id" exact component={CreateUserPage}/>
                <Route path="/users/:id/:tabName" exact component={CreateUserPage}/>
                {/* TODO break the id and new page into separate pages */}
                <Route path="/suppliers" exact component={SuppliersPage}/>
                <Route path="/suppliers/:id" exact component={CreateSupplierPage}/>
                <Route path="/vendors" exact component={VendorListingPage}/>
                <Route path="/vendors/new" exact component={VendorCreationPage}/>
                <Route path="/vendors/:id" component={VendorDetailPage}/>
                <Route path="/orders/" component={OrdersPage}/>
                <Route path="/roles" exact component={RolesPage}/>
                <Route path="/roles/:id" exact component={CreateRolePage}/>
                <Route path="/groups" exact component={GroupsPage}/>
                <Route path="/groups/:id" exact component={CreateGroupPage}/>
                <Route path="/permissions" exact component={PermissionsPage}/>
                <Route path="/permissions/:id" exact component={PermissionsDetailPage}/>
                <Route path="/brands" exact component={BrandsPage}/>
                <Route path="/brands/:id" exact component={CreateBrandPage}/>
                <Route path="/category" exact component={CategoryPage}/>
                <Route path="/category/:id" exact component={CreateCategoryPage}/>
                <Route path="/products" exact component={ProductsPage}/>
                <Route path="/products/:id" exact component={CreateProductPage}/>
                <Route path="/settings/" component={SettingsPage}/>
                <Redirect to={!loggedIn ? "/sign-in" : "/vendors"}/>
            </Switch>
        </Layout>
    );
}

export default App;
