import {
    GET_PERMISSION,
    GET_PERMISSION_FAIL,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSIONS,
    GET_PERMISSIONS_FAIL,
    GET_PERMISSIONS_SUCCESS,
    POST_PERMISSION,
    POST_PERMISSION_FAIL,
    POST_PERMISSION_SUCCESS,
    PUT_PERMISSION,
    PUT_PERMISSION_FAIL,
    PUT_PERMISSION_SUCCESS,
    SEARCH_PERMISSIONS, SEARCH_PERMISSIONS_FAIL, SEARCH_PERMISSIONS_SUCCESS,
    UPDATE_VALUES
} from "../action-types/permissions.action-type";

export const updatePermissionStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const getPermissionDetailsAction = (payload) => {
    return {
        type: GET_PERMISSION,
        payload,
    };
};

export const getPermissionDetailsSuccessAction = (payload) => {
    return {
        type: GET_PERMISSION_SUCCESS,
        payload,
    };
};

export const getPermissionDetailsFailAction = (payload) => {
    return {
        type: GET_PERMISSION_FAIL,
        payload,
    };
};


export const updatePermissionDetailsAction = (payload) => {
    return {
        type: PUT_PERMISSION,
        payload,
    };
};

export const updatePermissionDetailsSuccessAction = (payload) => {
    return {
        type: PUT_PERMISSION_SUCCESS,
        payload,
    };
};

export const updatePermissionDetailsFailAction = (payload) => {
    return {
        type: PUT_PERMISSION_FAIL,
        payload,
    };
};


export const createNewPermissionAction = () => {
    return {
        type: POST_PERMISSION,
    };
};

export const createNewPermissionSuccessAction = (payload) => {
    return {
        type: POST_PERMISSION_SUCCESS,
        payload
    };
};

export const createNewPermissionFailAction = (payload) => {
    return {
        type: POST_PERMISSION_FAIL,
        payload,
    };
};

export const getPermissionsListAction = (payload) => ({
    type: GET_PERMISSIONS,
    payload
});

export const getPermissionsListSuccessAction = (payload) => {
    return {
        type: GET_PERMISSIONS_SUCCESS,
        payload
    };
};

export const getPermissionsListFailAction = (payload) => {
    return {
        type: GET_PERMISSIONS_FAIL,
        payload,
    };
};


export const searchPermissionsListAction = (payload) => ({
    type: SEARCH_PERMISSIONS,
    payload
});

export const searchPermissionsListSuccessAction = (payload) => {
    return {
        type: SEARCH_PERMISSIONS_SUCCESS,
        payload
    };
};

export const searchPermissionsListFailAction = (payload) => {
    return {
        type: SEARCH_PERMISSIONS_FAIL,
        payload,
    };
};
