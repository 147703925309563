import './index.scss';
import {Card, Layout, message} from "antd";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {
    getOrdersListAction,
    resetOrderInputsValues,
    updateOrderStateValues
} from "../../../store/actions/orders.action";
import BreadcrumbComponent from "../../../components/shared/breadcrumb/Breadcrumb";
import {AddProductSearch} from "../../../components/orders/components/add-product-search/index";
import {ProductSearchItem} from "../components/product-search-item/index";
import {AddProductSummary} from "../components/add-product-summary/index";

const AddProductsPage = ({
   loading,
   error,
   success,
   updateOrderStateValues,
   selectedOrderId,
}) => {
    const navigate = useHistory();

    const viewOrder = () => {
        navigate.push(!selectedOrderId ? '/orders/new' : `/orders/${selectedOrderId}`);
    }

    const actionButtons = [{
        label: 'View order',
        type: 'primary',
        onClick: viewOrder,
    }];
    // Show Success / Failure Message
    useEffect(() => {
        if (!loading) {
            if (error) {
                message.error(error).then(() => {
                    updateOrderStateValues({
                        error: '',
                    })
                });
            } else if (success) {
                message.success(success).then(() => {
                    updateOrderStateValues({
                        error: '',
                    })
                });
            }
        }
    }, [loading, error, success, updateOrderStateValues]);

    return (
        <Layout className='padding-8'>
            <div>
                <BreadcrumbComponent actionButtons={actionButtons} />
                <Card size="small" className='background-grey'>
                    <div className='add-product-page d-flx flx-d-clm gap-12'>
                        <div className='search-cont'>
                            <AddProductSearch />
                        </div>
                        <div className='search-result-cont d-flx gap-12'>
                            <div className='search-results d-flx flx-d-clm gap-12'>
                                <ProductSearchItem />
                                <ProductSearchItem />
                            </div>
                            <div className='order-overview w-20'>
                                <AddProductSummary />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    )
}

const mapStateToProps = ({ ordersState }) => {
    const {
        orders, loading, error, success,
    } = ordersState;

    return {
        orders,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getOrdersListAction,
    updateOrderStateValues,
    resetOrderInputsValues,
})(AddProductsPage);
