import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_ROLE,
    PUT_ROLE,
    GET_ROLES,
    POST_ROLE,
    SEARCH_ROLES, UPDATE_ROLES_PRIORITY
} from '../action-types/roles.action-type';

import RolesBackendApiService from "../../services/backend-api/roles.backend-api.service";
import {
    createNewRoleFailAction,
    createNewRoleSuccessAction,
    getRoleDetailsFailAction,
    getRoleDetailsSuccessAction,
    getRolesListFailAction,
    getRolesListSuccessAction, searchRolesListFailAction,
    searchRolesListSuccessAction,
    updateRoleDetailsFailAction,
    updateRoleDetailsSuccessAction, updateRolesPriorityFailureAction, updateRolesPrioritySuccessAction
} from "../actions/roles.action";

function* roleDetails({payload}) {
    try {
        const response = yield call(RolesBackendApiService.getRoleDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getRoleDetailsSuccessAction(response.data));
        } else {
            yield put(getRoleDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getRoleDetailsFailAction(error.response));
    }
}

function* searchRolesList() {
    try {
        const response = yield call(RolesBackendApiService.getRolesList);
        if (response.success) {
            yield put(searchRolesListSuccessAction(response.data));
        } else {
            yield put(searchRolesListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(searchRolesListFailAction(error.response));
    }
}


function* getRolesList({payload}) {
    try {
        const response = yield call(RolesBackendApiService.getRolesList, payload);
        if (response.success) {
            yield put(getRolesListSuccessAction(response.data));
        } else {
            yield put(getRolesListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getRolesListFailAction(error.response));
    }
}

function* updateRoleDetails({payload}) {
    try {
        const response = yield call(RolesBackendApiService.updateRoleDetails, payload);
        if (response.success) {
            yield put(updateRoleDetailsSuccessAction(response));
        } else {
            yield put(updateRoleDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateRoleDetailsFailAction(error.response));
    }
}

function* updateRolesPriority({payload}) {
    try {
        const response = yield call(RolesBackendApiService.updateRolesPriority, payload);
        if (response.success) {
            yield put(updateRolesPrioritySuccessAction(response.data));
        } else {
            yield put(updateRolesPriorityFailureAction(response.message));
        }
    } catch (error) {
        yield put(updateRolesPriorityFailureAction(error.response));
    }
}


function* createNewRole({payload}) {
    try {
        const response = yield call(RolesBackendApiService.createNewRole, payload);
        if (response.success) {
            yield put(createNewRoleSuccessAction(response.data));
        } else {
            yield put(createNewRoleFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewRoleFailAction(error.response));
    }
}

function* RolesSaga() {
    yield takeLatest(GET_ROLE, roleDetails);
    yield takeLatest(GET_ROLES, getRolesList);
    yield takeLatest(SEARCH_ROLES, searchRolesList);
    yield takeLatest(POST_ROLE, createNewRole);
    yield takeLatest(PUT_ROLE, updateRoleDetails);
    yield takeLatest(UPDATE_ROLES_PRIORITY, updateRolesPriority);
}

export default RolesSaga;