import React from 'react'
import {connect} from 'react-redux'
import {Col, Form, Input, Row} from 'antd'

const PermissionBasicDetails = ({permission}) => {
    return (
        <Row gutter={[12, 0]}>
            <Col span={10}>
                <Form.Item label="Name" labelCol={{ span: 24 }}>
                    <Input name="name" type="text" placeholder="Name" size="large" value={permission.name} disabled={true} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Description" labelCol={{ span: 24 }}>
                    <Input name="description" type="text" placeholder="Last name" size="large" value={permission.description} disabled={true} />
                </Form.Item>
            </Col>
        </Row>
    )
}
const mapStateToProps = ({ permissionsState }) => {
    return {
        permission: permissionsState.selectedPermissionInputs,
    }
}

export default connect(mapStateToProps)(PermissionBasicDetails)
