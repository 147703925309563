import {
    GET_VENDOR,
    GET_VENDOR_FAIL,
    GET_VENDOR_SUCCESS,
    GET_VENDORS,
    GET_VENDORS_FAIL,
    GET_VENDORS_SUCCESS,
    POST_VENDOR,
    POST_VENDOR_FAIL,
    POST_VENDOR_SUCCESS,
    PUT_VENDOR,
    PUT_VENDOR_FAIL,
    PUT_VENDOR_SUCCESS,
    RESET_FORM_VALUES,
    UPDATE_FORM_VALUES,
    UPDATE_VALUES
} from "../action-types/vendors.action-type";

export const updateVendorStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const updateVendorFormStateValues = (payload) => {
    return {
        type: UPDATE_FORM_VALUES,
        payload,
    };
};

export const resetVendorInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
})

export const getVendorDetailsAction = (payload) => {
    return {
        type: GET_VENDOR,
        payload,
    };
};

export const getVendorDetailsSuccessAction = (payload) => {
    return {
        type: GET_VENDOR_SUCCESS,
        payload,
    };
};

export const getVendorDetailsFailAction = (payload) => {
    return {
        type: GET_VENDOR_FAIL,
        payload,
    };
};


export const updateVendorDetailsAction = () => {
    return {
        type: PUT_VENDOR,
    };
};

export const updateVendorDetailsSuccessAction = (payload) => {
    return {
        type: PUT_VENDOR_SUCCESS,
        payload,
    };
};

export const updateVendorDetailsFailAction = (payload) => {
    return {
        type: PUT_VENDOR_FAIL,
        payload,
    };
};


export const createNewVendorAction = () => {
    console.log('POST VENDOR');
    return {
        type: POST_VENDOR,
    };
};

export const createNewVendorSuccessAction = (payload) => {
    return {
        type: POST_VENDOR_SUCCESS,
        payload
    };
};

export const createNewVendorFailAction = (payload) => {
    return {
        type: POST_VENDOR_FAIL,
        payload,
    };
};

export const getVendorsListAction = (payload) => ({
    type: GET_VENDORS,
    payload
});

export const getVendorsListSuccessAction = (payload) => {
    return {
        type: GET_VENDORS_SUCCESS,
        payload
    };
};

export const getVendorsListFailAction = (payload) => {
    return {
        type: GET_VENDORS_FAIL,
        payload,
    };
};
