export const UPDATE_VALUES = "UPDATE_VALUES";

export const GET_TOKEN_INFO = "GET_TOKEN_INFO";
export const GET_TOKEN_INFO_SUCCESS = "GET_TOKEN_INFO_SUCCESS";
export const GET_TOKEN_INFO_FAIL = "GET_TOKEN_INFO_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAIL = "SIGN_OUT_FAIL";