import {
    GET_ROLE,
    GET_ROLE_FAIL,
    GET_ROLE_SUCCESS,
    GET_ROLES,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    POST_ROLE,
    POST_ROLE_FAIL,
    POST_ROLE_SUCCESS,
    PUT_ROLE,
    PUT_ROLE_FAIL,
    PUT_ROLE_SUCCESS,
    SEARCH_ROLES, SEARCH_ROLES_FAIL, SEARCH_ROLES_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES, UPDATE_ROLES_PRIORITY, UPDATE_ROLES_PRIORITY_SUCCESS, UPDATE_ROLES_PRIORITY_FAIL,
} from "../action-types/roles.action-type";

export const updateRoleStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const resetRoleInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
});

export const getRoleDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_ROLE,
        payload,
    };
};

export const getRoleDetailsSuccessAction = (payload) => {
    return {
        type: GET_ROLE_SUCCESS,
        payload,
    };
};

export const getRoleDetailsFailAction = (payload) => {
    return {
        type: GET_ROLE_FAIL,
        payload,
    };
};


export const updateRoleDetailsAction = (payload) => {
    return {
        type: PUT_ROLE,
        payload,
    };
};

export const updateRoleDetailsSuccessAction = (payload) => {
    return {
        type: PUT_ROLE_SUCCESS,
        payload,
    };
};

export const updateRoleDetailsFailAction = (payload) => {
    return {
        type: PUT_ROLE_FAIL,
        payload,
    };
};


export const createNewRoleAction = (payload) => {
    return {
        type: POST_ROLE,
        payload,
    };
};

export const createNewRoleSuccessAction = (payload) => {
    return {
        type: POST_ROLE_SUCCESS,
        payload
    };
};

export const createNewRoleFailAction = (payload) => {
    return {
        type: POST_ROLE_FAIL,
        payload,
    };
};

export const getRolesListAction = (payload) => ({
    type: GET_ROLES,
    payload
});

export const getRolesListSuccessAction = (payload) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload
    };
};

export const getRolesListFailAction = (payload) => {
    return {
        type: GET_ROLES_FAIL,
        payload,
    };
};

export const updateRolesPriorityAction = (
    data,
) => ({
    type: UPDATE_ROLES_PRIORITY,
    payload: data,
})

export const updateRolesPrioritySuccessAction = (payload) => ({
    type: UPDATE_ROLES_PRIORITY_SUCCESS,
    payload,
})

export const updateRolesPriorityFailureAction = (
    error,
) => ({
    type: UPDATE_ROLES_PRIORITY_FAIL,
    payload: error,
})

export const searchRolesListAction = (payload) => ({
    type: SEARCH_ROLES,
    payload
});

export const searchRolesListSuccessAction = (payload) => {
    return {
        type: SEARCH_ROLES_SUCCESS,
        payload
    };
};

export const searchRolesListFailAction = (payload) => {
    return {
        type: SEARCH_ROLES_FAIL,
        payload,
    };
};
