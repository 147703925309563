import React from 'react'
import LayoutComponent from "../../components/shared/layout/Layout";
// import './index.scss';
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {Redirect, Switch} from "react-router";
import LeadTimeOptionsTabPaneComponent from "../../components/settings/components/lead-time-options-tab-pane-component/index";
import CostOptionsTabPaneComponent from "../../components/settings/components/cost-options-tab-pane-component/index";

const SettingsPage = () => {
    // Load the vendor detail
    return (
        <LayoutComponent>
            <div className="vendor-tab-cont">
                <Switch>
                    <Route exact path="/settings/lead-time-options/" component={LeadTimeOptionsTabPaneComponent}/>
                    <Route exact path="/settings/cost-options/" component={CostOptionsTabPaneComponent}/>
                    <Redirect to="/settings/cost-options/"/>
                </Switch>
            </div>
        </LayoutComponent>
    )
}

export default connect(null, {
})(SettingsPage);
