import './index.scss';

export const ProductSearchItem = () => {
    return (
        <div className='product-search-item-cont'>
            <div className='product-search-item-content d-flx font-12'>
                <div className="product-image-cont d-flx ai-cen w-15">
                    <img className='w-100' src="https://m.media-amazon.com/images/I/81sBxCT9amS._AC_SY400_.jpg" alt="i"/>
                </div>
                <div className='product-detail-cont'>
                    <div className='product-detail-row d-flx gap-12 margin-8-bottom'>
                        <div className='product-detail-col d-flx flx-d-clm w-15'>
                            <span className='product-detail-col-label text-color-grey'>ASIN</span>
                            <span className='product-detail-col-value font-weight-bold'>B001E0ZRKI</span>
                        </div>
                        <div className='product-detail-col d-flx flx-d-clm'>
                            <span className='product-detail-col-label text-color-grey'>Description</span>
                            <span className='product-detail-col-value font-weight-bold'>SassyCups Best Dad Ever Tumbler | Vacuum Insulated Stainless Steel Dad Travel Mug | Birthday New Dad Tumbler | Worlds Best Dad Cup From Kids | Best Dad Bday | Dad Gifts | Father's Day Gifts</span>
                        </div>
                    </div>
                    <div className='product-detail-row d-flx gap-12 margin-8-bottom'>
                        <div className='product-detail-col d-flx flx-d-clm w-15'>
                            <span className='product-detail-col-label text-color-grey'>UPC</span>
                            <span className='product-detail-col-value font-weight-bold'>B08HD35Y98</span>
                        </div>
                        <div className='d-flx gap-12 flx-1'>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Category</span>
                                <span className='product-detail-col-value font-weight-bold'>Home & Kitchen</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Standard</span>
                                <span className='product-detail-col-value font-weight-bold'>Small & Light</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Product weigh</span>
                                <span className='product-detail-col-value font-weight-bold'>1.21 lbs</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Prep Instructions</span>
                                <span className='product-detail-col-value font-weight-bold'>-Labeling -Polybagging</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Package Quantity</span>
                                <span className='product-detail-col-value font-weight-bold'>4</span>
                            </div>
                        </div>
                    </div>
                    <div className='product-detail-row d-flx gap-12 margin-8-bottom'>
                        <div className='product-detail-col d-flx flx-d-clm w-15'></div>
                        <div className="d-flx flx-1 gap-12">
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Best Sellers Rank</span>
                                <span className='product-detail-col-value font-weight-bold'>23,343</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'></div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'>
                                <span className='product-detail-col-label text-color-grey'>Dimensions</span>
                                <span className='product-detail-col-value font-weight-bold'>23,343</span>
                            </div>
                            <div className='product-detail-col d-flx flx-d-clm w-20'></div>
                            <div className='product-detail-col d-flx flx-d-clm'>
                                <span className='product-detail-col-label text-color-grey'>Buy Box</span>
                                <span className='product-detail-col-value font-weight-bold'>$23.14</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
