import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {Layout, Menu} from 'antd'
import {menuItems} from '../../../constants/menu'
import Loader from "../loader/Loader";
import "./Layout.scss";
import {signOutAction} from "../../../store/actions";

const { Sider, Content } = Layout;
const MenuMatcherRegex = /^\/([a-zA-Z-]+)/
const openKeys = menuItems.filter(item => item.hasSubItems).map(item => item.key);

const LayoutComponent = ({ children, signOutAction, loading, hideSider = false}) => {
    const location = useLocation();

    const onSignOut = () => {
        signOutAction()
    }
    const selectedNavKey = MenuMatcherRegex.exec(location.pathname) ? MenuMatcherRegex.exec(location.pathname)[1] : ''
    console.log({location, selectedNavKey, a: MenuMatcherRegex.exec(location.pathname)});

    return (
        <Layout className='layout-container'>
            {
                !hideSider && <Sider>
                    <div className='logo'>
                        <span className='amz'>AMZ</span><span className='ware'>WARE</span>
                    </div>
                    <Menu
                        mode="inline"
                        theme="dark"
                        selectedKeys={[selectedNavKey]}
                        openKeys={openKeys}
                    >
                        {menuItems.map((item) => {
                            if (item.hasSubItems) {
                                return (
                                    <Menu.SubMenu key={item.key} title={item.title} expandIcon={<></>}>
                                        {item.children.map((subItem) => (
                                            <Menu.Item key={subItem.key}>
                                                <Link to={subItem.path}>{subItem.title}</Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu.SubMenu>
                                )
                            }
                            return (
                                <Menu.Item key={item.key}>
                                    <Link to={item.path}>{item.title}</Link>
                                </Menu.Item>
                            )
                        })}
                        <Menu.Item key="sign-out" onClick={onSignOut}>
                            Sign out
                        </Menu.Item>
                    </Menu>
                </Sider>
            }

            <Layout>
                <Content>
                    <div className="container">
                        {children}
                    </div>
                </Content>
            </Layout>
            {loading && <Loader spinning={true} />}
        </Layout>
    )
}

LayoutComponent.propTypes = {
    children: PropTypes.any.isRequired,
    signOutAction: PropTypes.func,
    loading: PropTypes.bool,
}

LayoutComponent.defaultProps = {
    loading: false,
}

export default
connect(null, {
    signOutAction,
})(LayoutComponent)
