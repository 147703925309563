import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {message} from 'antd'
import CategoryForm from "../../components/category/CategoryForm";
import LayoutComponent from "../../components/shared/layout/Layout";
import {
    createNewCategoryAction,
    getCategoryDetailsAction,
    updateCategoryDetailsAction,
    updateCategoryStateValues,
    resetCategoryInputsValues,
} from "../../store/actions/category.action";
import {useHistory} from "react-router-dom";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";

const CreateCategoryPage = ({success, error, updateCategoryStateValues,resetCategoryInputsValues, id, loading, getCategoryDetailsAction, selectedCategoryInputs, selectedCategory, updateCategoryDetailsAction, createNewCategoryAction}) => {
    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateCategoryStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/category`);
            }
        }
    }, [navigate, id, isNew, success, updateCategoryStateValues])

    useEffect(() => {
        resetCategoryInputsValues(idParam)
        if (idParam !== 'new') {
            getCategoryDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }

    }, [getCategoryDetailsAction, idParam, resetCategoryInputsValues]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateCategoryStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateCategoryStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        console.log({selectedCategory, selectedCategoryInputs})

        updateCategoryStateValues({
            selectedCategoryInputs: {
                ...selectedCategoryInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetCategoryInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        console.log('On Save');
        if (!isNew) {
            updateCategoryDetailsAction(selectedCategoryInputs);
        } else {
            createNewCategoryAction(selectedCategoryInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedCategory, selectedCategoryInputs, id, isNew})
        setViewMode(false);
    };
    return (
        <LayoutComponent loading={loading}>
            <CategoryForm
                viewMode={viewMode}
                isNew={isNew}
                id={idParam}
                onChangeInput={onChangeInput}
            />
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                viewMode={viewMode}
                isNew={isNew}
            />
        </LayoutComponent>
    )
}

CreateCategoryPage.propTypes = {
    loading: PropTypes.bool,
    success: PropTypes.string,
    id: PropTypes.number,
    error: PropTypes.string,
}

const mapStateToProps = ({ categoriesState }) => {
    const selectedCategoryInputs = categoriesState.selectedCategoryInputs;
    const selectedCategory = {...categoriesState.selectedCategory};
    return {
        loading: categoriesState.loading,
        success: categoriesState.success,
        id: categoriesState.id,
        error: categoriesState.error,
        selectedCategoryInputs,
        selectedCategory: Object.keys(selectedCategory).length ? selectedCategory : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateCategoryStateValues,
    getCategoryDetailsAction,
    updateCategoryDetailsAction,
    createNewCategoryAction,
    resetCategoryInputsValues,
})(CreateCategoryPage)
