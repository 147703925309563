import React, {useEffect} from 'react'
import LayoutComponent from "../../../components/shared/layout/Layout";
import './index.scss';
import VendorDetailsTabsComponent from "../components/vendor-details-tabs-component";
import {getVendorDetailsAction} from "../../../store/actions/vendors.action";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

const VendorDetailPage = ({ getVendorDetailsAction}) => {

    const vendorId = useParams().id;
    // Load the vendor detail

    useEffect(() => {
        getVendorDetailsAction(vendorId);
    }, [vendorId, getVendorDetailsAction])

    return (
        <LayoutComponent>
           <div className="vendor-tab-cont">
               <VendorDetailsTabsComponent/>
           </div>
        </LayoutComponent>
    )
}

export default connect(null, {
    getVendorDetailsAction,
})(VendorDetailPage);
