import React, {useCallback, useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import { HTML5Backend } from 'react-dnd-html5-backend'
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {
    getRolesListAction,
    resetRoleInputsValues,
    updateRolesPriorityAction,
    updateRoleStateValues
} from "../../store/actions/roles.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import DraggableBodyRow from "../../components/shared/draggableBodyRow";
import {DndProvider} from "react-dnd";

const RolesPage = ({ getRolesListAction, roles, loading, error, success, updateRoleStateValues, updateRolesPriorityAction }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('priority')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showOrderModal, setShowPriorityModal] = useState(false)

    const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false)
    const [userToDelete, setRoleToDelete] = useState({});

    const rolesTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/roles/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('priority')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewRole(record.id)}>View Role</Button>
                    <Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Role</Button>
                </>
            ),
        }
    ]

    const handleViewRole = (id) => {
        navigate.push(`/roles/${id}`);
    }

    const handleDeleteRole = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        setShowRoleDeleteModal(false)
        // deleteRoleAPI(userToDelete)
    }

    const cancelRoleDelete = () => {
        setRoleToDelete({})
        setShowRoleDeleteModal(false)
    }

    const handleDeleteConfirmation = (user) => {
        setRoleToDelete(user)
        setShowRoleDeleteModal(true)
    }

    const handleSortingOnPriorityCol = () => {
        setShowPriorityModal(false)
        setOrderKey('priority')
        setOrderDirection('asc')
    }

    const cancelSortingOnPriorityCol = () => {
        setShowPriorityModal(false)
    }
    const createNewRole = () => {
        resetRoleInputsValues(true);
        navigate.push('/roles/new');
    }
    useEffect(() => {
        // Call the API to fetch the roles list
        getRolesListAction({
            pageNo,
            pageLimit,
            orderKey,
            orderDirection,
        });
    }, [getRolesListAction, pageNo, pageLimit, orderKey, orderDirection]);

    useEffect(() => {
        // Call the API to fetch the roles list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateRoleStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateRoleStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateRoleStateValues]);

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            if (orderKey !== 'priority') {
                setShowPriorityModal(true)
                return
            }
            updateRolesPriorityAction({
                fromIndex: dragIndex,
                toIndex: hoverIndex,
                orderKey,
                orderDirection,
            })
        },
        [orderDirection, orderKey, updateRolesPriorityAction],
    )
    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createNewRole}>Create New Role</Button>
            </div>
            <DndProvider backend={HTML5Backend}>
                <Table
                    dataSource={roles}
                    columns={rolesTableColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                    rowClassName={(record) => {
                        return null
                        // if (!record.is_enabled) {
                        //     return 'light-red-colored-row'
                        // }
                    }}
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
            </DndProvider>
            <Modal
                title={<b>Role list ordering issue</b>}
                visible={showOrderModal}
                onOk={handleSortingOnPriorityCol}
                onCancel={cancelSortingOnPriorityCol}
                okText={'Sort on priority column'}
                okButtonProps={{ type: 'primary' }}
            >The table must be sorted by on priority before changing the order of elements. Do you want to sort the table by Priority?</Modal>
            <Modal
                title={<b>Are you sure?</b>}
                visible={showRoleDeleteModal}
                onOk={handleDeleteRole}
                onCancel={cancelRoleDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Role {userToDelete.name}?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ rolesState }) => {
    const {
        roles, loading, error, success,
    } = rolesState;

    return {
        roles,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getRolesListAction,
    updateRoleStateValues,
    updateRolesPriorityAction,
})(RolesPage);
