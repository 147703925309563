import {Form, Input, Button, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import "./index.scss";
import {connect} from "react-redux";
import {loginAction, updateAuthValues} from "../../store/actions";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

const SignInComponent = ({loginAction, updateAuthValues, error, success, loading}) => {
    const navigate = useHistory();
    const onSubmit = (values) => {
        loginAction(values);
    };

    useEffect(() => {
        if (!loading) {
            // Show error if the authState is in error state
            if (error) {
                message.error(error);
                // Reset error after showing it up on the UI
                updateAuthValues({
                    error: '',
                });
            }
            // Show success message if the authState is in success state, and redirect to the dashboard page
            if (success) {
                message.success(success);
                // Reset error after showing it up on the UI
                updateAuthValues({
                    success: '',
                });
                navigate.push('/users');
            }
        }
    }, [error, loading, updateAuthValues, navigate, success]);

    return (
        <div className='signup-page'>
            <div className='signup-container'>
                <div className='title-container'>
                    <h3>AMZWare</h3>
                </div>
                <div className='form-container'>

                    <Form
                        name="sign-in-form"
                        className="sign-in-form"
                        onFinish={onSubmit}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

            </div>
        </div>
    );
};


const mapStateToProps = ({ authState }) => {
    const {
        user, loading, error, success,
    } = authState;
    return {
        user,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    loginAction,
    updateAuthValues,
})(SignInComponent);
