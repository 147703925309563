import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {message} from 'antd'
import BrandForm from "../../components/brands/BrandForm";
import LayoutComponent from "../../components/shared/layout/Layout";
import {
    createNewBrandAction,
    getBrandDetailsAction,
    updateBrandDetailsAction,
    updateBrandStateValues,
    resetBrandInputsValues,
} from "../../store/actions/brands.action";
import {useHistory} from "react-router-dom";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";

const CreateProductPage = ({success, error, updateBrandStateValues,resetBrandInputsValues, id, loading, getBrandDetailsAction, selectedBrandInputs, selectedBrand, updateBrandDetailsAction, createNewBrandAction}) => {
    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateBrandStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/brands`);
            }
        }
    }, [navigate, id, isNew, success, updateBrandStateValues])

    useEffect(() => {
        resetBrandInputsValues(idParam)
        if (idParam !== 'new') {
            getBrandDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }

    }, [getBrandDetailsAction, idParam, resetBrandInputsValues]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateBrandStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateBrandStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        console.log({selectedBrand, selectedBrandInputs})

        updateBrandStateValues({
            selectedBrandInputs: {
                ...selectedBrandInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetBrandInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        console.log('On Save');
        if (!isNew) {
            updateBrandDetailsAction(selectedBrandInputs);
        } else {
            createNewBrandAction(selectedBrandInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedBrand, selectedBrandInputs, id, isNew})
        setViewMode(false);
    };
    return (
        <LayoutComponent loading={loading}>
            <BrandForm
                viewMode={viewMode}
                isNew={isNew}
                id={idParam}
                onChangeInput={onChangeInput}
            />
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                viewMode={viewMode}
                isNew={isNew}
            />
        </LayoutComponent>
    )
}

CreateProductPage.propTypes = {
    loading: PropTypes.bool,
    success: PropTypes.string,
    id: PropTypes.number,
    error: PropTypes.string,
}

const mapStateToProps = ({ brandsState }) => {
    const selectedBrandInputs = brandsState.selectedBrandInputs;
    const selectedBrand = {...brandsState.selectedBrand};
    return {
        loading: brandsState.loading,
        success: brandsState.success,
        id: brandsState.id,
        error: brandsState.error,
        selectedBrandInputs,
        selectedBrand: Object.keys(selectedBrand).length ? selectedBrand : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateBrandStateValues,
    getBrandDetailsAction,
    updateBrandDetailsAction,
    createNewBrandAction,
    resetBrandInputsValues,
})(CreateProductPage)
