import React from 'react'
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {Redirect, Switch} from "react-router";
import OrdersListingPage from "../../components/orders/listing/index";
import AddProductsPage from "../../components/orders/add-products/index";
import CreateOrder from "../../components/orders/create-order/index";

const OrdersPage = () => {
    return (
        <div className="vendor-tab-cont">
            <Switch>
                <Route exact path="/orders/" component={OrdersListingPage}/>
                <Route exact path="/orders/new/" component={CreateOrder}/>
                <Route exact path="/orders/new/add-product/" component={AddProductsPage}/>
                <Redirect to="/orders/"/>
            </Switch>
        </div>
    )
}

export default connect(null, {
})(OrdersPage);
