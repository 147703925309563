import API from "../interceptor/axios.interceptor.service";

class AccountInviteBackendApiService {

    static async getAccountInviteInfo(id) {
        const url = `/api/v1/account-invite/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching account invite information, please try refreshing!',
            }
        }
    }

    static async completeInvitation(payload) {
        const url = `/api/v1/account-invite`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching completing the invitation!',
            }
        }
    }
}

export default AccountInviteBackendApiService;