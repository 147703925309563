import {call, put, select, takeLatest} from "redux-saga/effects";

import {GET_LEAD_TIMES_FOR_VENDOR, POST_LEAD_TIMES_FOR_VENDOR} from '../action-types/lead-times.action-type';

import LeadTimesBackendApiService from "../../services/backend-api/lead-times.backend-api.service";
import {
    createNewLeadTimesForVendorFailAction,
    createNewLeadTimesForVendorSuccessAction,
    getLeadTimesForVendorListFailAction,
    getLeadTimesForVendorListSuccessAction,
} from "../actions/lead-times.action";

function* getLeadTimesForVendorList({payload}) {
    try {
        const response = yield call(LeadTimesBackendApiService.getLeadTimesForVendorList, payload);
        if (response.success) {
            const fbm = [], fba = [];
            response.data.rows.forEach(row => {
                if (row.isFBA) {
                    fba.push({
                        ...row,
                        step: row.LeadTimeOptionId,
                        idx: fba.length
                    })
                }else {
                    fbm.push({
                        ...row,
                        step: row.LeadTimeOptionId,
                        idx: fbm.length
                    })
                }
            })
            yield put(getLeadTimesForVendorListSuccessAction({
                ...response.data,
                fbm,
                fba,
            }));
        } else {
            yield put(getLeadTimesForVendorListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getLeadTimesForVendorListFailAction(error.response));
    }
}

function* createNewLeadTimesForVendor({payload}) {
    try {
        const data = yield select(({leadTimesState}) => leadTimesState.vendorsLeadTimeInputs);
        const response = yield call(LeadTimesBackendApiService.updateLeadTimes, {
            vendorId: payload.vendorId,
            del: [...data.fba.del, ...data.fbm.del],
            update: [...data.fba.updates, ...data.fbm.updates]
        });
        if (response.success) {
            yield put(createNewLeadTimesForVendorSuccessAction(response));
        } else {
            yield put(createNewLeadTimesForVendorFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewLeadTimesForVendorFailAction(error.response));
    }
}

function* LeadTimesSaga() {
    yield takeLatest(GET_LEAD_TIMES_FOR_VENDOR, getLeadTimesForVendorList);
    yield takeLatest(POST_LEAD_TIMES_FOR_VENDOR, createNewLeadTimesForVendor);
}

export default LeadTimesSaga;
