import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Button, Col, Form, Input, message, Row, Select} from 'antd'
import {
    createNewUserAction,
    getUserDetailsAction,
    resetUserInputsValues,
    updateGroupUserDetailsAction,
    updateUserStateValues
} from "../../store/actions/users.action";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {searchPermissionsListAction} from "../../store/actions/permissions.action";
import {searchGroupsListAction} from "../../store/actions/groups.action";
import {PREBUILT_GROUPS} from "../../constants/groups.constants";
import Text from "antd/es/typography/Text";
import RolePermissionSelector from "../shared/group-permissions-selector/RolePermissionSelector";
import EffectiveGroupPermission from "../../components/shared/effective-groups-permission/EffectiveGroupPermission";

const UserGroupsForm = ({isAdmin, success, error, selectedUserInputs, id, getUserDetailsAction, searchedGroups, updateUserStateValues, searchedPermissions, resetUserInputsValues, selectedUser, searchPermissionsListAction, searchGroupsListAction, updateGroupUserDetailsAction, createNewUserAction}) => {

    const groupsOptions = searchedGroups.map(group => <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option>)

    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateUserStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/users`);
            }
        }
    }, [navigate, id, isNew, success, updateUserStateValues])

    useEffect(() => {
        resetUserInputsValues(idParam);
        if (idParam !== 'new') {
            getUserDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }
    }, [getUserDetailsAction, idParam, resetUserInputsValues]);

    useEffect(() => {
        console.log('searchPermissionsListAction');
        searchPermissionsListAction();
    }, [searchPermissionsListAction]);

    useEffect(() => {
        searchGroupsListAction();
    }, [searchGroupsListAction]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateUserStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateUserStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target
        updateUserStateValues({
            selectedUserInputs: {
                ...selectedUserInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetUserInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        console.log('On Save');
        if (!isNew) {
            updateGroupUserDetailsAction(selectedUserInputs);
        } else {
            createNewUserAction(selectedUserInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedUser, selectedUserInputs, id, isNew})
        setViewMode(false);
    };
    console.log({s: selectedUserInputs.groups})
    return (
        <div>
            <div>
                <Row gutter={[12, 0]}>
                    <Col span={10}>
                        <Form.Item label="Add more groups" labelCol={{ span: 24 }}>
                            <Select
                                showSearch
                                mode="multiple"
                                value={selectedUserInputs.groups}
                                placeholder={"Groups"}
                                defaultActiveFirstOption={false}
                                showArrow={true}
                                filterOption={true}
                                onChange={(value) => {
                                    onChangeInput({target: {name: 'groups', value,}})
                                }}
                                onClear={() => {
                                    onChangeInput({target: {name: 'groups', value: []}})
                                }}
                                notFoundContent={<>0 results found</>}
                                size="large"
                                allowClear
                                disabled={viewMode}
                                labelInValue={true}
                            >
                                {groupsOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                    {
                        selectedUser && selectedUser.UserGroups ? selectedUser.UserGroups.map(g => (
                            <div className="inner-section">
                                <div className="main-section">
                                    <Row gutter={[12, 0]}>
                                        <Col span={2}>
                                            <Form.Item label="Priority" labelCol={{ span: 24 }}>
                                                <Input name="priority" type="text" placeholder="Priority" size="large" value={g.priority} disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item label="Name" labelCol={{ span: 24 }}>
                                                <Input name="name" type="text" placeholder="Name" size="large" value={g.name} disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="Description" labelCol={{ span: 24 }}>
                                                <Input name="description" type="text" placeholder="Description" size="large" value={g.description} disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item label="Details" labelCol={{ span: 24 }}>
                                                <Button type="primary" className="detail-btn" onClick={() => {  navigate.push(`/groups/${g.id}`) }}>Details</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {
                                        <Row gutter={[12, 0]}>
                                            <Col span={20}>
                                                <RolePermissionSelector
                                                    viewMode={true}
                                                    onChange={() => {}}
                                                    searchedPermissions={searchedPermissions}
                                                    userGroupPermissions={g.UserGroupsPermissions}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                        )) : null
                    }
                <div className="inner-section">
                    <div className="main-section">
                        <Form.Item label="" labelCol={{ span: 24 }}>
                            <Text style={{ fontSize: '16px', marginBottom: '16px', display: "block" }}>List of effective permissions applied from groups</Text>
                            <EffectiveGroupPermission
                                viewMode={true}
                                groupsList={selectedUser.UserGroups}
                                searchedPermissions={searchedPermissions}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                hideEdit={isAdmin}
                viewMode={viewMode}
                isNew={isNew}
            />
        </div>
    )
}

UserGroupsForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedUserInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

UserGroupsForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ usersState, permissionsState, groupsState }) => {
    const selectedUserInputs = usersState.selectedUserInputs;
    const selectedUser = {...usersState.selectedUser};
    return {
        loading: usersState.loading,
        success: usersState.success,
        id: usersState.id,
        isAdmin: selectedUser.UserGroups ? selectedUser.UserGroups.some((group) => group.name === PREBUILT_GROUPS.ADMIN) : false,
        error: usersState.error,
        selectedUserInputs,
        searchedGroups: groupsState.searchedGroups,
        searchedPermissions: permissionsState.searchedPermissions,
        selectedPermissions: usersState.selectedUserInputs.selectedPermissions,
        selectedUser: Object.keys(selectedUser).length ? selectedUser : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateUserStateValues,
    getUserDetailsAction,
    searchPermissionsListAction,
    searchGroupsListAction,
    updateGroupUserDetailsAction,
    createNewUserAction,
    resetUserInputsValues,
})(UserGroupsForm)
