export default class RolesParserService {
    static parseRole (role) {
        let selectedPermissions = {};
        role.RolesPermissions.filter(perm => perm.Permission).forEach((perm => {
            selectedPermissions = {
                ...selectedPermissions,
                [perm.Permission.name]: selectedPermissions[perm.Permission.name]
                    ? [...selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    : [perm.AccessType.name]
            }
        }));
        return {
            ...role,
            selectedPermissions,
        }
    }
    static parseRolesPermissionList (RolesPermissions) {
        let parsedRolesPermissionMap = {};
        RolesPermissions.filter(perm => perm.Permission).forEach((perm => {
            parsedRolesPermissionMap = {
                ...parsedRolesPermissionMap,
                [perm.Role.id]: parsedRolesPermissionMap[perm.Role.id] ? {
                    ...parsedRolesPermissionMap[perm.Role.id],
                    selectedPermissions: {
                        [perm.Permission.name]: [...parsedRolesPermissionMap[perm.Role.id].selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    }
                } :  {
                    Role: perm.Role,
                    selectedPermissions: {
                        [perm.Permission.name]: [perm.AccessType.name]
                    }
                }
            }
        }));
        console.log({parsedRolesPermissionMap})
        return {
            parsedRolesPermissionMap,
        }
    }
    static parsePermissionsFromRolesPermissions (RolesPermissions) {
        let parsedRolesPermissionMap = {};
        RolesPermissions.filter(perm => perm.Permission).forEach((perm => {
            parsedRolesPermissionMap = {
                ...parsedRolesPermissionMap,
                [perm.Permission.name]: parsedRolesPermissionMap[perm.Permission.name] ? [...parsedRolesPermissionMap[perm.Permission.name], perm.AccessType.name] :  [perm.AccessType.name],
            }
        }));
        return {
            parsedRolesPermissionMap,
        }
    }


    static parseEffectivePermissionList (RolesPermissions) {
        let parsedRolesPermissionMap = {};
        RolesPermissions.sort((role1, role2) => role1.priority > role2.priority ? 1 : -1).forEach((role => {
            let roleSpecificPerms = {};
            role.RolesPermissions.map(rolePerm => {
                if (!parsedRolesPermissionMap[rolePerm.Permission.name]) {
                    roleSpecificPerms = {
                        ...roleSpecificPerms,
                        [rolePerm.Permission.name]: roleSpecificPerms[rolePerm.Permission.name]
                            ? [...roleSpecificPerms[rolePerm.Permission.name], rolePerm.AccessType.name] : [rolePerm.AccessType.name]
                    }
                }
                return rolePerm; //doing just bcoz of linter
            });
            parsedRolesPermissionMap = {
                ...parsedRolesPermissionMap,
                ...roleSpecificPerms,
            }
            roleSpecificPerms = {};
        }));
        return {
            parsedRolesPermissionMap,
        }
    }
}