import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row, Select} from 'antd'
import {SuppliersStatus} from "../../constants/suppliers.constant";

const SupplierForm = ({selectedSupplierInputs, viewMode, isNew, id, onChangeInput}) => {
    const statusOptions = Object.keys(SuppliersStatus).map(supplier => (<Select.Option key={supplier} value={supplier}>{supplier}</Select.Option>))
    return (
        <Row gutter={[12, 0]}>
            <Col span={10}>
                <Form.Item label="Business First name" labelCol={{ span: 24 }}>
                    <Input name="firstName" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedSupplierInputs.firstName} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Business Last name" labelCol={{ span: 24 }}>
                    <Input name="lastName" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedSupplierInputs.lastName} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Contact First name" labelCol={{ span: 24 }}>
                    <Input name="contactFirstName" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedSupplierInputs.contactFirstName} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Contact Last name" labelCol={{ span: 24 }}>
                    <Input name="contactLastName" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedSupplierInputs.contactLastName} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="E-mail" labelCol={{ span: 24 }}>
                    <Input name="email" type="text" placeholder="Email" size="large" onChange={onChangeInput} value={selectedSupplierInputs.email} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Phone" labelCol={{ span: 24 }}>
                    <Input name="phone" type="phone" placeholder="Phone" size="large" onChange={onChangeInput} value={selectedSupplierInputs.phone} disabled={viewMode} />
                </Form.Item>
            </Col>
            { !isNew && (<Col span={10}>
                <Form.Item label="Supplier status" labelCol={{span: 24}}>
                    <Select
                        showSearch
                        value={selectedSupplierInputs.status}
                        placeholder={"Status"}
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={true}
                        onChange={(value) => {
                            onChangeInput({target: {name: 'status', value,}})
                        }}
                        notFoundContent={<>0 results found</>}
                        size="large"
                        disabled={viewMode}
                        labelInValue={true}
                    >
                        {statusOptions}
                    </Select>
                </Form.Item>
            </Col>)}
            <Col span={10}>
                <Form.Item label="Location" labelCol={{ span: 24 }}>
                    <Input name="location" type="text" placeholder="Phone" size="large" onChange={onChangeInput} value={selectedSupplierInputs.location} disabled={viewMode} />
                </Form.Item>
            </Col>
            {
                !isNew && (
                    <Col span={10}>
                        <Form.Item label="Attached Permission" labelCol={{ span: 24 }}>
                            <Input name="permission" type="text" placeholder="Attached Permission" size="large" value={selectedSupplierInputs.Permission ? selectedSupplierInputs.Permission.name : ''} disabled={true} />
                        </Form.Item>
                    </Col>
                )
            }
        </Row>
    )
}

SupplierForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedSupplierInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

SupplierForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ suppliersState }) => {
    return {
        selectedSupplierInputs: suppliersState.selectedSupplierInputs,
    }
}

export default connect(mapStateToProps)(SupplierForm)
