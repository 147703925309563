import {combineReducers} from "redux";

import AuthReducer from "./auth.reducer";
import UsersReducer from "./users.reducer";
import PermissionsReducer from "./permissions.reducer";
import RolesReducer from "./roles.reducer";
import GroupsReducer from "./groups.reducer";
import SuppliersReducer from "./suppliers.reducer";
import BrandsReducer from "./brands.reducer";
import CategoryReducer from "./category.reducer";
import ProductsReducer from "./products.reducer";
import VendorsReducer from "./vendors.reducer";
import LeadTimesReducer from "./lead-times.reducer";
import LeadTimeOptionsReducer from "./lead-time-options.reducer";
import CostOptionsReducer from "./cost-options.reducer";
import CostsReducer from "./costs.reducer";
import OrdersReducer from "./orders.reducer";

const rootReducer = combineReducers({
    authState: AuthReducer,
    usersState: UsersReducer,
    permissionsState: PermissionsReducer,
    rolesState: RolesReducer,
    groupsState: GroupsReducer,
    suppliersState: SuppliersReducer,
    brandsState: BrandsReducer,
    categoriesState: CategoryReducer,
    productsState: ProductsReducer,
    vendorsState: VendorsReducer,
    ordersState: OrdersReducer,
    leadTimesState: LeadTimesReducer,
    leadTimeOptionsState: LeadTimeOptionsReducer,
    costOptionsState: CostOptionsReducer,
    costsState: CostsReducer,
});

export default rootReducer;
