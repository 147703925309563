export const UPDATE_VALUES = "LEAD_TIME.UPDATE_LEAD_TIME_VALUES";
export const UPDATE_FORM_VALUES = "LEAD_TIME.UPDATE_LEAD_TIME_FORM_VALUES";

export const RESET_FORM_VALUES = "LEAD_TIME.RESET_LEAD_TIME_FORM_VALUES";

export const GET_LEAD_TIMES_FOR_VENDOR = "LEAD_TIME.GET_LEAD_TIMES_FOR_VENDOR";
export const GET_LEAD_TIMES_FOR_VENDOR_SUCCESS = "LEAD_TIME.GET_LEAD_TIMES_FOR_VENDOR_SUCCESS";
export const GET_LEAD_TIMES_FOR_VENDOR_FAIL = "LEAD_TIME.GET_LEAD_TIMES_FOR_VENDOR_FAIL";

export const POST_LEAD_TIMES_FOR_VENDOR = "LEAD_TIME.POST_LEAD_TIMES_FOR_VENDOR";
export const POST_LEAD_TIMES_FOR_VENDOR_SUCCESS = "LEAD_TIME.POST_LEAD_TIMES_FOR_VENDOR_SUCCESS";
export const POST_LEAD_TIMES_FOR_VENDOR_FAIL = "LEAD_TIME.POST_LEAD_TIMES_FOR_VENDOR_FAIL";
