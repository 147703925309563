import React from 'react'
import {Breadcrumb, Button, Card} from 'antd'
import { useLocation, Link } from 'react-router-dom'
import "./Breadcrumb.scss";

const BreadcrumbComponent = ({actionButtons}) => {
  const location = useLocation();

  const { pathname } = location;

  const paths = pathname.split('/')

  const getLink = (index) => {
    let link = ''
    paths.map((x, i) => {
      if (i <= index) {
        const withSlash = i !== 0 ? '/' : ''
        link += `${withSlash}${x}`
      }
      return true
    })

    return link
  }

  return (
      <div className='breadcrumb-container'>
        <Card size="small">
          <div className='d-flx ai-cen jc-between'>
            <Breadcrumb>
              {paths.map((path, index) => path !== '' ? (
                  <Breadcrumb.Item key={index} className='breadcrumb-item'>
                    { (paths.length - 1) === index
                        ? <span>{path}</span>
                        : (
                            <Link to={getLink(index)}>
                              <span>{path}</span>
                            </Link>
                        )
                    }
                  </Breadcrumb.Item>
              ) : null)}
            </Breadcrumb>
            <div className='breadcrumb-actions d-flx gap-12'>
              {
                actionButtons.map(actionButton => (
                    <Button onClick={actionButton.onClick} type={actionButton.type}>{actionButton.label}</Button>
                ))
              }
            </div>
          </div>
        </Card>
      </div>
  )
}

export default BreadcrumbComponent
