import {
    GET_COSTS_FOR_VENDOR,
    GET_COSTS_FOR_VENDOR_FAIL,
    GET_COSTS_FOR_VENDOR_SUCCESS,
    POST_COSTS_FOR_VENDOR,
    POST_COSTS_FOR_VENDOR_FAIL,
    POST_COSTS_FOR_VENDOR_SUCCESS,
    RESET_COST_FORM_VALUES,
    UPDATE_COST_FORM_VALUES,
    UPDATE_COST_VALUES
} from "../action-types/costs.action-type";

export const updateCostsStateValues = (payload) => {
    return {
        type: UPDATE_COST_VALUES,
        payload,
    };
};

export const updateCostsFormStateValues = (payload) => {
    return {
        type: UPDATE_COST_FORM_VALUES,
        payload,
    };
};

export const resetCostsInputsValues = (isNew) => ({
    type: RESET_COST_FORM_VALUES,
    payload: { isNew },
})

export const createNewCostsForVendorAction = (payload) => {
    return {
        type: POST_COSTS_FOR_VENDOR,
        payload,
    };
};

export const createNewCostsForVendorSuccessAction = (payload) => {
    return {
        type: POST_COSTS_FOR_VENDOR_SUCCESS,
        payload
    };
};

export const createNewCostsForVendorFailAction = (payload) => {
    return {
        type: POST_COSTS_FOR_VENDOR_FAIL,
        payload,
    };
};

export const getCostsForVendorListAction = (payload) => ({
    type: GET_COSTS_FOR_VENDOR,
    payload
});

export const getCostsForVendorListSuccessAction = (payload) => {
    return {
        type: GET_COSTS_FOR_VENDOR_SUCCESS,
        payload
    };
};

export const getCostsForVendorListFailAction = (payload) => {
    return {
        type: GET_COSTS_FOR_VENDOR_FAIL,
        payload,
    };
};
