import {
    GET_COST_OPTIONS,
    GET_COST_OPTIONS_FAIL,
    GET_COST_OPTIONS_SUCCESS,
    POST_COST_OPTIONS,
    POST_COST_OPTIONS_FAIL,
    POST_COST_OPTIONS_SUCCESS,
    RESET_COST_OPTION_FORM_VALUES,
    UPDATE_COST_OPTION_FORM_VALUES,
    UPDATE_COST_OPTION_VALUES
} from "../action-types/cost-options.action-type";

export const updateCostOptionsStateValues = (payload) => {
    return {
        type: UPDATE_COST_OPTION_VALUES,
        payload,
    };
};

export const updateCostOptionsFormStateValues = (payload) => {
    return {
        type: UPDATE_COST_OPTION_FORM_VALUES,
        payload,
    };
};

export const resetCostOptionsInputsValues = (isNew) => ({
    type: RESET_COST_OPTION_FORM_VALUES,
    payload: { isNew },
})

export const createNewCostOptionsAction = () => {
    return {
        type: POST_COST_OPTIONS,
    };
};

export const createNewCostOptionsSuccessAction = (payload) => {
    return {
        type: POST_COST_OPTIONS_SUCCESS,
        payload
    };
};

export const createNewCostOptionsFailAction = (payload) => {
    return {
        type: POST_COST_OPTIONS_FAIL,
        payload,
    };
};

export const getCostOptionsAction = (payload) => ({
    type: GET_COST_OPTIONS,
    payload
});

export const getCostOptionsSuccessAction = (payload) => {
    return {
        type: GET_COST_OPTIONS_SUCCESS,
        payload
    };
};

export const getCostOptionsFailAction = (payload) => {
    return {
        type: GET_COST_OPTIONS_FAIL,
        payload,
    };
};
