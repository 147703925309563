import {call, put, select, takeLatest} from "redux-saga/effects";

import {GET_COSTS_FOR_VENDOR, POST_COSTS_FOR_VENDOR} from '../action-types/costs.action-type';

import CostsBackendApiService from "../../services/backend-api/costs.backend-api.service";
import {
    createNewCostsForVendorFailAction,
    createNewCostsForVendorSuccessAction,
    getCostsForVendorListFailAction,
    getCostsForVendorListSuccessAction,
} from "../actions/costs.action";

function* getCostsForVendorList({payload}) {
    try {
        const response = yield call(CostsBackendApiService.getCostsForVendorList, payload);
        if (response.success) {
            const fbm = [], fba = [];
            response.data.rows.forEach(row => {
                if (row.isFBA) {
                    fba.push({
                        ...row,
                        step: row.CostOptionId,
                        idx: fba.length
                    })
                }else {
                    fbm.push({
                        ...row,
                        step: row.CostOptionId,
                        idx: fbm.length
                    })
                }
            })
            yield put(getCostsForVendorListSuccessAction({
                ...response.data,
                fbm,
                fba,
            }));
        } else {
            yield put(getCostsForVendorListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getCostsForVendorListFailAction(error.response));
    }
}

function* createNewCostsForVendor({payload}) {
    try {
        console.log(payload);
        const data = yield select(({costsState}) => costsState.vendorsCostInputs);
        const response = yield call(CostsBackendApiService.updateCosts, {
            vendorId: payload.vendorId,
            del: [...data.fba.del, ...data.fbm.del],
            update: [...data.fba.updates, ...data.fbm.updates]
        });
        if (response.success) {
            yield put(createNewCostsForVendorSuccessAction(response));
        } else {
            yield put(createNewCostsForVendorFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewCostsForVendorFailAction(error.response));
    }
}

function* CostsSaga() {
    yield takeLatest(GET_COSTS_FOR_VENDOR, getCostsForVendorList);
    yield takeLatest(POST_COSTS_FOR_VENDOR, createNewCostsForVendor);
}

export default CostsSaga;
