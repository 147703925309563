import FormComponent from "../../../shared/form-component";
import FormConstants from "../../../../constants/form.constants";
import {useSelector} from "react-redux";

const AddressFormComponent = ({onChange, address}) => {
    const formFields = [{
        ...FormConstants.fullName,
        label: 'Address line 1 (Street Address, P.O. box)',
        placeholder: 'Address line 1 (Street Address, P.O. box)',
        onChange,
        value: address.line1,
        name: 'line1',
    }, {
        ...FormConstants.fullName,
        label: 'Address line 2 (Apartment, Suite, Unit, Building, floor, etc.)',
        placeholder: 'Address line 2 (Apartment, Suite, Unit, Building, floor, etc.)',
        onChange,
        value: address.line2,
        name: 'line2'
    }, {
        ...FormConstants.fullName,
        label: 'City',
        placeholder: 'City',
        onChange,
        value: address.city,
        name: 'city'
    }, {
        ...FormConstants.fullName,
        label: 'Zip',
        placeholder: 'Zip',
        onChange,
        value: address.zip,
        name: 'zip'
    }, {
        ...FormConstants.fullName,
        label: 'State',
        placeholder: 'State',
        onChange,
        value: address.state,
        name: 'state'
    }, {
        ...FormConstants.fullName,
        label: 'Country',
        placeholder: 'Country',
        onChange,
        value: address.country,
        name: 'country'
    }];

    const {isEditing} = useSelector(({vendorsState}) => {
        return {
            isEditing: vendorsState.isEditing,
        }
    })
    return (
        <div className='address-form-component'>
            <FormComponent formFields={formFields} colSpan={12} viewOnly={!isEditing}/>
        </div>
    )
}

export default AddressFormComponent;
