import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, message, Row} from 'antd'
import PermissionSelector from "../shared/permission-selector/PermissionSelector";
import {
    createNewUserAction,
    getUserDetailsAction,
    resetUserInputsValues,
    updatePermissionUserDetailsAction,
    updateUserStateValues
} from "../../store/actions/users.action";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {searchPermissionsListAction} from "../../store/actions/permissions.action";
import {searchRolesListAction} from "../../store/actions/roles.action";
import {searchGroupsListAction} from "../../store/actions/groups.action";
import {PREBUILT_GROUPS} from "../../constants/groups.constants";
import Text from "antd/es/typography/Text";

const UserPermissionsForm = ({isAdmin, success, error, selectedUserInputs, id, getUserDetailsAction, updateUserStateValues, searchedPermissions, selectedPermissions, resetUserInputsValues, selectedUser, searchPermissionsListAction, searchRolesListAction, searchGroupsListAction, updatePermissionUserDetailsAction, createNewUserAction}) => {

    const permissionChangeHandler = (selectedPermissions) => {
        onChangeInput({
            target: {
                name: 'selectedPermissions',
                value: selectedPermissions
            },
        });
    }
    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateUserStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/users`);
            }
        }
    }, [navigate, id, isNew, success, updateUserStateValues])

    useEffect(() => {
        resetUserInputsValues(idParam);
        if (idParam !== 'new') {
            getUserDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }
    }, [getUserDetailsAction, idParam, resetUserInputsValues]);

    useEffect(() => {
        console.log('searchPermissionsListAction');
        searchPermissionsListAction();
    }, [searchPermissionsListAction]);

    useEffect(() => {
        searchRolesListAction();
    }, [searchRolesListAction]);

    useEffect(() => {
        searchGroupsListAction();
    }, [searchGroupsListAction]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateUserStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateUserStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target
        updateUserStateValues({
            selectedUserInputs: {
                ...selectedUserInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetUserInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        console.log('On Save');
        if (!isNew) {
            updatePermissionUserDetailsAction(selectedUserInputs);
        } else {
            createNewUserAction(selectedUserInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedUser, selectedUserInputs, id, isNew})
        setViewMode(false);
    };
    return (
        <div>
            <div>
                <Row gutter={[12, 0]}>
                    <Col span={20}>
                        <Form.Item label="" labelCol={{ span: 24 }}>
                            <Text style={{ fontSize: '16px', marginBottom: '16px', display: "block" }}>List of permissions directly attached to the user</Text>
                            <PermissionSelector
                                viewMode={viewMode}
                                onChange={permissionChangeHandler}
                                searchedPermissions={searchedPermissions}
                                selectedPermissions={selectedPermissions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                hideEdit={isAdmin}
                viewMode={viewMode}
                isNew={isNew}
            />
        </div>
    )
}

UserPermissionsForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedUserInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

UserPermissionsForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ usersState, permissionsState, groupsState, rolesState }) => {
    const selectedUserInputs = usersState.selectedUserInputs;
    const selectedUser = {...usersState.selectedUser};
    return {
        loading: usersState.loading,
        success: usersState.success,
        id: usersState.id,
        isAdmin: selectedUser.UserGroups ? selectedUser.UserGroups.some((group) => group.name === PREBUILT_GROUPS.ADMIN) : false,
        error: usersState.error,
        selectedUserInputs,
        searchedRoles: rolesState.searchedRoles,
        searchedGroups: groupsState.searchedGroups,
        searchedPermissions: permissionsState.searchedPermissions,
        selectedPermissions: usersState.selectedUserInputs.selectedPermissions,
        selectedUser: Object.keys(selectedUser).length ? selectedUser : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateUserStateValues,
    getUserDetailsAction,
    searchPermissionsListAction,
    searchRolesListAction,
    searchGroupsListAction,
    updatePermissionUserDetailsAction,
    createNewUserAction,
    resetUserInputsValues,
})(UserPermissionsForm)
