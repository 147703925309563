export const UPDATE_VALUES = "UPDATE_SUPPLIER_VALUES";

export const RESET_FORM_VALUES = "RESET_SUPPLIER_FORM_VALUES";

export const GET_SUPPLIER = "GET_SUPPLIER";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAIL = "GET_SUPPLIER_FAIL";

export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_FAIL = "GET_SUPPLIERS_FAIL";

export const POST_SUPPLIER = "POST_SUPPLIER";
export const POST_SUPPLIER_SUCCESS = "POST_SUPPLIER_SUCCESS";
export const POST_SUPPLIER_FAIL = "POST_SUPPLIER_FAIL";

export const PUT_SUPPLIER = "PUT_SUPPLIER";
export const PUT_SUPPLIER_SUCCESS = "PUT_SUPPLIER_SUCCESS";
export const PUT_SUPPLIER_FAIL = "PUT_SUPPLIER_FAIL";