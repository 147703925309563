export const UPDATE_VALUES = "UPDATE_PRODUCT_VALUES";

export const RESET_FORM_VALUES = "RESET_PRODUCT_FORM_VALUES";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const POST_PRODUCT = "POST_PRODUCT";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";
export const POST_PRODUCT_FAIL = "LOGIN_FAIL";

export const PUT_PRODUCT = "PUT_PRODUCT";
export const PUT_PRODUCT_SUCCESS = "PUT_PRODUCT_SUCCESS";
export const PUT_PRODUCT_FAIL = "PUT_PRODUCT_FAIL";