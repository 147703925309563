import FormComponent from "../../../shared/form-component";
import FormConstants from "../../../../constants/form.constants";
import AddressFormComponent from "../address-form-component";
import {connect} from "react-redux";
import {updateVendorFormStateValues, updateVendorStateValues} from "../../../../store/actions/vendors.action";

const BusinessInfoComponent = ({updateVendorFormStateValues, isEditing, loading, selectedVendorInputs}) => {
    const handleChange = ({name, value}) => {
        updateVendorFormStateValues({
            [name]: value,
        });
    }
    const handleAddressChange = ({name, value}) => {
        updateVendorFormStateValues({
            address: {
                ...selectedVendorInputs.address,
                [name]: value,
            }
        });
    }

    const formFields = [{
        ...FormConstants.fullName,
        label: 'Business Name',
        value: selectedVendorInputs.name,
        onChange: handleChange,
    }, {
        ...FormConstants.phone,
        value: selectedVendorInputs.phone,
        onChange: handleChange,
    }, {
        ...FormConstants.email,
        value: selectedVendorInputs.email,
        onChange: handleChange,
    }, {
        ...FormConstants.website,
        value: selectedVendorInputs.websiteLink,
        onChange: handleChange,
    }];
    // Form Handling via the Redux Store
    return (
        <div className='business-info-component'>
            <FormComponent formFields={formFields} colSpan={12} viewOnly={!isEditing} />
            <AddressFormComponent onChange={handleAddressChange} address={selectedVendorInputs.address} />
        </div>
    )
}

const mapStateToProps = ({ vendorsState }) => {
    const selectedVendorInputs = vendorsState.selectedVendorInputs;
    return {
        loading: vendorsState.loading,
        success: vendorsState.success,
        isEditing: vendorsState.isEditing,
        id: vendorsState.id,
        error: vendorsState.error,
        selectedVendorInputs,
    }
}
export default connect(mapStateToProps, {
    updateVendorStateValues,
    updateVendorFormStateValues,
})(BusinessInfoComponent);
