import API from "../interceptor/axios.interceptor.service";

class AuthBackendApiService {
    static async signin({email, password}) {
        const url = `/api/v1/auth/sign-in`;
        try {
            const response = await API.post(url, {email, password});
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong, please try again!',
            }
        }
    }

    static async me() {
        const url = `/api/v1/auth/me`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong getting information from token!',
            }
        }
    }

    static async signOut() {
        const url = `/api/v1/auth/sign-out`;
        try {
            const response = await API.post(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while logging you out!',
            }
        }
    }
}

export default AuthBackendApiService;