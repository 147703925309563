import API from "../interceptor/axios.interceptor.service";

class GroupsBackendApiService {
    static async getGroupDetail(id) {
        const url = `/api/v1/groups/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching group detail, please try again!',
            }
        }
    }

    static async createNewGroup(payload) {
        const url = `/api/v1/groups`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating the new group!',
            }
        }
    }

    static async getGroupsList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/groups/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}&orderKey=${payload.orderKey}&orderDirection=${payload.orderDirection}` : `/api/v1/groups/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching groups list!',
            }
        }
    }

    static async searchGroupsList(payload) {
        const url = `/api/v1/groups/?searchTerm=${payload.searchTerm}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while searching for groups list!',
            }
        }
    }

    static async updateUserDetails() {
        const url = `/api/v1/auth/sign-out`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while logging you out!',
            }
        }
    }
    static async updateGroupDetails(payload) {
        const url = `/api/v1/groups/${payload.id}`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                name: payload.name,
                description: payload.description,
                selectedPermissions: payload.selectedPermissions,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the group detail!',
            }
        }
    }
    static async updateGroupsPriority(payload) {
        const url = `/api/v1/groups/update-priority/`;
        try {
            const response = await API.post(url, {
                fromId: payload.fromId, // Currently undefined, check if we should remove it
                toId: payload.toId, // Currently undefined, check if we should remove it
                fromIndex: payload.fromIndex,
                toIndex: payload.toIndex,
                orderKey: payload.orderKey,
                orderDirection: payload.orderDirection,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the groups priority!',
            }
        }
    }
}

export default GroupsBackendApiService;