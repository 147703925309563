import {put, takeLatest, call, select} from "redux-saga/effects";

import {GET_VENDOR, PUT_VENDOR, GET_VENDORS, POST_VENDOR} from '../action-types/vendors.action-type';

import VendorsBackendApiService from "../../services/backend-api/vendors.backend-api.service";
import {
    createNewVendorFailAction,
    createNewVendorSuccessAction,
    getVendorDetailsFailAction,
    getVendorDetailsSuccessAction, getVendorsListFailAction,
    getVendorsListSuccessAction, updateVendorDetailsFailAction, updateVendorDetailsSuccessAction
} from "../actions/vendors.action";
import {addressInitialState} from "../reducers/vendors.reducer";

function* vendorDetails({payload}) {
    try {
        const response = yield call(VendorsBackendApiService.getVendorDetail, payload);
        console.log(response);
        if (response.success) {
            const contacts = response.data.vendor.Contacts;
            const parsedPayload = {
                ...response.data,
                vendor: {
                    ...response.data.vendor,
                    contacts: contacts.map((contact, idx) => ({idx, ...contact, address: contact.Address})),
                    address: response.data.vendor.Address || addressInitialState,
                }
            }
            yield put(getVendorDetailsSuccessAction(parsedPayload));
        } else {
            yield put(getVendorDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getVendorDetailsFailAction(error.response));
    }
}

function* getVendorsList({payload}) {
    try {
        const response = yield call(VendorsBackendApiService.getVendorsList, payload);
        if (response.success) {
            yield put(getVendorsListSuccessAction({
                ...response.data,
                rows: response.data.rows.map((row) => {
                    return {
                        ...row,
                        contacts: row.Contacts,
                    }
                })
            }));
        } else {
            yield put(getVendorsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getVendorsListFailAction(error.response));
    }
}

function* updateVendorDetails() {
    try {
        const vendorPayload = yield select(({vendorsState}) => vendorsState.selectedVendorInputs);
        const response = yield call(VendorsBackendApiService.updateVendorDetails, {
            ...vendorPayload,
        });
        if (response.success) {
            yield put(updateVendorDetailsSuccessAction(response));
        } else {
            yield put(updateVendorDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateVendorDetailsFailAction(error.response));
    }
}


function* createNewVendor() {
    console.log('CREATE VENDOR SAGA')
    try {
        const vendorPayload = yield select(({vendorsState}) => vendorsState.selectedVendorInputs);
        console.log({vendorPayload});
        const response = yield call(VendorsBackendApiService.createNewVendor, vendorPayload);
        if (response.success) {
            yield put(createNewVendorSuccessAction(response));
        } else {
            yield put(createNewVendorFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewVendorFailAction(error.response));
    }
}

function* VendorsSaga() {
    yield takeLatest(GET_VENDOR, vendorDetails);
    yield takeLatest(GET_VENDORS, getVendorsList);
    yield takeLatest(POST_VENDOR, createNewVendor);
    yield takeLatest(PUT_VENDOR, updateVendorDetails);
}

export default VendorsSaga;
