import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row} from 'antd'

const BrandForm = ({selectedBrandInputs, viewMode, onChangeInput}) => {

    return (
        <Row gutter={[12, 0]}>
            <Col span={10}>
                <Form.Item label="Name" labelCol={{ span: 24 }}>
                    <Input name="name" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedBrandInputs.name} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Description" labelCol={{ span: 24 }}>
                    <Input name="description" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedBrandInputs.description} disabled={viewMode} />
                </Form.Item>
            </Col>
        </Row>
    )
}

BrandForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedBrandInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

BrandForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ brandsState }) => {
    return {
        selectedBrandInputs: brandsState.selectedBrandInputs,
    }
}

export default connect(mapStateToProps)(BrandForm)
