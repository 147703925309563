import './index.scss';
import { Input } from 'antd';
import {useState} from "react";

const { Search } = Input;

export const AddProductSearch = () => {
    const [loading] = useState();

    return (
        <div>
            <Search placeholder="Product name, UPC, EAN, ISBN or ASIN" loading={loading} enterButton="Search" size='large'/>
        </div>
    )
}
