import {
    GET_PRODUCT,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
    POST_PRODUCT,
    POST_PRODUCT_FAIL,
    POST_PRODUCT_SUCCESS,
    PUT_PRODUCT,
    PUT_PRODUCT_FAIL,
    PUT_PRODUCT_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/products.action-type";

const initialState = {
    products: [],
    selectedProduct: {},
    searchedProducts: [],
    selectedProductInputs: {
        name: '',
        description: '',
    },
    loading: false,
    error: '',
    success: '',
};

const ProductsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_PRODUCT:
            state = { ...state, loading: true };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedProductInputs: payload.isNew ? initialState.selectedProductInputs : state.selectedProduct };
            break;
        case GET_PRODUCT_SUCCESS:
            state = { ...state, selectedProduct: payload.product, selectedProductInputs: payload.product, loading: false, success: payload.message };
            break;
        case GET_PRODUCT_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_PRODUCTS:
            state = { ...state, loading: true };
            break;
        case GET_PRODUCTS_SUCCESS:
            state = { ...state, products: payload.rows, loading: false };
            break;
        case GET_PRODUCTS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_PRODUCT:
            state = { ...state, loading: true };
            break;
        case POST_PRODUCT_SUCCESS:
            state = { ...state, selectedProduct: payload.product, loading: false, success: 'New Product created successfully!' };
            break;
        case POST_PRODUCT_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_PRODUCT:
            state = { ...state, loading: true };
            break;
        case PUT_PRODUCT_SUCCESS:
            state = { ...state, selectedProduct: payload.product, loading: false, success: 'product updated successfully' };
            break;
        case PUT_PRODUCT_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default ProductsReducer;
