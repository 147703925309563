import API from "../interceptor/axios.interceptor.service";

class ProductsBackendApiService {
    static async getProductDetail(id) {
        const url = `/api/v1/products/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching product detail, please try again!',
            }
        }
    }

    static async createNewProduct(payload) {
        const url = `/api/v1/products`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating the new product!',
            }
        }
    }

    static async getProductsList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/products/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/products/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching products list!',
            }
        }
    }

    static async updateProductDetails(payload) {
        const url = `/api/v1/products/${payload.id}`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                name: payload.name,
                description: payload.description,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the product detail!',
            }
        }
    }
}

export default ProductsBackendApiService;