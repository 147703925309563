import React, {useEffect, useState} from 'react';
import {Checkbox, Empty} from 'antd';
import {ACCESS_TYPES} from "../../../constants/Permissions.constant";
import './index.scss';
import RolesParserService from "../../../services/parsers/roles.parser.service";

const CheckboxRole = Checkbox.Group;

const accessTypeOptions = [ACCESS_TYPES.CREATE, ACCESS_TYPES.VIEW, ACCESS_TYPES.UPDATE, ACCESS_TYPES.DELETE];

const RolePermissionSelector = ({onChange, viewMode, searchedPermissions, rolePermissions}) => {
    const [parsedRolesPermissions, setParsedRolesPermissions] = useState({});
    const onChangeHandler = (perm, options) => {
        onChange({
            ...parsedRolesPermissions,
            [perm.name]: options,
        })
    }
    useEffect(() => {
        if (rolePermissions) {
            const parsedData = RolesParserService.parsePermissionsFromRolesPermissions(rolePermissions);
            setParsedRolesPermissions(parsedData.parsedRolesPermissionMap);
        }
    }, [rolePermissions])
    return (
        <div className={'permission-selector-container'}>
            {
                searchedPermissions.map(perm => {
                    if (!(viewMode && Object.keys(parsedRolesPermissions).indexOf(perm.name) === -1)) {
                        return (
                            <div className='permission-container'>
                                <div className='permission-name'>
                                    Id: [{perm.id}], Name: [{perm.name}], Type: [{perm.type}]
                                </div>
                                <div className='permission-description'>
                                    {perm.description}
                                </div>
                                <div className='permission-options'>
                                    <CheckboxRole
                                        options={accessTypeOptions}
                                        value={parsedRolesPermissions[perm.name] || []}
                                        onChange={(e) => onChangeHandler(perm, e)}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        return null;
                    }
                })
            }
            {
                searchedPermissions.length !== 0 && Object.keys(parsedRolesPermissions).length === 0 && (
                    <div className='d-flx'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )
            }
        </div>
    );
}

export default RolePermissionSelector;