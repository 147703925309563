import {Button, Card, message} from "antd";
import './index.scss';
import {connect} from "react-redux";
import {
    resetCostsInputsValues,
    updateCostsStateValues,
    updateCostsFormStateValues,
    createNewCostsForVendorAction, getCostsForVendorListAction,
} from "../../../../store/actions/costs.action";
import BreadcrumbComponent from "../../../../components/shared/breadcrumb/Breadcrumb";
import VendorsTabs from "../../../../components/vendors/components/vendors-tabs";
import {useCallback, useEffect, useState} from "react";
import {costInitialState} from "../../../../store/reducers/costs.reducer";
import CostFormItem from "../../../../components/shared/cost-form-item";
import {getCostOptionsAction} from "../../../../store/actions/cost-options.action";
import {useParams} from "react-router-dom";

const VendorCostTabPaneComponent = ({ getCostOptionsAction, createNewCostsForVendorAction,resetCostsInputsValues, updateCostsStateValues, updateCostsFormStateValues, getCostsForVendorListAction, isEditing, loading, success, error, fbaInputs, fbmInputs,fbaDeletes, fbmDeletes, costOptions}) => {

    const vendorId = useParams().id;

    const handleEdit = useCallback(() => {
        updateCostsStateValues({
            isEditing: true,
        })
        setActionButtons([{
            label: 'Cancel',
            type: 'secondary',
            onClick: handleCancel,
        }, {
            label: 'Save',
            type: 'primary',
            onClick: handleSave,
        }])
        // eslint-disable-next-line
    }, [updateCostsStateValues])

    const handleCancel = useCallback(() => {
        resetCostsInputsValues();
        setActionButtons([{
            label: 'Edit',
            type: 'primary',
            onClick: handleEdit,
        }])
        // eslint-disable-next-line
    }, [resetCostsInputsValues])

    const handleSave = useCallback(() => {
        console.log({vendorId});
        createNewCostsForVendorAction({vendorId});
    }, [createNewCostsForVendorAction, vendorId])

    const [actionButtons, setActionButtons] = useState([{
        label: 'Edit',
        type: 'primary',
        onClick: handleEdit,
    }]);

    useEffect(() => {
        if (!loading) {
            if (success) {
                message.success(success).then(() => {
                    updateCostsStateValues({
                        success: '',
                    });
                    setActionButtons([{
                        label: 'Edit',
                        type: 'primary',
                        onClick: handleEdit,
                    }])
                })
            } else if (error) {
                message.error(error).then(() => {
                    updateCostsStateValues({
                        error: '',
                    });
                    setActionButtons([{
                        label: 'Cancel',
                        type: 'secondary',
                        onClick: handleCancel,
                    }, {
                        label: 'Save',
                        type: 'primary',
                        onClick: handleSave,
                    }])
                })
            }

        }
    }, [loading, success, error, handleCancel, handleEdit, handleSave, createNewCostsForVendorAction, updateCostsStateValues])

    useEffect(() => {
        // Fetch the list of lead time options
        getCostOptionsAction();
        // fetch the list of lead times
        getCostsForVendorListAction({vendorId});
        // eslint-disable-next-line
    }, [])

    const handleAddFBMCost = () => {
       updateCostsFormStateValues({
           fbm: {
               del: fbmDeletes,
               updates: [
                   ...fbmInputs,
                   {...costInitialState, idx: fbmInputs.length + 1, isFBM: true,},
               ]
           },
       });
    }

    const handleAddFBACost = () => {
        updateCostsFormStateValues({
            fba: {
                del: fbaDeletes,
                updates: [
                    ...fbaInputs,
                    {...costInitialState, idx: fbaInputs.length + 1, isFBA: true},
                ]
            },
        });
    }

    return (
        <div>
            <BreadcrumbComponent actionButtons={actionButtons} />
            <Card size="small">
                <VendorsTabs />
                <div className='vendor-detail-info-tab-pane-component'>
                    <div className='vendor-detail-section'>
                        <div className='contact-info'>
                            <div className='contact-info-label detail-label'>
                                <div className='detail-label-content'>
                                    <div className="label">FBA Costs</div>
                                    {
                                        isEditing && (
                                            <Button type='outline' onClick={handleAddFBACost}>+ Add Step</Button>
                                        )
                                    }
                                </div>
                                <div className="divider"></div>
                            </div>
                            {
                                fbaInputs ? fbaInputs.map((item) => (
                                    <div className='contact-info-form'>
                                        <CostFormItem shipmentType="fba" idx={item.idx} costOptions={costOptions}/>
                                    </div>
                                )) : null
                            }
                        </div>
                        <div className='contact-info'>
                            <div className='contact-info-label detail-label'>
                                <div className='detail-label-content'>
                                    <div className="label">FBM Costs</div>
                                    {
                                        isEditing && (
                                            <Button type='outline' onClick={handleAddFBMCost}>+ Add Step</Button>
                                        )
                                    }
                                </div>
                                <div className="divider"></div>
                            </div>
                            {
                                fbmInputs ? fbmInputs.map((item) => (
                                    <div className='contact-info-form'>
                                        <CostFormItem shipmentType={'fbm'} idx={item.idx}  costOptions={costOptions}/>
                                    </div>
                                )) : null
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = ({ costsState, costOptionsState }) => {
    const costOptions = costOptionsState.costOptions.map(val => ({ ...val, value: val.id, label: val.name, }))
    return {
        loading: costsState.loading,
        isEditing: costsState.isEditing,
        success: costsState.success,
        id: costsState.id,
        error: costsState.error,
        fbaInputs: costsState.vendorsCostInputs.fba.updates,
        fbmInputs: costsState.vendorsCostInputs.fbm.updates,
        fbaDeletes: costsState.vendorsCostInputs.fba.del,
        fbmDeletes: costsState.vendorsCostInputs.fbm.del,
        costOptions,
    }
}

export default connect(mapStateToProps, {
    updateCostsStateValues,
    updateCostsFormStateValues,
    resetCostsInputsValues,
    getCostOptionsAction,
    getCostsForVendorListAction,
    createNewCostsForVendorAction,
})(VendorCostTabPaneComponent);
