import {Button, Checkbox, Col, Form, Input, Row, Select} from "antd";
import React from "react";
import formConstants from "../../../constants/form.constants";
import './index.scss';

const { Option } = Select;

// Set Label = '   ' if you want to hide the label but its height, Set Label - null if you dont want height also
const FormComponent = ({formFields, viewOnly, rowGutter, colSpan}) => {
    console.log({formFields});
    return (
        <div className='form-component'>
            <Row gutter={rowGutter || [12, 0]} className='ai-cen'>
                {
                    formFields.filter(field => !field.hide).map(field => (
                        <Col span={field.span || colSpan} className={field.className}>
                            {
                                field.fieldType === formConstants.fieldTypes.Input && (
                                    <Form.Item label={field.label} labelCol={{ span: 24 }}>
                                        <Input name={field.name} type="text" placeholder={field.placeholder} size="large" onChange={(e) => field.onChange({name: field.name, value: e.target.value})} value={field.value} disabled={viewOnly || field.disabled} />
                                    </Form.Item>
                                )
                            }
                            {
                                field.fieldType === formConstants.fieldTypes.Select && (
                                    <Form.Item label={field.label} labelCol={{ span: 24 }}>
                                        <Select
                                            showSearch={field.showSearch}
                                            value={field.value}
                                            placeholder={field.placeholder}
                                            defaultActiveFirstOption={field.defaultActiveFirstOption}
                                            showArrow={field.showArrow}
                                            filterOption={field.filterOption}
                                            onChange={(value) => field.onChange({name: field.name, value})}
                                            notFoundContent={<>No results found</>}
                                            size="large"
                                            disabled={viewOnly || field.disabled}
                                            labelInValue={field.labelInValue}
                                        >
                                            {field.options.map(option => (
                                                <Option value={option.value}>{option.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }
                            {
                                field.fieldType === formConstants.fieldTypes.Checkbox && (
                                    <Checkbox
                                        onChange={(e) => field.onChange({name: field.name, value: e.target.checked})}
                                        checked={field.value}
                                        disabled={viewOnly || field.disabled}
                                    >{field.label}</Checkbox>
                                )
                            }
                            {
                                field.fieldType === formConstants.fieldTypes.Button && (
                                    <Button onClick={() => field.onClick(field)} type={field.type}>{field.label}</Button>
                                )
                            }
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default FormComponent;
