import './index.scss';

export const OrderSummaryCard = () => {
    const details = [{
        label: 'OrderId',
        value: '00992',
    },{
        label: 'Invoice',
        value: 'INV00992',
    },{
        label: 'Vendor',
        value: (
            <div className='d-flx flx-d-clm'>
                <span>Business Name</span>
                <span>Business phone</span>
                <span>Primary Contact</span>
            </div>
        ),
    },{
        label: 'ASINs',
        value: 3,
    },{
        label: 'Estimated ROI',
        value: '3.5%',
    },{
        label: 'Balance',
        value: '$5.40',
    },{
        label: 'Order Costs',
        value: '$4.50',
    },{
        label: 'OrderId',
        value: '00992',
    },{
        label: 'OrderId',
        value: '00992',
    }, {
        label: 'Total Amount',
        value: 3,
    },{
        label: 'FBA shipment Id',
        value: '3.5%',
    },{
        label: 'Vendor Shipment',
        value: '$5.40',
    },{
        label: 'Discrepancy',
        value: '$4.50',
    },{
        label: 'Claims',
        value: '00992',
    },{
        label: 'Buyer',
        value: 'Gustavo',
    },{
        label: 'Status',
        value: 'Draft',
    }, ]
    return (
        <div className='product-search-item-cont'>
            <div className='product-detail-row d-flx gap-12 margin-8-bottom font-12'>
                {
                    details.map(detail => (
                        <div className='product-detail-col d-flx flx-d-clm'>
                            <span className='product-detail-col-label text-color-grey'>{detail.label}</span>
                            <span className='product-detail-col-value font-weight-bold'>{detail.value}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
