import API from "../interceptor/axios.interceptor.service";

class OrdersBackendApiService {
    static async getOrderDetail(id) {
        const url = `/api/v1/orders/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching vendor detail, please try again!',
            }
        }
    }

    static async createNewOrder(payload) {
        const url = `/api/v1/orders/`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating a new vendor!',
            }
        }
    }

    static async getOrdersList(payload) {
        // const url = payload.pageNo && payload.pageLimit ?  `/api/v1/orders/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/orders/`;
        try {
            // const response = await API.get(url);
            // return response.data;
            return new Promise(res => {
                setTimeout(() => {
                    res({
                        success: true,
                        data: {
                            rows: new Array(12).fill({
                                id: '001',
                                invoiceNumber: 'invoice-001',
                                pendingOrders: Math.floor(Math.random() * 100),
                                openClaims: Math.floor(Math.random() * 20),
                                totalAmount: '$' + Math.floor(Math.random() * 1000),
                                discrepancy: Math.floor(Math.random() * 10),
                                estimatedROI: Math.floor(Math.random() * 90)+'%',
                                asins: Math.floor(Math.random() * 10),
                                items: Math.floor(Math.random() * 50),
                                updatedAt: '05/05/2022',
                                status: 'Draft',
                            })
                        }
                    })
                }, 2000)
            })
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching the vendor list!',
            }
        }
    }

    static async updateOrderDetails(payload) {
        const url = `/api/v1/orders/${payload.id}`;
        try {
            const response = await API.put(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while logging you out!',
            }
        }
    }
}

export default OrdersBackendApiService;
