import {Button} from "antd";

const FormHeader = ({label, showAddButton, buttonLabel, onAddClick}) => {
    return (
        <div className='form-header-component detail-label'>
            <div className='detail-label-content'>
                <div className="label">{label}</div>
                {
                    showAddButton && (
                        <Button type='outline' onClick={onAddClick}>{buttonLabel}</Button>
                    )
                }
            </div>
            <div className="divider"></div>
        </div>
    )
}

export default FormHeader;
