import API from "../interceptor/axios.interceptor.service";

class VendorsBackendApiService {
    static async getVendorDetail(id) {
        const url = `/api/v1/vendors/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching vendor detail, please try again!',
            }
        }
    }

    static async createNewVendor(payload) {
        const url = `/api/v1/vendors/`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating a new vendor!',
            }
        }
    }

    static async getVendorsList(payload) {
        const url = payload.pageNo && payload.pageLimit ?  `/api/v1/vendors/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/vendors/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching the vendor list!',
            }
        }
    }

    static async updateVendorDetails(payload) {
        const url = `/api/v1/vendors/${payload.id}`;
        try {
            const response = await API.put(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while logging you out!',
            }
        }
    }
}

export default VendorsBackendApiService;
