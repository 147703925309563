import {Tabs} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router-dom";

const { TabPane } = Tabs;

const tabs = [
    {
        key: 'cost-options',
        label: 'Costs Options',
    },{
        key: 'lead-time-options',
        label: 'Lead Time Options',
    },];

const SettingsTab = () => {
    const [activeKey, setActiveKey] = useState('lead-time-options');
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        const tabKey = location.pathname.split('/')[2];
        if (tabKey) {
            setActiveKey(tabKey);
        }
    }, [location]);

    const handleTabChange = (selectedKey) => {
        history.push(`/settings/${selectedKey}`)
        setActiveKey(selectedKey);
    }

    return (
        <Tabs defaultActiveKey={'lead-time-options'} activeKey={activeKey} onChange={handleTabChange}>
            {
                tabs.map(tab => (
                    <TabPane tab={tab.label} key={tab.key} disabled={tab.disabled}/>
                ))
            }
        </Tabs>
    )
}

export default SettingsTab;
