export const UPDATE_VALUES = "UPDATE_USER_VALUES";

export const RESET_FORM_VALUES = "RESET_USER_FORM_VALUES";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const POST_USER = "POST_USER";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAIL = "LOGIN_FAIL";

export const PUT_BASIC_USER = "PUT_BASIC_USER";
export const PUT_BASIC_USER_SUCCESS = "PUT_BASIC_USER_SUCCESS";
export const PUT_BASIC_USER_FAIL = "PUT_BASIC_USER_FAIL";

export const PUT_PERMISSIONS_USER = "PUT_PERMISSIONS_USER";
export const PUT_PERMISSIONS_USER_SUCCESS = "PUT_PERMISSIONS_USER_SUCCESS";
export const PUT_PERMISSIONS_USER_FAIL = "PUT_PERMISSIONS_USER_FAIL";

export const PUT_ROLES_USER = "PUT_ROLES_USER";
export const PUT_ROLES_USER_SUCCESS = "PUT_ROLES_USER_SUCCESS";
export const PUT_ROLES_USER_FAIL = "PUT_ROLES_USER_FAIL";

export const PUT_GROUPS_USER = "PUT_GROUPS_USER";
export const PUT_GROUPS_USER_SUCCESS = "PUT_GROUPS_USER_SUCCESS";
export const PUT_GROUPS_USER_FAIL = "PUT_GROUPS_USER_FAIL";