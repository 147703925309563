import {call, put, select, takeLatest} from "redux-saga/effects";

import {GET_COST_OPTIONS, POST_COST_OPTIONS} from '../action-types/cost-options.action-type';

import CostOptionsBackendApiService from "../../services/backend-api/cost-options.backend-api.service";
import {
    createNewCostOptionsFailAction,
    createNewCostOptionsSuccessAction,
    getCostOptionsFailAction,
    getCostOptionsSuccessAction
} from "../actions/cost-options.action";

function* getCostOptionsList({payload}) {
    try {
        const response = yield call(CostOptionsBackendApiService.getCostOptions, payload);
        if (response.success) {
            yield put(getCostOptionsSuccessAction({
                ...response.data,
                rows: response.data.rows.map((row) => {
                    return {
                        ...row,
                    }
                })
            }));
        } else {
            yield put(getCostOptionsFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getCostOptionsFailAction(error.response));
    }
}

function* createNewCostOptions() {
    try {
        const payload = yield select(({costOptionsState}) => costOptionsState.costOptionsInputs);
        const response = yield call(CostOptionsBackendApiService.updateCostOptions, payload);
        if (response.success) {
            yield put(createNewCostOptionsSuccessAction(response));
        } else {
            yield put(createNewCostOptionsFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewCostOptionsFailAction(error.response));
    }
}

function* LeadTimesSaga() {
    yield takeLatest(GET_COST_OPTIONS, getCostOptionsList);
    yield takeLatest(POST_COST_OPTIONS, createNewCostOptions);
}

export default LeadTimesSaga;
