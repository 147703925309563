import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, message, Row, Switch} from 'antd'
import {
    createNewUserAction,
    getUserDetailsAction,
    resetUserInputsValues,
    updateBasicUserDetailsAction,
    updateUserStateValues
} from "../../store/actions/users.action";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {PREBUILT_GROUPS} from "../../constants/groups.constants";

const UserForm = ({isAdmin, success, error, selectedUserInputs, id, getUserDetailsAction, updateUserStateValues, resetUserInputsValues, selectedUser, updateBasicUserDetailsAction, createNewUserAction}) => {

    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateUserStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/users`);
            }
        }
    }, [navigate, id, isNew, success, updateUserStateValues])

    useEffect(() => {
        resetUserInputsValues(idParam);
        if (idParam !== 'new') {
            getUserDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }
    }, [getUserDetailsAction, idParam, resetUserInputsValues]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateUserStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateUserStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target
        updateUserStateValues({
            selectedUserInputs: {
                ...selectedUserInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetUserInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        if (!isNew) {
            updateBasicUserDetailsAction(selectedUserInputs);
        } else {
            createNewUserAction(selectedUserInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedUser, selectedUserInputs, id, isNew})
        setViewMode(false);
    };
    return (
        <div>
            <div>
                <Row gutter={[12, 0]}>
                    <Col span={10}>
                        <Form.Item label="First name" labelCol={{ span: 24 }}>
                            <Input name="firstName" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedUserInputs.firstName} disabled={viewMode} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Last name" labelCol={{ span: 24 }}>
                            <Input name="lastName" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedUserInputs.lastName} disabled={viewMode} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="E-mail" labelCol={{ span: 24 }}>
                            <Input name="email" type="text" placeholder="Email" size="large" onChange={onChangeInput} value={selectedUserInputs.email} disabled={viewMode || !isNew} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Phone" labelCol={{ span: 24 }}>
                            <Input name="phone" type="phone" placeholder="Phone" size="large" onChange={onChangeInput} value={selectedUserInputs.phone} disabled={viewMode} />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item label="Email Verified?" labelCol={{ span: 24 }}>
                            <Switch
                                size="large"
                                onChange={(value) => onChangeInput({target: {name: 'verified', value}})}
                                checked={selectedUserInputs.verified}
                                disabled={viewMode}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                hideEdit={isAdmin}
                viewMode={viewMode}
                isNew={isNew}
            />
        </div>
    )
}

UserForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedUserInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

UserForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ usersState, permissionsState, groupsState, rolesState }) => {
    const selectedUserInputs = usersState.selectedUserInputs;
    const selectedUser = {...usersState.selectedUser};
    return {
        loading: usersState.loading,
        success: usersState.success,
        id: usersState.id,
        isAdmin: selectedUser.UserGroups ? selectedUser.UserGroups.some((group) => group.name === PREBUILT_GROUPS.ADMIN) : false,
        error: usersState.error,
        selectedUserInputs,
        searchedRoles: rolesState.searchedRoles,
        searchedGroups: groupsState.searchedGroups,
        searchedPermissions: permissionsState.searchedPermissions,
        selectedPermissions: usersState.selectedUserInputs.selectedPermissions,
        selectedUser: Object.keys(selectedUser).length ? selectedUser : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateUserStateValues,
    getUserDetailsAction,
    updateBasicUserDetailsAction,
    createNewUserAction,
    resetUserInputsValues,
})(UserForm)
