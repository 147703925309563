import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_PRODUCT,
    PUT_PRODUCT,
    GET_PRODUCTS,
    POST_PRODUCT,
} from '../action-types/products.action-type';

import ProductsBackendApiService from "../../services/backend-api/products.backend-api.service";
import {
    createNewProductFailAction,
    createNewProductSuccessAction,
    getProductDetailsFailAction,
    getProductDetailsSuccessAction,
    getProductsListFailAction,
    getProductsListSuccessAction,
    updateProductDetailsFailAction,
    updateProductDetailsSuccessAction,
} from "../actions/products.action";

function* productDetails({payload}) {
    try {
        const response = yield call(ProductsBackendApiService.getProductDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getProductDetailsSuccessAction(response.data));
        } else {
            yield put(getProductDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getProductDetailsFailAction(error.response));
    }
}


function* getProductsList({payload}) {
    try {
        const response = yield call(ProductsBackendApiService.getProductsList, payload);
        if (response.success) {
            yield put(getProductsListSuccessAction(response.data));
        } else {
            yield put(getProductsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getProductsListFailAction(error.response));
    }
}

function* updateProductDetails({payload}) {
    try {
        const response = yield call(ProductsBackendApiService.updateProductDetails, payload);
        if (response.success) {
            yield put(updateProductDetailsSuccessAction(response));
        } else {
            yield put(updateProductDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateProductDetailsFailAction(error.response));
    }
}


function* createNewProduct({payload}) {
    try {
        console.log({payload});
        const response = yield call(ProductsBackendApiService.createNewProduct, payload);
        if (response.success) {
            yield put(createNewProductSuccessAction(response.data));
        } else {
            yield put(createNewProductFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewProductFailAction(error.response));
    }
}


function* ProductsSaga() {
    yield takeLatest(GET_PRODUCT, productDetails);
    yield takeLatest(GET_PRODUCTS, getProductsList);
    yield takeLatest(POST_PRODUCT, createNewProduct);
    yield takeLatest(PUT_PRODUCT, updateProductDetails);
}

export default ProductsSaga;