import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_BRAND,
    PUT_BRAND,
    GET_BRANDS,
    POST_BRAND,
} from '../action-types/brands.action-type';

import BrandsBackendApiService from "../../services/backend-api/brands.backend-api.service";
import {
    createNewBrandFailAction,
    createNewBrandSuccessAction,
    getBrandDetailsFailAction,
    getBrandDetailsSuccessAction,
    getBrandsListFailAction,
    getBrandsListSuccessAction,
    updateBrandDetailsFailAction,
    updateBrandDetailsSuccessAction,
} from "../actions/brands.action";

function* brandDetails({payload}) {
    try {
        const response = yield call(BrandsBackendApiService.getBrandDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getBrandDetailsSuccessAction(response.data));
        } else {
            yield put(getBrandDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getBrandDetailsFailAction(error.response));
    }
}


function* getBrandsList({payload}) {
    try {
        const response = yield call(BrandsBackendApiService.getBrandsList, payload);
        if (response.success) {
            yield put(getBrandsListSuccessAction(response.data));
        } else {
            yield put(getBrandsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getBrandsListFailAction(error.response));
    }
}

function* updateBrandDetails({payload}) {
    try {
        const response = yield call(BrandsBackendApiService.updateBrandDetails, payload);
        if (response.success) {
            yield put(updateBrandDetailsSuccessAction(response));
        } else {
            yield put(updateBrandDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateBrandDetailsFailAction(error.response));
    }
}


function* createNewBrand({payload}) {
    try {
        console.log({payload});
        const response = yield call(BrandsBackendApiService.createNewBrand, payload);
        if (response.success) {
            yield put(createNewBrandSuccessAction(response.data));
        } else {
            yield put(createNewBrandFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewBrandFailAction(error.response));
    }
}


function* BrandsSaga() {
    yield takeLatest(GET_BRAND, brandDetails);
    yield takeLatest(GET_BRANDS, getBrandsList);
    yield takeLatest(POST_BRAND, createNewBrand);
    yield takeLatest(PUT_BRAND, updateBrandDetails);
}

export default BrandsSaga;