import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Button, Col, Empty, Form, Input, Row} from 'antd'
import UsersParserService from "../../services/parsers/users.parser.service";
import PermissionSelector from "../../components/shared/permission-selector/PermissionSelector";
import {useHistory} from "react-router-dom";

const PermissionUserDetails = ({permission, searchedPermissions}) => {
    const [parsedUsersPermissions, setParsedUsersPermissions] = useState({})
    const navigate = useHistory();
    useEffect(() => {
        if (permission.UsersPermissions) {
            const parsedData = UsersParserService.parseUsersPermissionList(permission.UsersPermissions);
            setParsedUsersPermissions(parsedData.parsedUsersPermissionMap);
        }
    }, [permission])

    return (
        <div className="inner-section">
            <div className="header-container">
                <div className="user-permission-header">
                    Users with this permission attached
                </div>
            </div>
            <div className="main-section">
                {
                    Object.keys(parsedUsersPermissions).map((permRow) => (
                        <Row gutter={[12, 0]}>
                            <Col span={8}>
                                <Form.Item label="Full Name" labelCol={{ span: 24 }}>
                                    <Input name="full name" type="text" placeholder="Full Name" size="large" value={`${parsedUsersPermissions[permRow].User.firstName} ${parsedUsersPermissions[permRow].User.lastName}`} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="E-mail" labelCol={{ span: 24 }}>
                                    <Input name="email" type="text" placeholder="Email" size="large" value={parsedUsersPermissions[permRow].User.email} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Details" labelCol={{ span: 24 }}>
                                    <Button type="primary" className="detail-btn" onClick={() => {  navigate.push(`/users/${parsedUsersPermissions[permRow].User.id}`) }}>Details</Button>
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <PermissionSelector
                                    viewMode={true}
                                    onChange={() => {}}
                                    searchedPermissions={searchedPermissions}
                                    selectedPermissions={parsedUsersPermissions[permRow].selectedPermissions}
                                />
                            </Col>
                        </Row>
                    ))
                }
                {
                    Object.keys(parsedUsersPermissions).length === 0 && (
                        <Empty style={{margin: 0}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({ permissionsState }) => {
    return {
        permission: permissionsState.selectedPermissionInputs,
        searchedPermissions: permissionsState.permissions,
    }
}

export default connect(mapStateToProps)(PermissionUserDetails)
