import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Col, Form, Input, Row} from 'antd'

const CategoryForm = ({selectedCategoryInputs, viewMode, onChangeInput}) => {

    return (
        <Row gutter={[12, 0]}>
            <Col span={10}>
                <Form.Item label="Name" labelCol={{ span: 24 }}>
                    <Input name="name" type="text" placeholder="Name" size="large" onChange={onChangeInput} value={selectedCategoryInputs.name} disabled={viewMode} />
                </Form.Item>
            </Col>
            <Col span={10}>
                <Form.Item label="Description" labelCol={{ span: 24 }}>
                    <Input name="description" type="text" placeholder="Last name" size="large" onChange={onChangeInput} value={selectedCategoryInputs.description} disabled={viewMode} />
                </Form.Item>
            </Col>
        </Row>
    )
}

CategoryForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedCategoryInputs: PropTypes.object,
    viewMode: PropTypes.bool,
    isNew: PropTypes.bool,
}

CategoryForm.defaultProps = {
    viewMode: false,
    isNew: false,
}

const mapStateToProps = ({ categoriesState }) => {
    return {
        selectedCategoryInputs: categoriesState.selectedCategoryInputs,
    }
}

export default connect(mapStateToProps)(CategoryForm)
