export const UPDATE_COST_OPTION_VALUES = "COST_OPTION.UPDATE_COST_OPTION_VALUES";

export const UPDATE_COST_OPTION_FORM_VALUES = "COST_OPTION.UPDATE_FORM_VALUES";

export const RESET_COST_OPTION_FORM_VALUES = "COST_OPTION.RESET_FORM_OPTION_VALUES";

export const GET_COST_OPTIONS = "COST_OPTION.GET_COST_OPTIONS";
export const GET_COST_OPTIONS_SUCCESS = "COST_OPTION.GET_COST_OPTIONS_SUCCESS";
export const GET_COST_OPTIONS_FAIL = "COST_OPTION.GET_COST_OPTIONS_FAIL";

export const POST_COST_OPTIONS = "COST_OPTION.POST_COST_OPTION";
export const POST_COST_OPTIONS_SUCCESS = "COST_OPTION.POST_COST_OPTION_SUCCESS";
export const POST_COST_OPTIONS_FAIL = "COST_OPTION.POST_COST_OPTION_FAIL";
