export const UPDATE_VALUES = "UPDATE_VALUES";

export const GET_PERMISSION = "GET_PERMISSION";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL";

export const SEARCH_PERMISSIONS = "SEARCH_PERMISSIONS";
export const SEARCH_PERMISSIONS_SUCCESS = "SEARCH_PERMISSIONS_SUCCESS";
export const SEARCH_PERMISSIONS_FAIL = "SEARCH_PERMISSIONS_FAIL";

export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";

export const POST_PERMISSION = "POST_PERMISSION";
export const POST_PERMISSION_SUCCESS = "POST_PERMISSION_SUCCESS";
export const POST_PERMISSION_FAIL = "LOGIN_FAIL";

export const PUT_PERMISSION = "PUT_PERMISSION";
export const PUT_PERMISSION_SUCCESS = "PUT_PERMISSION_SUCCESS";
export const PUT_PERMISSION_FAIL = "PUT_PERMISSION_FAIL";