export default class GroupsParserService {
    static parseGroup (role) {
        let selectedPermissions = {};
        role.UserGroupsPermissions.filter(perm => perm.Permission).forEach((perm => {
            selectedPermissions = {
                ...selectedPermissions,
                [perm.Permission.name]: selectedPermissions[perm.Permission.name]
                    ? [...selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    : [perm.AccessType.name]
            }
        }));
        return {
            ...role,
            selectedPermissions,
        }
    }
    static parseGroupsPermissionList (GroupsPermissions) {
        let parsedGroupsPermissionMap = {};
        GroupsPermissions.filter(perm => perm.Permission).forEach((perm => {
            parsedGroupsPermissionMap = {
                ...parsedGroupsPermissionMap,
                [perm.UserGroup.id]: parsedGroupsPermissionMap[perm.UserGroup.id] ? {
                    ...parsedGroupsPermissionMap[perm.UserGroup.id],
                    selectedPermissions: {
                        [perm.Permission.name]: [...parsedGroupsPermissionMap[perm.UserGroup.id].selectedPermissions[perm.Permission.name], perm.AccessType.name]
                    }
                } :  {
                    Group: perm.UserGroup,
                    selectedPermissions: {
                        [perm.Permission.name]: [perm.AccessType.name]
                    }
                }
            }
        }));
        console.log({parsedGroupsPermissionMap})
        return {
            parsedGroupsPermissionMap,
        }
    }
    static parsePermissionsFromUserGroupsPermissions (GroupsPermissions) {
        let parsedGroupsPermissionMap = {};
        GroupsPermissions.filter(perm => perm.Permission).forEach((perm => {
            parsedGroupsPermissionMap = {
                ...parsedGroupsPermissionMap,
                [perm.Permission.name]: parsedGroupsPermissionMap[perm.Permission.name] ? [...parsedGroupsPermissionMap[perm.Permission.name], perm.AccessType.name] :  [perm.AccessType.name],
            }
        }));
        console.log({parsedGroupsPermissionMap});
        return {
            parsedGroupsPermissionMap,
        }
    }

    static parseEffectivePermissionList (GroupsPermissions) {
        let parsedGroupsPermissionMap = {};
        GroupsPermissions.sort((group1, group2) => group1.priority > group2.priority ? 1 : -1).forEach((group => {
            let groupSpecificPerms = {};
            group.UserGroupsPermissions.map(groupPerm => {
                if (!parsedGroupsPermissionMap[groupPerm.Permission.name]) {
                    groupSpecificPerms = {
                        ...groupSpecificPerms,
                        [groupPerm.Permission.name]: groupSpecificPerms[groupPerm.Permission.name]
                            ? [...groupSpecificPerms[groupPerm.Permission.name], groupPerm.AccessType.name] : [groupPerm.AccessType.name]
                    }
                }
                return groupPerm; // NOt needed, doing bcoz of linter
            });
            parsedGroupsPermissionMap = {
                ...parsedGroupsPermissionMap,
                ...groupSpecificPerms,
            }
            groupSpecificPerms = {};
        }));
        return {
            parsedGroupsPermissionMap,
        }
    }

}