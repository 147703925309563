import React from "react";
import {Route, Switch} from "react-router-dom";
import VendorDetailInfoTabPaneComponent from "../../../vendors/components/vendor-detail-info-tab-pane-component/index";
import VendorLeadTimeTabPaneComponent from "../../../vendors/components/vendor-lead-time-tab-pane-component/index";
import VendorCostTabPaneComponent from "../../../vendors/components/vendor-cost-tab-pane-component/index";

const VendorDetailsTabsComponent = () => {
    // Get the current Selected Tab (fallback to default) and then make it selected
    return (
        <div className='vendor-details-tab-component'>
            <Switch>
                <Route exact path="/vendors/:id/" component={VendorDetailInfoTabPaneComponent}/>
                <Route exact path="/vendors/:id/info" component={VendorDetailInfoTabPaneComponent}/>
                <Route exact path="/vendors/:id/lead-times" component={VendorLeadTimeTabPaneComponent}/>
                <Route exact path="/vendors/:id/costs" component={VendorCostTabPaneComponent}/>
            </Switch>
        </div>
    )
}

export default VendorDetailsTabsComponent;
