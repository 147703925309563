import {
    GET_USER,
    GET_USER_FAIL,
    GET_USER_SUCCESS,
    GET_USERS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    POST_USER,
    POST_USER_FAIL,
    POST_USER_SUCCESS,
    PUT_BASIC_USER,
    PUT_BASIC_USER_FAIL,
    PUT_BASIC_USER_SUCCESS,
    PUT_GROUPS_USER,
    PUT_GROUPS_USER_FAIL,
    PUT_GROUPS_USER_SUCCESS,
    PUT_PERMISSIONS_USER, PUT_PERMISSIONS_USER_FAIL,
    PUT_PERMISSIONS_USER_SUCCESS,
    PUT_ROLES_USER,
    PUT_ROLES_USER_FAIL,
    PUT_ROLES_USER_SUCCESS,
    RESET_FORM_VALUES,
    UPDATE_VALUES
} from "../action-types/users.action-type";

export const updateUserStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const resetUserInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
})

export const getUserDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_USER,
        payload,
    };
};

export const getUserDetailsSuccessAction = (payload) => {
    return {
        type: GET_USER_SUCCESS,
        payload,
    };
};

export const getUserDetailsFailAction = (payload) => {
    return {
        type: GET_USER_FAIL,
        payload,
    };
};


export const updateBasicUserDetailsAction = (payload) => {
    return {
        type: PUT_BASIC_USER,
        payload,
    };
};

export const updateBasicUserDetailsSuccessAction = (payload) => {
    return {
        type: PUT_BASIC_USER_SUCCESS,
        payload,
    };
};

export const updateBasicUserDetailsFailAction = (payload) => {
    return {
        type: PUT_BASIC_USER_FAIL,
        payload,
    };
};


export const updateRoleUserDetailsAction = (payload) => {
    console.log({payload});
    return {
        type: PUT_ROLES_USER,
        payload,
    };
};

export const updateRoleUserDetailsSuccessAction = (payload) => {
    return {
        type: PUT_ROLES_USER_SUCCESS,
        payload,
    };
};

export const updateRoleUserDetailsFailAction = (payload) => {
    return {
        type: PUT_ROLES_USER_FAIL,
        payload,
    };
};



export const updateGroupUserDetailsAction = (payload) => {
    return {
        type: PUT_GROUPS_USER,
        payload,
    };
};

export const updateGroupUserDetailsSuccessAction = (payload) => {
    return {
        type: PUT_GROUPS_USER_SUCCESS,
        payload,
    };
};

export const updateGroupUserDetailsFailAction = (payload) => {
    return {
        type: PUT_GROUPS_USER_FAIL,
        payload,
    };
};



export const updatePermissionUserDetailsAction = (payload) => {
    return {
        type: PUT_PERMISSIONS_USER,
        payload,
    };
};

export const updatePermissionUserDetailsSuccessAction = (payload) => {
    return {
        type: PUT_PERMISSIONS_USER_SUCCESS,
        payload,
    };
};

export const updatePermissionUserDetailsFailAction = (payload) => {
    return {
        type: PUT_PERMISSIONS_USER_FAIL,
        payload,
    };
};


export const createNewUserAction = (payload) => {
    console.log({payload});
    return {
        type: POST_USER,
        payload,
    };
};

export const createNewUserSuccessAction = (payload) => {
    return {
        type: POST_USER_SUCCESS,
        payload
    };
};

export const createNewUserFailAction = (payload) => {
    return {
        type: POST_USER_FAIL,
        payload,
    };
};

export const getUsersListAction = (payload) => ({
    type: GET_USERS,
    payload
});

export const getUsersListSuccessAction = (payload) => {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
};

export const getUsersListFailAction = (payload) => {
    return {
        type: GET_USERS_FAIL,
        payload,
    };
};
