import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_PERMISSION,
    GET_PERMISSIONS,
    SEARCH_PERMISSIONS
} from '../action-types/permissions.action-type';

import PermissionsBackendApiService from "../../services/backend-api/permissions.backend-api.service";
import {
    getPermissionsListFailAction,
    getPermissionsListSuccessAction,
    getPermissionDetailsFailAction,
    getPermissionDetailsSuccessAction,
    searchPermissionsListFailAction,
    searchPermissionsListSuccessAction,
} from "../actions/permissions.action";

function* permissionDetails({payload}) {
    try {
        const response = yield call(PermissionsBackendApiService.getPermissionDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getPermissionDetailsSuccessAction(response.data));
        } else {
            yield put(getPermissionDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getPermissionDetailsFailAction(error.response));
    }
}

function* searchPermissionsList() {
    try {
        const response = yield call(PermissionsBackendApiService.getPermissionsList);
        if (response.success) {
            yield put(searchPermissionsListSuccessAction(response.data));
        } else {
            yield put(searchPermissionsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(searchPermissionsListFailAction(error.response));
    }
}


function* getPermissionsList({payload}) {
    try {
        const response = yield call(PermissionsBackendApiService.getPermissionsList, payload);
        if (response.success) {
            yield put(getPermissionsListSuccessAction(response.data));
        } else {
            yield put(getPermissionsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getPermissionsListFailAction(error.response));
    }
}

function* PermissionsSaga() {
    yield takeLatest(GET_PERMISSION, permissionDetails);
    yield takeLatest(GET_PERMISSIONS, getPermissionsList);
    yield takeLatest(SEARCH_PERMISSIONS, searchPermissionsList);
}

export default PermissionsSaga;