import API from "../interceptor/axios.interceptor.service";

class CategoryBackendApiService {
    static async getCategoryDetail(id) {
        const url = `/api/v1/categories/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching category detail, please try again!',
            }
        }
    }

    static async createNewCategory(payload) {
        const url = `/api/v1/categories`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating the new category!',
            }
        }
    }

    static async getCategoriesList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/categories/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/categories/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching category list!',
            }
        }
    }

    static async updateCategoryDetails(payload) {
        const url = `/api/v1/categories/${payload.id}`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                name: payload.name,
                description: payload.description,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the category detail!',
            }
        }
    }
}

export default CategoryBackendApiService;