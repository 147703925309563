import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import {message} from 'antd'
import RoleForm from "../../components/roles/RoleForm";
import LayoutComponent from "../../components/shared/layout/Layout";
import {
    createNewRoleAction,
    getRoleDetailsAction,
    updateRoleDetailsAction,
    updateRoleStateValues,
    resetRoleInputsValues,
} from "../../store/actions/roles.action";
import {useHistory} from "react-router-dom";
import {searchPermissionsListAction} from "../../store/actions/permissions.action";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";

const CreateRolePage = ({success, error, updateRoleStateValues,resetRoleInputsValues, id, loading, getRoleDetailsAction, selectedRoleInputs, selectedRole, searchPermissionsListAction, updateRoleDetailsAction, createNewRoleAction}) => {
    const [viewMode, setViewMode] = useState(true)
    const [isNew, setIsNew] = useState(false)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateRoleStateValues({
                success: ''
            });
            if (isNew) {
                navigate.push(`/roles`);
            }
        }
    }, [navigate, id, isNew, success, updateRoleStateValues])

    useEffect(() => {
        resetRoleInputsValues(idParam);
        if (idParam !== 'new') {
            getRoleDetailsAction(idParam)
            setIsNew(false)
            setViewMode(true)
        } else {
            setViewMode(false)
            setIsNew(true)
        }
    }, [getRoleDetailsAction, idParam, resetRoleInputsValues]);

    useEffect(() => {
        console.log('searchPermissionsListAction');
        searchPermissionsListAction();
    }, [searchPermissionsListAction]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateRoleStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateRoleStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        console.log({selectedRole, selectedRoleInputs})

        updateRoleStateValues({
            selectedRoleInputs: {
                ...selectedRoleInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetRoleInputsValues(isNew);
        setViewMode(true);
    };

    const onSave = () => {
        if (!isNew) {
            updateRoleDetailsAction(selectedRoleInputs);
        } else {
            createNewRoleAction(selectedRoleInputs);
        }
    }

    const onEdit = () => {
        console.log({selectedRole, selectedRoleInputs, id, isNew})
        setViewMode(false);
    };
    return (
        <LayoutComponent loading={loading}>
            <RoleForm
                viewMode={viewMode}
                isNew={isNew}
                id={idParam}
                onChangeInput={onChangeInput}
            />
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                viewMode={viewMode}
                isNew={isNew}
            />
        </LayoutComponent>
    )
}

CreateRolePage.propTypes = {
    loading: PropTypes.bool,
    success: PropTypes.string,
    id: PropTypes.number,
    error: PropTypes.string,
}

const mapStateToProps = ({ rolesState }) => {
    const selectedRoleInputs = rolesState.selectedRoleInputs;
    const selectedRole = {...rolesState.selectedRole};
    return {
        loading: rolesState.loading,
        success: rolesState.success,
        id: rolesState.id,
        error: rolesState.error,
        selectedRoleInputs,
        selectedRole: Object.keys(selectedRole).length ? selectedRole : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateRoleStateValues,
    getRoleDetailsAction,
    searchPermissionsListAction,
    updateRoleDetailsAction,
    createNewRoleAction,
    resetRoleInputsValues,
})(CreateRolePage)
