export const UPDATE_VALUES = "VENDOR.UPDATE_VENDOR_VALUES";
export const UPDATE_FORM_VALUES = "VENDOR.UPDATE_VENDOR_FORM_VALUES";

export const RESET_FORM_VALUES = "VENDOR.RESET_VENDOR_FORM_VALUES";

export const GET_VENDOR = "VENDOR.GET_VENDOR";
export const GET_VENDOR_SUCCESS = "VENDOR.GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAIL = "VENDOR.GET_VENDOR_FAIL";

export const GET_VENDORS = "VENDOR.GET_VENDORS";
export const GET_VENDORS_SUCCESS = "VENDOR.GET_VENDORS_SUCCESS";
export const GET_VENDORS_FAIL = "VENDOR.GET_VENDORS_FAIL";

export const POST_VENDOR = "VENDOR.POST_VENDOR";
export const POST_VENDOR_SUCCESS = "VENDOR.POST_VENDOR_SUCCESS";
export const POST_VENDOR_FAIL = "VENDOR.POST_VENDOR_FAIL";

export const PUT_VENDOR = "VENDOR.PUT_VENDOR";
export const PUT_VENDOR_SUCCESS = "VENDOR.PUT_VENDOR_SUCCESS";
export const PUT_VENDOR_FAIL = "VENDOR.PUT_VENDOR_FAIL";
