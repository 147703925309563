import {
    GET_USER,
    GET_USER_FAIL,
    GET_USER_SUCCESS,
    GET_USERS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    PUT_BASIC_USER,
    PUT_BASIC_USER_FAIL,
    PUT_BASIC_USER_SUCCESS,
    PUT_ROLES_USER,
    PUT_ROLES_USER_FAIL,
    PUT_ROLES_USER_SUCCESS,
    PUT_GROUPS_USER,
    PUT_GROUPS_USER_FAIL,
    PUT_GROUPS_USER_SUCCESS,
    PUT_PERMISSIONS_USER,
    PUT_PERMISSIONS_USER_FAIL,
    PUT_PERMISSIONS_USER_SUCCESS,
    POST_USER, POST_USER_FAIL,
    POST_USER_SUCCESS, RESET_FORM_VALUES,
    UPDATE_VALUES,
} from "../action-types/users.action-type";
import UsersParserService from "../../services/parsers/users.parser.service";

const initialState = {
    users: [],
    selectedUser: {
        roles: [],
        permissions: [],
        groups: [],
        selectedPermissions: {},
    },
    selectedUserInputs: {
        roles: [],
        permissions: [],
        groups: [],
        selectedPermissions: {},
    },
    loading: false,
    error: '',
    success: '',
};

const UsersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedUserInputs: payload.isNew ? initialState.selectedUserInputs : state.selectedUser };
            break;
        case GET_USER:
            state = { ...state, loading: true };
            break;
        case GET_USER_SUCCESS:
            let parsedUser = UsersParserService.parseUser(payload.user);
            state = { ...state, selectedUser: parsedUser, selectedUserInputs: parsedUser, loading: false, success: payload.message };
            break;
        case GET_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_USERS:
            state = { ...state, loading: true };
            break;
        case GET_USERS_SUCCESS:
            state = { ...state, users: payload.rows, loading: false };
            break;
        case GET_USERS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_USER:
            state = { ...state, loading: true };
            break;
        case POST_USER_SUCCESS:
            state = { ...state, selectedUser: initialState.user, loading: false, success: 'New user created successfully!' };
            break;
        case POST_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_BASIC_USER:
            state = { ...state, loading: true };
            break;
        case PUT_BASIC_USER_SUCCESS:
            state = { ...state, selectedUser: initialState.user, loading: false, success: 'User updated successfully' };
            break;
        case PUT_BASIC_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_ROLES_USER:
            state = { ...state, loading: true };
            break;
        case PUT_ROLES_USER_SUCCESS:
            state = { ...state, selectedUser: initialState.user, loading: false, success: 'User updated successfully' };
            break;
        case PUT_ROLES_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_GROUPS_USER:
            state = { ...state, loading: true };
            break;
        case PUT_GROUPS_USER_SUCCESS:
            state = { ...state, selectedUser: initialState.user, loading: false, success: 'User updated successfully' };
            break;
        case PUT_GROUPS_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_PERMISSIONS_USER:
            state = { ...state, loading: true };
            break;
        case PUT_PERMISSIONS_USER_SUCCESS:
            state = { ...state, selectedUser: initialState.user, loading: false, success: 'User updated successfully' };
            break;
        case PUT_PERMISSIONS_USER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default UsersReducer;
