import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'
import LayoutComponent from "../../components/shared/layout/Layout";
import {getPermissionDetailsAction, getPermissionsListAction} from "../../store/actions/permissions.action";
import PermissionBasicDetails from "../../components/permissions/PermissionBasicDetails";
import PermissionRoleDetails from "../../components/permissions/PermissionRoleDetails";
import PermissionUserDetails from "../../components/permissions/PermissionUserDetails";
import PermissionGroupDetails from "../../components/permissions/PermissionGroupDetails";

const PermissionsDetailPage = ({ loading, getPermissionDetailsAction, getPermissionsListAction }) => {
    const idParam = useParams().id

    useEffect(() => {
        getPermissionDetailsAction(idParam);
        getPermissionsListAction();
    }, [idParam, getPermissionDetailsAction, getPermissionsListAction])

    return (
        <LayoutComponent loading={loading}>
            <PermissionBasicDetails />
            <PermissionUserDetails />
            <PermissionGroupDetails />
            <PermissionRoleDetails />
        </LayoutComponent>
    )
}

PermissionsDetailPage.propTypes = {
    loading: PropTypes.bool,
}

const mapStateToProps = ({ permissionsState }) => {
    return {
        loading: permissionsState.loading,
    }
}

export default
connect(mapStateToProps, {
    getPermissionDetailsAction,
    getPermissionsListAction,
})(PermissionsDetailPage)
