import API from "../interceptor/axios.interceptor.service";

class LeadTimesBackendApiService {
    static async getLeadTimesForVendorList(payload) {
        const url = payload.pageNo && payload.pageLimit ?  `/api/v1/lead-times/?vendorId=${payload.vendorId}&pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/lead-times/?vendorId=${payload.vendorId}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching the lead times for given vendor!',
            }
        }
    }

    static async updateLeadTimes({vendorId, ...payload}) {
        const url = `/api/v1/lead-times/${vendorId}`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the list of lead times!',
            }
        }
    }
}

export default LeadTimesBackendApiService;
