import {
    GET_GROUP,
    GET_GROUP_FAIL,
    GET_GROUP_SUCCESS,
    GET_GROUPS,
    GET_GROUPS_FAIL,
    GET_GROUPS_SUCCESS,
    POST_GROUP,
    POST_GROUP_FAIL,
    POST_GROUP_SUCCESS,
    PUT_GROUP,
    PUT_GROUP_FAIL,
    PUT_GROUP_SUCCESS,
    SEARCH_GROUPS, SEARCH_GROUPS_FAIL, SEARCH_GROUPS_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
    UPDATE_GROUPS_PRIORITY,
    UPDATE_GROUPS_PRIORITY_FAIL,
    UPDATE_GROUPS_PRIORITY_SUCCESS,
} from "../action-types/groups.action-type";

export const resetGroupInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
});

export const updateGroupStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const getGroupDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_GROUP,
        payload,
    };
};

export const getGroupDetailsSuccessAction = (payload) => {
    return {
        type: GET_GROUP_SUCCESS,
        payload,
    };
};

export const getGroupDetailsFailAction = (payload) => {
    return {
        type: GET_GROUP_FAIL,
        payload,
    };
};


export const updateGroupDetailsAction = (payload) => {
    return {
        type: PUT_GROUP,
        payload,
    };
};

export const updateGroupDetailsSuccessAction = (payload) => {
    return {
        type: PUT_GROUP_SUCCESS,
        payload,
    };
};

export const updateGroupDetailsFailAction = (payload) => {
    return {
        type: PUT_GROUP_FAIL,
        payload,
    };
};


export const createNewGroupAction = (payload) => {
    return {
        type: POST_GROUP,
        payload,
    };
};

export const createNewGroupSuccessAction = (payload) => {
    return {
        type: POST_GROUP_SUCCESS,
        payload
    };
};

export const createNewGroupFailAction = (payload) => {
    return {
        type: POST_GROUP_FAIL,
        payload,
    };
};

export const updateGroupsPriorityAction = (
    data,
) => ({
    type: UPDATE_GROUPS_PRIORITY,
    payload: data,
})

export const updateGroupsPrioritySuccessAction = (payload) => ({
    type: UPDATE_GROUPS_PRIORITY_SUCCESS,
    payload,
})

export const updateGroupsPriorityFailureAction = (
    error,
) => ({
    type: UPDATE_GROUPS_PRIORITY_FAIL,
    payload: error,
})

export const getGroupsListAction = (payload) => ({
    type: GET_GROUPS,
    payload
});

export const getGroupsListSuccessAction = (payload) => {
    return {
        type: GET_GROUPS_SUCCESS,
        payload
    };
};

export const getGroupsListFailAction = (payload) => {
    return {
        type: GET_GROUPS_FAIL,
        payload,
    };
};


export const searchGroupsListAction = (payload) => ({
    type: SEARCH_GROUPS,
    payload
});

export const searchGroupsListSuccessAction = (payload) => {
    return {
        type: SEARCH_GROUPS_SUCCESS,
        payload
    };
};

export const searchGroupsListFailAction = (payload) => {
    return {
        type: SEARCH_GROUPS_FAIL,
        payload,
    };
};
