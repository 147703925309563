import {
    GET_ROLE,
    GET_ROLE_FAIL,
    GET_ROLE_SUCCESS,
    GET_ROLES,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    POST_ROLE,
    POST_ROLE_FAIL,
    POST_ROLE_SUCCESS,
    PUT_ROLE,
    PUT_ROLE_FAIL,
    PUT_ROLE_SUCCESS,
    SEARCH_ROLES,
    SEARCH_ROLES_FAIL,
    SEARCH_ROLES_SUCCESS,
    UPDATE_VALUES,
    UPDATE_ROLES_PRIORITY,
    UPDATE_ROLES_PRIORITY_FAIL,
    UPDATE_ROLES_PRIORITY_SUCCESS,
} from "../action-types/roles.action-type";
import {RESET_FORM_VALUES} from "../action-types/users.action-type";
import RolesParserService from "../../services/parsers/roles.parser.service";

const initialState = {
    roles: [],
    selectedRole: {},
    searchedRoles: [],
    selectedRoleInputs: {
        name: '',
        description: '',
        selectedPermissions: {},
    },
    loading: false,
    error: '',
    success: '',
};

const RolesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_ROLE:
            state = { ...state, loading: true };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedRoleInputs: payload.isNew ? initialState.selectedRoleInputs : state.selectedRole };
            break;
        case GET_ROLE_SUCCESS:
            let parsedRole = RolesParserService.parseRole(payload.role);
            state = { ...state, selectedRole: parsedRole, selectedRoleInputs: parsedRole, loading: false, success: payload.message };
            break;
        case GET_ROLE_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case SEARCH_ROLES:
            state = { ...state, loading: true };
            break;
        case SEARCH_ROLES_SUCCESS:
            state = { ...state, searchedRoles: payload.rows, selectedRoleInputs: payload.role, loading: false, success: payload.message };
            break;
        case SEARCH_ROLES_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_ROLES:
            state = { ...state, loading: true };
            break;
        case GET_ROLES_SUCCESS:
            state = { ...state, roles: payload.rows, loading: false };
            break;
        case GET_ROLES_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_ROLE:
            state = { ...state, loading: true };
            break;
        case POST_ROLE_SUCCESS:
            state = { ...state, selectedRole: payload.role, loading: false, success: 'Role added successfully' };
            break;
        case POST_ROLE_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_ROLE:
            state = { ...state, loading: true };
            break;
        case PUT_ROLE_SUCCESS:
            state = { ...state, selectedRole: payload.role, loading: false, success: 'Role updated successfully' };
            break;
        case PUT_ROLE_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case UPDATE_ROLES_PRIORITY:
            state = { ...state, loading: true };
            break;
        case UPDATE_ROLES_PRIORITY_SUCCESS:
            state = { ...state, roles: payload.rows, loading: false };
            break;
        case UPDATE_ROLES_PRIORITY_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default RolesReducer;
