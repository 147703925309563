export const menuItems = [
    {
        hasSubItems: false,
        title: 'Dashboard',
        breadcrumbName: 'Dashboard',
        path: '/dashboard',
        key: 'dashboard',
    },
    {
        hasSubItems: false,
        title: 'Vendors',
        breadcrumbName: 'Vendor',
        path: '/vendors',
        key: 'vendors',
    },
    {
        hasSubItems: false,
        title: 'Users',
        breadcrumbName: 'Users',
        path: '/users',
        key: 'users',
    },
    {
        hasSubItems: false,
        title: 'Orders',
        breadcrumbName: 'Orders',
        path: '/orders',
        key: 'orders',
    },
    {
        hasSubItems: false,
        title: 'Roles',
        breadcrumbName: 'Roles',
        path: '/roles',
        key: 'roles',
    },
    {
        hasSubItems: false,
        title: 'Groups',
        breadcrumbName: 'Groups',
        path: '/groups',
        key: 'groups',
    },
    {
        hasSubItems: false,
        title: 'Permissions',
        breadcrumbName: 'Permissions',
        path: '/permissions',
        key: 'permissions',
    },
    {
        hasSubItems: false,
        title: 'Brands',
        breadcrumbName: 'Brands',
        path: '/brands',
        key: 'brands',
    },
    {
        hasSubItems: false,
        title: 'Category',
        breadcrumbName: 'Category',
        path: '/category',
        key: 'category',
    },
    {
        hasSubItems: false,
        title: 'Settings',
        breadcrumbName: 'Settings',
        path: '/settings',
        key: 'settings',
    },
    {
        hasSubItems: false,
        title: 'Products',
        breadcrumbName: 'Products',
        path: '/products',
        key: 'products',
    }
]
