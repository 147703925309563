import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {
    getCategoriesListAction,
    resetCategoryInputsValues,
    updateCategoryStateValues
} from "../../store/actions/category.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const CategoryPage = ({ getCategoriesListAction, categories, loading, error, success, updateCategoryStateValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('priority')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showOrderModal, setShowPriorityModal] = useState(false)

    const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false)
    const [userToDelete, setCategoryToDelete] = useState({});

    const userCategoryTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/category/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewCategory(record.id)}>View Category</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Category</Button>*/}
                </>
            ),
        }
    ]

    const handleViewCategory = (id) => {
        console.log(showOrderModal)
        navigate.push(`/category/${id}`);
    }

    const handleDeleteCategory = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection, setShowPriorityModal);
        setShowCategoryDeleteModal(false)
        // deleteCategoryAPI(userToDelete)
    }

    const cancelCategoryDelete = () => {
        setCategoryToDelete({})
        setShowCategoryDeleteModal(false)
    }

    const createUserCategory = () => {
        resetCategoryInputsValues(true);
        navigate.push('/category/new');
    }

    useEffect(() => {
        // Call the API to fetch the category list
        getCategoriesListAction({
            pageNo,
            pageLimit,
            orderKey,
            orderDirection,
        });
    }, [getCategoriesListAction, pageNo, pageLimit, orderKey, orderDirection]);

    useEffect(() => {
        // Call the API to fetch the category list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateCategoryStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateCategoryStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateCategoryStateValues]);

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createUserCategory}>Create New Category</Button>
            </div>
                <Table
                    dataSource={categories}
                    columns={userCategoryTableColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                />
            <Modal
                title={<b>Are you sure?</b>}
                visible={showCategoryDeleteModal}
                onOk={handleDeleteCategory}
                onCancel={cancelCategoryDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Category {userToDelete.name}?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ categoriesState }) => {
    const {
        categories, loading, error, success,
    } = categoriesState;

    return {
        categories,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getCategoriesListAction,
    updateCategoryStateValues,
})(CategoryPage);
