import {
    GET_BRAND,
    GET_BRAND_FAIL,
    GET_BRAND_SUCCESS,
    GET_BRANDS,
    GET_BRANDS_FAIL,
    GET_BRANDS_SUCCESS,
    POST_BRAND,
    POST_BRAND_FAIL,
    POST_BRAND_SUCCESS,
    PUT_BRAND,
    PUT_BRAND_FAIL,
    PUT_BRAND_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/brands.action-type";

const initialState = {
    brands: [],
    selectedBrand: {},
    searchedBrands: [],
    selectedBrandInputs: {
        name: '',
        description: '',
    },
    loading: false,
    error: '',
    success: '',
};

const BrandsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_BRAND:
            state = { ...state, loading: true };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedBrandInputs: payload.isNew ? initialState.selectedBrandInputs : state.selectedBrand };
            break;
        case GET_BRAND_SUCCESS:
            state = { ...state, selectedBrand: payload.brand, selectedBrandInputs: payload.brand, loading: false, success: payload.message };
            break;
        case GET_BRAND_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_BRANDS:
            state = { ...state, loading: true };
            break;
        case GET_BRANDS_SUCCESS:
            state = { ...state, brands: payload.rows, loading: false };
            break;
        case GET_BRANDS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_BRAND:
            state = { ...state, loading: true };
            break;
        case POST_BRAND_SUCCESS:
            state = { ...state, selectedBrand: payload.brand, loading: false, success: 'New Brand created successfully!' };
            break;
        case POST_BRAND_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_BRAND:
            state = { ...state, loading: true };
            break;
        case PUT_BRAND_SUCCESS:
            state = { ...state, selectedBrand: payload.brand, loading: false, success: 'brand updated successfully' };
            break;
        case PUT_BRAND_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default BrandsReducer;
