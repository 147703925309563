import './index.scss';
import LayoutComponent from "../../shared/layout/Layout";
import {Card, message, Table} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    getOrdersListAction,
    resetOrderInputsValues,
    updateOrderStateValues
} from "../../../store/actions/orders.action";
import BreadcrumbComponent from "../../../components/shared/breadcrumb/Breadcrumb";

const OrdersListingPage = ({
   orders,
   loading,
   error,
   success,
   getOrdersListAction,
   updateOrderStateValues,
}) => {
    const navigate = useHistory();


    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')


    // const handleViewOrder = (id) => {
    //     console.log(id);
    //     navigate.push(`/orders/${id}`);
    // }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/orders/${id}`} key={id}>
                    {id}
                </Link>
            ),
        }, {
            title: 'Invoice No.',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            render: (value) => (value || '-')
        }, {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            render: value => (value || '-')
        }, {
            title: 'ASINs',
            dataIndex: 'asins',
            key: 'asins',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('asin')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Items',
            dataIndex: 'items',
            key: 'items',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('items')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Discrepancy',
            dataIndex: 'discrepancy',
            key: 'discrepancy',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('discrepancy')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Open Claims',
            dataIndex: 'openClaims',
            key: 'openClaims',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('openClaims')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('totalAmount')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Estimated ROI',
            dataIndex: 'estimatedROI',
            key: 'estimatedROI',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('estimatedROI')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('balance')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Last Update',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('updatedAt')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Status',
            key: 'status',
            render: (value) => value || '',
        }
    ]


    const createNewOrder = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        // resetUserInputsValues(true);
        navigate.push('/orders/new');
    }

    const actionButtons = [{
        label: 'New order',
        type: 'primary',
        onClick: createNewOrder,
    }];


    useEffect(() => {
        // Call the API to fetch the orders list
        getOrdersListAction({
            pageNo,
            pageLimit,
        });
    }, [getOrdersListAction, pageNo, pageLimit]);

    // Show Success / Failure Message
    useEffect(() => {
        if (!loading) {
            if (error) {
                message.error(error).then(() => {
                    updateOrderStateValues({
                        error: '',
                    })
                });
            } else if (success) {
                message.success(success).then(() => {
                    updateOrderStateValues({
                        error: '',
                    })
                });
            }
        }
    }, [loading, error, success, updateOrderStateValues]);

    return (
        <LayoutComponent>
            <div>
                <BreadcrumbComponent actionButtons={actionButtons} />
                <Card size="small">
                    <div className='order-listing-page'>
                        <Table
                            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                            dataSource={orders}
                            columns={columns}
                            loading={loading}
                            rowKey="id"
                            scroll={{x: 2000}}
                        />
                    </div>
                </Card>
            </div>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ ordersState }) => {
    const {
        orders, loading, error, success,
    } = ordersState;

    return {
        orders,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getOrdersListAction,
    updateOrderStateValues,
    resetOrderInputsValues,
})(OrdersListingPage);
