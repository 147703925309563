import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Button, Col, Form, Input, message, Row, Select} from 'antd'
import {
    createNewUserAction,
    getUserDetailsAction,
    resetUserInputsValues,
    updateRoleUserDetailsAction,
    updateUserStateValues
} from "../../store/actions/users.action";
import ActionButtonsComponent from "../../components/shared/action-buttons/ActionButtons.component";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {searchPermissionsListAction} from "../../store/actions/permissions.action";
import {searchRolesListAction} from "../../store/actions/roles.action";
import {PREBUILT_GROUPS} from "../../constants/groups.constants";
import Text from "antd/es/typography/Text";
import EffectiveRolePermission from "../shared/effective-roles-permission/EffectiveRolePermission";
import RolePermissionSelector from "../../components/shared/group-permissions-selector/RolePermissionSelector";

const UserRolesForm = ({isAdmin, success, error, selectedUserInputs, id, getUserDetailsAction, searchedRoles, updateUserStateValues, searchedPermissions, resetUserInputsValues, selectedUser, searchPermissionsListAction, searchRolesListAction, updateRoleUserDetailsAction}) => {

    const rolesOptions = searchedRoles.map(role => <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>)

    const [viewMode, setViewMode] = useState(true)
    const idParam = useParams().id

    const navigate = useHistory();

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (success) {
            message.success(success);
            setViewMode(true);
            updateUserStateValues({
                success: ''
            });
            navigate.push(`/users/`);
        }
    }, [navigate, id, success, updateUserStateValues])

    useEffect(() => {
        resetUserInputsValues(idParam);
        getUserDetailsAction(idParam)
        setViewMode(true)
    }, [getUserDetailsAction, idParam, resetUserInputsValues]);

    useEffect(() => {
        console.log('searchPermissionsListAction');
        searchPermissionsListAction();
    }, [searchPermissionsListAction]);

    useEffect(() => {
        searchRolesListAction();
    }, [searchRolesListAction]);

    useEffect(() => {
        if (error) {
            message.error(error);
            updateUserStateValues({
                error: null,
            })
            setViewMode(false)
        }
    }, [error, updateUserStateValues])

    const onChangeInput = (e) => {
        const { name, value } = e.target
        console.log({ name, value });
        updateUserStateValues({
            selectedUserInputs: {
                ...selectedUserInputs,
                [name]: value,
            }
        });
    }
    // TODO Need to figure out why the method are not the correct redux state values
    const onCancel = () => {
        resetUserInputsValues(false);
        setViewMode(true);
    };

    const onSave = () => {
        updateRoleUserDetailsAction(selectedUserInputs);
    }

    const onEdit = () => {
        console.log({selectedUser, selectedUserInputs, id})
        setViewMode(false);
    };
    return (
        <div>
            <div>
                <Row gutter={[12, 0]}>
                    <Col span={10}>
                        <Form.Item label="Add more roles" labelCol={{ span: 24 }}>
                            <Select
                                showSearch
                                mode="multiple"
                                value={selectedUserInputs.roles}
                                placeholder={"Roles"}
                                defaultActiveFirstOption={false}
                                showArrow={true}
                                filterOption={true}
                                onChange={(value) => {
                                    onChangeInput({target: {name: 'roles', value,}})
                                }}
                                onClear={() => {
                                    onChangeInput({target: {name: 'roles', value: []}})
                                }}
                                notFoundContent={<>0 results found</>}
                                size="large"
                                allowClear
                                disabled={viewMode}
                                labelInValue={true}
                            >
                                {rolesOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    selectedUser && selectedUser.Roles ? selectedUser.Roles.map(role => (
                        <div className="inner-section">
                            <div className="main-section">
                                <Row gutter={[12, 0]}>
                                    <Col span={2}>
                                        <Form.Item label="Priority" labelCol={{ span: 24 }}>
                                            <Input name="priority" type="text" placeholder="Priority" size="large" value={role.priority} disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Name" labelCol={{ span: 24 }}>
                                            <Input name="name" type="text" placeholder="Name" size="large" value={role.name} disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Description" labelCol={{ span: 24 }}>
                                            <Input name="description" type="text" placeholder="Description" size="large" value={role.description} disabled={true} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Details" labelCol={{ span: 24 }}>
                                            <Button type="primary" className="detail-btn" onClick={() => {  navigate.push(`/roles/${role.id}`) }}>Details</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {
                                    <Row gutter={[12, 0]}>
                                        <Col span={20}>
                                            <RolePermissionSelector
                                                viewMode={true}
                                                onChange={() => {}}
                                                searchedPermissions={searchedPermissions}
                                                rolePermissions={role.RolesPermissions}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </div>
                    )) : null
                }
                <div className="inner-section">
                    <div className="main-section">
                        <Form.Item label="" labelCol={{ span: 24 }}>
                            <Text style={{ fontSize: '16px', marginBottom: '16px', display: "block" }}>List of effective permissions applied from roles</Text>
                            <EffectiveRolePermission
                                viewMode={true}
                                rolesList={selectedUser.Roles}
                                searchedPermissions={searchedPermissions}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
            <ActionButtonsComponent
                className='gap-8 right'
                labelMap={{
                    save: 'Save',
                    edit: 'Edit',
                    cancel: 'Cancel'
                }}
                handleSave={onSave}
                handleCancel={onCancel}
                handleEdit={onEdit}
                hideEdit={isAdmin}
                viewMode={viewMode}
                isNew={false}
            />
        </div>
    )
}

UserRolesForm.propTypes = {
    onChangeInput: PropTypes.func,
    selectedUserInputs: PropTypes.object,
    viewMode: PropTypes.bool,
}

UserRolesForm.defaultProps = {
    viewMode: false,
}

const mapStateToProps = ({ usersState, permissionsState, rolesState }) => {
    const selectedUserInputs = usersState.selectedUserInputs;
    const selectedUser = {...usersState.selectedUser};
    return {
        loading: usersState.loading,
        success: usersState.success,
        id: usersState.id,
        isAdmin: selectedUser.UserGroups ? selectedUser.UserGroups.some((group) => group.name === PREBUILT_GROUPS.ADMIN) : false,
        error: usersState.error,
        selectedUserInputs,
        searchedRoles: rolesState.searchedRoles,
        searchedPermissions: permissionsState.searchedPermissions,
        selectedPermissions: usersState.selectedUserInputs.selectedPermissions,
        selectedUser: Object.keys(selectedUser).length ? selectedUser : {firstName: 'initial'},
    }
}

export default
connect(mapStateToProps, {
    updateUserStateValues,
    getUserDetailsAction,
    searchPermissionsListAction,
    searchRolesListAction,
    updateRoleUserDetailsAction,
    createNewUserAction,
    resetUserInputsValues,
})(UserRolesForm)
