import React from 'react';
import {Checkbox, Empty} from 'antd';
import {ACCESS_TYPES} from "../../../constants/Permissions.constant";
import './index.scss';

const CheckboxGroup = Checkbox.Group;

const accessTypeOptions = [ACCESS_TYPES.CREATE, ACCESS_TYPES.VIEW, ACCESS_TYPES.UPDATE, ACCESS_TYPES.DELETE];

const PermissionSelector = ({onChange, viewMode, searchedPermissions, selectedPermissions}) => {
    const onChangeHandler = (perm, options) => {
        onChange({
            ...selectedPermissions,
            [perm.name]: options,
        })
    }
    return (
        <div className={'permission-selector-container'}>
            {
                searchedPermissions.map(perm => {
                    if (!(viewMode && Object.keys(selectedPermissions).indexOf(perm.name) === -1)) {
                        return (
                            <div className='permission-container'>
                                <div className='permission-name'>
                                    Id: [{perm.id}], Name: [{perm.name}], Type: [{perm.type}]
                                </div>
                                <div className='permission-description'>
                                    {perm.description}
                                </div>
                                <div className='permission-options'>
                                    <CheckboxGroup
                                        options={accessTypeOptions}
                                        value={selectedPermissions[perm.name] || []}
                                        onChange={(e) => onChangeHandler(perm, e)}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        return null;
                    }
                })
            }
            {
                searchedPermissions.length !== 0 && Object.keys(selectedPermissions).length === 0 && (
                    <div className='d-flx'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )
            }
        </div>
    );
}

export default PermissionSelector;