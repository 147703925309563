import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {getSuppliersListAction, updateSupplierStateValues, resetSupplierInputsValues} from "../../store/actions/suppliers.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const SuppliersPage = ({ getSuppliersListAction, suppliers, loading, error, success, updateSupplierStateValues, resetSupplierInputsValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [showSupplierDeleteModal, setShowSupplierDeleteModal] = useState(false)
    const [supplierToDelete, setSupplierToDelete] = useState({});

    const supplierTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/suppliers/${id}`} key={id}>
                    {id}
                </Link>
            ),
        }, {
            title: 'Business Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, row) => (`${row.firstName} ${row.lastName}`)
        }, {
            title: 'Contact Name',
            dataIndex: 'contactName',
            key: 'contact',
            render: (_, row) => (`${row.contactFirstName} ${row.contactLastName}`)
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('email')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('phone')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('status')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewSupplier(record.id)}>View Supplier</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Supplier</Button>*/}
                </>
            ),
        }
    ]

    const handleViewSupplier = (id) => {
        navigate.push(`/suppliers/${id}`);
    }

    const handleDeleteSupplier = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        setShowSupplierDeleteModal(false)
        // deleteSupplierAPI(supplierToDelete)
    }

    const cancelSupplierDelete = () => {
        setSupplierToDelete({})
        setShowSupplierDeleteModal(false)
    }

    const createNewSupplier = () => {
        resetSupplierInputsValues(true);
        navigate.push('/suppliers/new');
    }

    // const handleDeleteConfirmation = (supplier) => {
    //     setSupplierToDelete(supplier)
    //     setShowSupplierDeleteModal(true)
    // }

    useEffect(() => {
        // Call the API to fetch the suppliers list
        getSuppliersListAction({
            pageNo,
            pageLimit,
        });
    }, [getSuppliersListAction, pageNo, pageLimit]);

    useEffect(() => {
        // Call the API to fetch the suppliers list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateSupplierStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateSupplierStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateSupplierStateValues]);

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createNewSupplier}>Create New Supplier</Button>
            </div>
            <Table
                dataSource={suppliers}
                columns={supplierTableColumns}
                loading={loading}
                rowKey="id"
            />
            <Modal
                title={<b>Are you sure?</b>}
                visible={showSupplierDeleteModal}
                onOk={handleDeleteSupplier}
                onCancel={cancelSupplierDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Supplier {supplierToDelete.name} ({supplierToDelete.email})?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ suppliersState }) => {
    const {
        suppliers, loading, error, success,
    } = suppliersState;

    return {
        suppliers,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getSuppliersListAction,
    updateSupplierStateValues,
    resetSupplierInputsValues,
})(SuppliersPage);
