import {
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORIES,
    GET_CATEGORIES_FAIL,
    GET_CATEGORIES_SUCCESS,
    POST_CATEGORY,
    POST_CATEGORY_FAIL,
    POST_CATEGORY_SUCCESS,
    PUT_CATEGORY,
    PUT_CATEGORY_FAIL,
    PUT_CATEGORY_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/category.action-type";

export const resetCategoryInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
});

export const updateCategoryStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const getCategoryDetailsAction = (payload) => {
    return {
        type: GET_CATEGORY,
        payload,
    };
};

export const getCategoryDetailsSuccessAction = (payload) => {
    return {
        type: GET_CATEGORY_SUCCESS,
        payload,
    };
};

export const getCategoryDetailsFailAction = (payload) => {
    return {
        type: GET_CATEGORY_FAIL,
        payload,
    };
};


export const updateCategoryDetailsAction = (payload) => {
    return {
        type: PUT_CATEGORY,
        payload,
    };
};

export const updateCategoryDetailsSuccessAction = (payload) => {
    return {
        type: PUT_CATEGORY_SUCCESS,
        payload,
    };
};

export const updateCategoryDetailsFailAction = (payload) => {
    return {
        type: PUT_CATEGORY_FAIL,
        payload,
    };
};


export const createNewCategoryAction = (payload) => {
    return {
        type: POST_CATEGORY,
        payload,
    };
};

export const createNewCategorySuccessAction = (payload) => {
    return {
        type: POST_CATEGORY_SUCCESS,
        payload
    };
};

export const createNewCategoryFailAction = (payload) => {
    return {
        type: POST_CATEGORY_FAIL,
        payload,
    };
};

export const getCategoriesListAction = (payload) => ({
    type: GET_CATEGORIES,
    payload
});

export const getCategoriesListSuccessAction = (payload) => {
    return {
        type: GET_CATEGORIES_SUCCESS,
        payload
    };
};

export const getCategoriesListFailAction = (payload) => {
    return {
        type: GET_CATEGORIES_FAIL,
        payload,
    };
};
