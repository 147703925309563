import formConstants from "../../../../constants/form.constants";
import FormComponent from "../../../../components/shared/form-component/index";
import {connect, useSelector} from "react-redux";
import {updateCostOptionsFormStateValues} from "../../../../store/actions/cost-options.action";

const CostOptionFormItem = ({idx, updateCostOptionsFormStateValues}) => {

    const handleChange = ({name, value}) => {
        updateCostOptionsFormStateValues({
            del: deletes,
            update: updates.map((v) => ({
                ...v,
                [name]: v.idx === idx ? value : v[name],
            }))
        });
    }

    const handleRemove = () => {
        // item at idx has id => it will be deleted from the DB else just filter it out
        const deletedRow = updates.find(input => input.idx === idx);
        updateCostOptionsFormStateValues({
            del: deletedRow.id ? [...deletes, deletedRow.id] : deletes,
            update: updates.filter(input => input.idx !== idx)
        });
    }


    const {updates, deletes, formFields, isEditing} = useSelector(({costOptionsState}) => {
        const values = costOptionsState.costOptionsInputs.update.find(item => item.idx === idx);
        return {
            updates: costOptionsState.costOptionsInputs.update,
            deletes: costOptionsState.costOptionsInputs.del,
            isEditing: costOptionsState.isEditing,
            formFields: [{
                ...formConstants.fullName,
                name: 'name',
                label: 'Name',
                value: values.name,
                onChange: handleChange,
                placeholder: 'Name',
                span: 8,
            }, {
                ...formConstants.fullName,
                name: 'description',
                label: 'Description',
                value: values.description,
                onChange: handleChange,
                placeholder: 'description',
                span: 12,
            }, {
                label: 'Remove',
                onClick: handleRemove,
                fieldType: formConstants.fieldTypes.Button,
                type: 'danger',
                hide: !costOptionsState.isEditing,
                span: 4,
            }]
        }
    })

    return (
        <div>
            <FormComponent formFields={formFields} viewOnly={!isEditing}/>
        </div>
    )
}

export default connect(null, {
    updateCostOptionsFormStateValues,
})(CostOptionFormItem);
