import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {getUsersListAction, resetUserInputsValues, updateUserStateValues} from "../../store/actions/users.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const UsersPage = ({ getUsersListAction, users, loading, error, success, updateUserStateValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('id')
    const [orderDirection, setOrderDirection] = useState('desc')

    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)
    const [userToDelete, setUserToDelete] = useState({});

    const userTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/users/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, row) => (`${row.firstName} ${row.lastName}`)
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('email')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('phone')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: val => val || 'No Phone'
        }, {
            title: 'Roles',
            dataIndex: 'Roles',
            key: 'Roles',
            render: (roles => {
                return roles.length ? roles.map(role => role.name).join(', ') : 'No Roles'
            })
        }, {
            title: 'Groups',
            dataIndex: 'UserGroups',
            key: 'UserGroups',
            render: (groups => {
                return groups.length ? groups.map(group => group.name).join(', '): 'No Groups'
            })
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('status')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: val => val || '-'
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewUser(record.id)}>View User</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete User</Button>*/}
                </>
            ),
        }
    ]

    const handleViewUser = (id) => {
        navigate.push(`/users/${id}`);
    }

    const handleDeleteUser = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        setShowUserDeleteModal(false)
        // deleteUserAPI(userToDelete)
    }

    const cancelUserDelete = () => {
        setUserToDelete({})
        setShowUserDeleteModal(false)
    }

    const createNewUser = () => {
        resetUserInputsValues(true);
        navigate.push('/users/new');
    }
    useEffect(() => {
        // Call the API to fetch the users list
        getUsersListAction({
            pageNo,
            pageLimit,
        });
    }, [getUsersListAction, pageNo, pageLimit]);

    useEffect(() => {
        // Call the API to fetch the users list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateUserStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateUserStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateUserStateValues]);

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createNewUser}>Create New User</Button>
            </div>
            <Table
                dataSource={users}
                columns={userTableColumns}
                loading={loading}
                rowKey="id"
            />
            <Modal
                title={<b>Are you sure?</b>}
                visible={showUserDeleteModal}
                onOk={handleDeleteUser}
                onCancel={cancelUserDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove User {userToDelete.name} ({userToDelete.email})?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ usersState }) => {
    const {
        users, loading, error, success,
    } = usersState;

    return {
        users,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getUsersListAction,
    updateUserStateValues,
})(UsersPage);
