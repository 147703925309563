import React, {useCallback, useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {
    getGroupsListAction,
    resetGroupInputsValues,
    updateGroupsPriorityAction,
    updateGroupStateValues
} from "../../store/actions/groups.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import DraggableBodyRow from "../../components/shared/draggableBodyRow";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const GroupsPage = ({ getGroupsListAction, groups, loading, error, success, updateGroupStateValues, updateGroupsPriorityAction }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('priority')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showOrderModal, setShowPriorityModal] = useState(false)

    const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false)
    const [userToDelete, setGroupToDelete] = useState({});

    const userGroupsTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/groups/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },{
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('priority')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        },  {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button primary className='margin-2' onClick={() => handleViewGroup(record.id)}>View Group</Button>
                    <Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Group</Button>
                </>
            ),
        }
    ]

    const handleViewGroup = (id) => {
        console.log(showOrderModal)
        navigate.push(`/groups/${id}`);
    }

    const handleDeleteGroup = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection);
        setShowGroupDeleteModal(false)
        // deleteGroupAPI(userToDelete)
    }

    const cancelGroupDelete = () => {
        setGroupToDelete({})
        setShowGroupDeleteModal(false)
    }

    const handleDeleteConfirmation = (user) => {
        setGroupToDelete(user)
        setShowGroupDeleteModal(true)
    }

    const createUserGroup = () => {
        resetGroupInputsValues(true);
        navigate.push('/groups/new');
    }

    useEffect(() => {
        // Call the API to fetch the groups list
        getGroupsListAction({
            pageNo,
            pageLimit,
            orderKey,
            orderDirection,
        });
    }, [getGroupsListAction, pageNo, pageLimit, orderKey, orderDirection]);

    useEffect(() => {
        // Call the API to fetch the groups list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateGroupStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateGroupStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateGroupStateValues]);
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            if (orderKey !== 'priority') {
                setShowPriorityModal(true)
                return
            }
            updateGroupsPriorityAction({
                fromIndex: dragIndex,
                toIndex: hoverIndex,
                orderKey,
                orderDirection,
            })
        },
        [orderDirection, orderKey, updateGroupsPriorityAction],
    )

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createUserGroup}>Create New Group</Button>
            </div>
            <DndProvider backend={HTML5Backend}>
                <Table
                    dataSource={groups}
                    columns={userGroupsTableColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                    rowClassName={(record) => {
                        return null
                        // if (!record.is_enabled) {
                        //     return 'light-red-colored-row'
                        // }
                    }}
                    components={components}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                />
            </DndProvider>
            <Modal
                title={<b>Are you sure?</b>}
                visible={showGroupDeleteModal}
                onOk={handleDeleteGroup}
                onCancel={cancelGroupDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Group {userToDelete.name}?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ groupsState }) => {
    const {
        groups, loading, error, success,
    } = groupsState;

    return {
        groups,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getGroupsListAction,
    updateGroupStateValues,
    updateGroupsPriorityAction,
})(GroupsPage);
