import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_GROUP,
    PUT_GROUP,
    GET_GROUPS,
    POST_GROUP,
    SEARCH_GROUPS,
    UPDATE_GROUPS_PRIORITY
} from '../action-types/groups.action-type';

import GroupsBackendApiService from "../../services/backend-api/groups.backend-api.service";
import {
    createNewGroupFailAction,
    createNewGroupSuccessAction,
    getGroupDetailsFailAction,
    getGroupDetailsSuccessAction,
    getGroupsListFailAction,
    getGroupsListSuccessAction, searchGroupsListFailAction,
    searchGroupsListSuccessAction,
    updateGroupDetailsFailAction,
    updateGroupDetailsSuccessAction, updateGroupsPriorityFailureAction, updateGroupsPrioritySuccessAction
} from "../actions/groups.action";

function* groupDetails({payload}) {
    try {
        const response = yield call(GroupsBackendApiService.getGroupDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getGroupDetailsSuccessAction(response.data));
        } else {
            yield put(getGroupDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getGroupDetailsFailAction(error.response));
    }
}

function* searchGroupsList(payload) {
    try {
        const response = yield call(GroupsBackendApiService.getGroupsList, payload);
        if (response.success) {
            yield put(searchGroupsListSuccessAction(response.data));
        } else {
            yield put(searchGroupsListFailAction(response.message));
        }
    } catch (error) {
        yield put(searchGroupsListFailAction(error.response));
    }
}


function* getGroupsList({payload}) {
    try {
        const response = yield call(GroupsBackendApiService.getGroupsList, payload);
        if (response.success) {
            yield put(getGroupsListSuccessAction(response.data));
        } else {
            yield put(getGroupsListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getGroupsListFailAction(error.response));
    }
}

function* updateGroupDetails({payload}) {
    try {
        const response = yield call(GroupsBackendApiService.updateGroupDetails, payload);
        if (response.success) {
            yield put(updateGroupDetailsSuccessAction(response));
        } else {
            yield put(updateGroupDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateGroupDetailsFailAction(error.response));
    }
}


function* createNewGroup({payload}) {
    try {
        console.log({payload});
        const response = yield call(GroupsBackendApiService.createNewGroup, payload);
        if (response.success) {
            yield put(createNewGroupSuccessAction(response.data));
        } else {
            yield put(createNewGroupFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewGroupFailAction(error.response));
    }
}

function* updateGroupsPriority({payload}) {
    try {
        const response = yield call(GroupsBackendApiService.updateGroupsPriority, payload);
        if (response.success) {
            yield put(updateGroupsPrioritySuccessAction(response.data));
        } else {
            yield put(updateGroupsPriorityFailureAction(response.message));
        }
    } catch (error) {
        yield put(updateGroupsPriorityFailureAction(error.response));
    }
}

function* GroupsSaga() {
    yield takeLatest(GET_GROUP, groupDetails);
    yield takeLatest(GET_GROUPS, getGroupsList);
    yield takeLatest(SEARCH_GROUPS, searchGroupsList);
    yield takeLatest(POST_GROUP, createNewGroup);
    yield takeLatest(PUT_GROUP, updateGroupDetails);
    yield takeLatest(UPDATE_GROUPS_PRIORITY, updateGroupsPriority);
}

export default GroupsSaga;