export const UPDATE_COST_VALUES = "COST.UPDATE_COST_VALUES";
export const UPDATE_COST_FORM_VALUES = "COST.UPDATE_COST_FORM_VALUES";

export const RESET_COST_FORM_VALUES = "COST.RESET_COST_FORM_VALUES";

export const GET_COSTS_FOR_VENDOR = "COST.GET_COSTS_FOR_VENDOR";
export const GET_COSTS_FOR_VENDOR_SUCCESS = "COST.GET_COSTS_FOR_VENDOR_SUCCESS";
export const GET_COSTS_FOR_VENDOR_FAIL = "COST.GET_COSTS_FOR_VENDOR_FAIL";

export const POST_COSTS_FOR_VENDOR = "COST.POST_COSTS_FOR_VENDOR";
export const POST_COSTS_FOR_VENDOR_SUCCESS = "COST.POST_COSTS_FOR_VENDOR_SUCCESS";
export const POST_COSTS_FOR_VENDOR_FAIL = "COST.POST_COSTS_FOR_VENDOR_FAIL";
