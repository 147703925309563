import {put, takeLatest, call} from "redux-saga/effects";

import {LOGIN, SIGN_OUT, GET_TOKEN_INFO} from '../action-types/auth.action-type';

import AuthBackendApiService from "../../services/backend-api/auth.backend-api.service";
import {
    loginFailAction,
    loginSuccessAction, signOutFailAction,
    signOutSuccessAction,
    tokenInfoFailAction,
    tokenInfoSuccessAction
} from "../actions";

function* login({payload}) {
    try {
        const response = yield call(AuthBackendApiService.signin, payload);
        if (response.success) {
            yield put(loginSuccessAction(response));
        } else {
            yield put(loginFailAction(response.message));
        }
    } catch (error) {
        yield put(loginFailAction(error.response));
    }
}

function* getTokenInfo() {
    try {
        const response = yield call(AuthBackendApiService.me);
        if (response.success) {
            yield put(tokenInfoSuccessAction(response));
        } else {
            yield put(tokenInfoFailAction(response.message));
        }
    } catch (error) {
        yield put(tokenInfoFailAction(error.response));
    }
}

function* signOut() {
    try {
        const response = yield call(AuthBackendApiService.signOut);
        yield put(signOutSuccessAction(response));
        window.location.href = '/sign-in';
    } catch (error) {
        yield put(signOutFailAction(error.response));
    }
}

function* AuthSaga() {
    yield takeLatest(LOGIN, login);
    yield takeLatest(GET_TOKEN_INFO, getTokenInfo);
    yield takeLatest(SIGN_OUT, signOut);
}

export default AuthSaga;