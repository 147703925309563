import './index.scss';


export const AddProductSummary = () => {

    const items = [{
        asin: 'B08HD35Y98',
        qty: 2,
    }, {
        asin: 'B08WD35Y12',
        qty: 13,
    }];

    return (
        <div className='product-summary-cont'>
            <div className='margin-8-bottom'>Order Summary</div>
            <div className='summary-table'>
                <div className="summary-table-header d-flx jc-between">
                    <div className='summary-table-header-col'>ASIN</div>
                    <div className='summary-table-header-col'>Quantity</div>
                </div>
                {
                    items.map(item => (
                        <div className="summary-table-data-item d-flx jc-between">
                            <div className=''>{item.asin}</div>
                            <div className=''>{item.qty}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
