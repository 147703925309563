import {Form, Input, Button, message} from 'antd';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import "./index.scss";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import AccountInviteBackendApiService from "../../services/backend-api/account-invite.backend-api.service";

const AccountInviteComponent = () => {
    const [user, setUser] = useState({});
    const [heading, setHeading] = useState('Account invite');

    const id = useParams().id;
    const [form] = Form.useForm();

    const navigate = useHistory();

    useEffect(() => {
        AccountInviteBackendApiService.getAccountInviteInfo(id).then(response => {
            if (response.success) {
                setUser(response.data.user);
                form.setFieldsValue(response.data.user);
                setHeading(`Account invitation to ${response.data.user.firstName} ${response.data.user.lastName}`);
            } else {
                message.error(response.message);
            }
        });
    }, [id, form,]);

    const onSubmit = (values) => {
        AccountInviteBackendApiService.completeInvitation({
            email: user.email,
            id: user.id,
            password: values.password,
            confirmPassword: values.confirmPassword,
        }).then(response => {
            if (response.success) {
                message.success(response.data.message)
                navigate.push('/sign-in');
            } else {
                message.error(response.message);
            }
        });
    };
    // const onChangeInput = (e) => {
    //     const {name, value} = e.target;
    //     setUser((prevState => ({
    //         ...prevState,
    //         [name]: value,
    //     })))
    // }

    return (
        <div className='signup-page'>
            <div className='signup-container'>
                <div className='title-container'>
                    <h3>AMZWare</h3>
                    <h4 className='description'>{heading}</h4>
                </div>
                <div className='form-container'>

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onSubmit}
                        form={form}
                    >
                        <Form.Item
                            name="email"
                        >
                            <Input disabled={true} value={user.email} type='email' prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Confirm Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Accept Invite
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

            </div>
        </div>
    );
};

export default AccountInviteComponent;
