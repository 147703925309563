import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Button, Col, Empty, Form, Input, Row} from 'antd'
import GroupsParserService from "../../services/parsers/groups.parser.service";
import PermissionSelector from "../../components/shared/permission-selector/PermissionSelector";
import {useHistory} from "react-router-dom";

const PermissionGroupDetails = ({permission, searchedPermissions}) => {
    const [parsedUserGroupsPermissions, setParsedUserGroupsPermissions] = useState({})
    const navigate = useHistory();
    useEffect(() => {
        if (permission.UserGroupsPermissions) {
            const parsedData = GroupsParserService.parseGroupsPermissionList(permission.UserGroupsPermissions);
            console.log({parsedData})
            setParsedUserGroupsPermissions(parsedData.parsedGroupsPermissionMap);
        }
    }, [permission])

    return (
        <div className="inner-section">
            <div className="header-container">
                <div className="group-permission-header">
                    Groups with this permission attached
                </div>
            </div>
            <div className="main-section">
                {
                    Object.keys(parsedUserGroupsPermissions).map((permRow) => (
                        <Row gutter={[12, 0]}>
                            <Col span={8}>
                                <Form.Item label="Name" labelCol={{ span: 24 }}>
                                    <Input name="name" type="text" placeholder="Name" size="large" value={parsedUserGroupsPermissions[permRow].Group.name} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Description" labelCol={{ span: 24 }}>
                                    <Input name="description" type="text" placeholder="Description" size="large" value={parsedUserGroupsPermissions[permRow].Group.description} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Details" labelCol={{ span: 24 }}>
                                    <Button type="primary" className="detail-btn" onClick={() => {  navigate.push(`/groups/${parsedUserGroupsPermissions[permRow].Group.id}`) }}>Details</Button>
                                </Form.Item>
                            </Col>
                            <Col span={20}>
                                <PermissionSelector
                                    viewMode={true}
                                    onChange={() => {}}
                                    searchedPermissions={searchedPermissions}
                                    selectedPermissions={parsedUserGroupsPermissions[permRow].selectedPermissions}
                                />
                            </Col>
                        </Row>
                    ))
                }
                {
                    Object.keys(parsedUserGroupsPermissions).length === 0 && (
                        <Empty style={{margin: 0}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                }
            </div>
        </div>
    )
}
const mapStateToProps = ({ permissionsState }) => {
    return {
        permission: permissionsState.selectedPermissionInputs,
        searchedPermissions: permissionsState.permissions,
    }
}

export default connect(mapStateToProps)(PermissionGroupDetails)
