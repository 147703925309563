import axios from "axios";
import {LocalStorageConstant} from "../../constants/local-storage.constant";

const API = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 60000,
})

API.interceptors.request.use(
    request =>  {
        // console.log(request.url);
        if (request.url !== '/auth/sign-in') {
            const token = localStorage.getItem(LocalStorageConstant.AMZWARE_TOKEN)
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
    },
    error => {
        return Promise.reject(error);
    }
)

API.interceptors.response.use(
    response => {
        if ([401, 403].includes(response.status)) {
            if (window.location.pathname !== '/sign-in') {
                window.location.replace('/sign-in')
            }
            // antMessage.error('Please Login! You must be logged in to view this content!')
        }
        return response;
    },
    error => {
        // if (error?.message === "Request failed with status code 404") {
        //     window.location.replace('/sign-in')
        // }
        if ([401, 403].includes(error?.response?.status)) {
            if (window.location.pathname !== '/sign-in') {
                window.location.replace('/sign-in')
            }
            // antMessage.error('Please Login! You must be logged in to view this content!')
        }
        return Promise.reject(error);
    }
)

export default API;
