export const UPDATE_VALUES = "UPDATE_ROLE_VALUES";

export const RESET_FORM_VALUES = "RESET_ROLE_FORM_VALUES";

export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAIL = "GET_ROLE_FAIL";

export const SEARCH_ROLES = "SEARCH_ROLES";
export const SEARCH_ROLES_SUCCESS = "SEARCH_ROLES_SUCCESS";
export const SEARCH_ROLES_FAIL = "SEARCH_ROLES_FAIL";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const UPDATE_ROLES_PRIORITY = "UPDATE_ROLES_PRIORITY";
export const UPDATE_ROLES_PRIORITY_SUCCESS = "UPDATE_ROLES_PRIORITY_SUCCESS";
export const UPDATE_ROLES_PRIORITY_FAIL = "UPDATE_ROLES_PRIORITY_FAIL";

export const POST_ROLE = "POST_ROLE";
export const POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS";
export const POST_ROLE_FAIL = "LOGIN_FAIL";

export const PUT_ROLE = "PUT_ROLE";
export const PUT_ROLE_SUCCESS = "PUT_ROLE_SUCCESS";
export const PUT_ROLE_FAIL = "PUT_ROLE_FAIL";