import API from "../interceptor/axios.interceptor.service";

class UsersBackendApiService {
    static async getUserDetail(id) {
        const url = `/api/v1/users/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching user detail, please try again!',
            }
        }
    }

    static async createNewUser(payload) {
        const url = `/api/v1/users/`;
        try {
            const response = await API.post(url, {
                firstName: payload.firstName,
                lastName: payload.lastName,
                phone: payload.phone,
                email: payload.email,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong getting information from token!',
            }
        }
    }

    static async getUsersList(payload) {
        const url = payload.pageNo && payload.pageLimit ?  `/api/v1/users/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/users/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching the users list!',
            }
        }
    }

    static async updateBasicDetails(payload) {
        const url = `/api/v1/users/${payload.id}/basic`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                firstName: payload.firstName,
                lastName: payload.lastName,
                phone: payload.phone,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the user detail!',
            }
        }
    }
    static async updatePermissionAttached(payload) {
        const url = `/api/v1/users/${payload.id}/permissions`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                selectedPermissions: payload.selectedPermissions,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the user detail!',
            }
        }
    }
    static async updateGroupsAttached(payload) {
        const url = `/api/v1/users/${payload.id}/groups`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                groups: payload.groups.map(group => group.key),
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the user detail!',
            }
        }
    }
    static async updateRolesAttached(payload) {
        const url = `/api/v1/users/${payload.id}/roles`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                roles: payload.roles.map(role => role.key),
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the user detail!',
            }
        }
    }
}

export default UsersBackendApiService;