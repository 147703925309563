import {
    UPDATE_VALUES, GET_TOKEN_INFO, GET_TOKEN_INFO_FAIL, GET_TOKEN_INFO_SUCCESS, LOGIN,
    LOGIN_FAIL, LOGIN_SUCCESS, SIGN_OUT, SIGN_OUT_FAIL, SIGN_OUT_SUCCESS
} from "../action-types/auth.action-type";

const initialState = {
    user: {},
    loading: false,
    error: '',
    success: '',
};

const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case LOGIN:
            state = { ...state, loading: true };
            break;
        case LOGIN_SUCCESS:
            state = { ...state, user: payload.user, loading: false, success: payload.message };
            break;
        case LOGIN_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_TOKEN_INFO:
            state = { ...state, loading: true };
            break;
        case GET_TOKEN_INFO_SUCCESS:
            state = { ...state, user: payload, loading: false };
            break;
        case GET_TOKEN_INFO_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case SIGN_OUT:
            state = { ...state, loading: true };
            break;
        case SIGN_OUT_SUCCESS:
            state = { ...state, user: initialState.user, loading: false, success: 'Sign out success event!' };
            break;
        case SIGN_OUT_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default AuthReducer;
