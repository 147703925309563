import {put, takeLatest, call} from "redux-saga/effects";

import {
    GET_USER,
    GET_USERS,
    POST_USER,
    PUT_BASIC_USER,
    PUT_ROLES_USER, PUT_GROUPS_USER, PUT_PERMISSIONS_USER
} from '../action-types/users.action-type';

import UsersBackendApiService from "../../services/backend-api/users.backend-api.service";
import {
    createNewUserFailAction, createNewUserSuccessAction,
    getUserDetailsFailAction, getUserDetailsSuccessAction,
    getUsersListFailAction, getUsersListSuccessAction,
    updateBasicUserDetailsFailAction, updateBasicUserDetailsSuccessAction,
    updateRoleUserDetailsFailAction, updateRoleUserDetailsSuccessAction,
    updateGroupUserDetailsFailAction, updateGroupUserDetailsSuccessAction,
    updatePermissionUserDetailsFailAction, updatePermissionUserDetailsSuccessAction,
} from "../actions/users.action";

function* userDetails({payload}) {
    try {
        const response = yield call(UsersBackendApiService.getUserDetail, payload);
        if (response.success) {
            yield put(getUserDetailsSuccessAction(response.data));
        } else {
            yield put(getUserDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getUserDetailsFailAction(error.response));
    }
}

function* getUsersList({payload}) {
    try {
        const response = yield call(UsersBackendApiService.getUsersList, payload);
        if (response.success) {
            yield put(getUsersListSuccessAction(response.data));
        } else {
            yield put(getUsersListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getUsersListFailAction(error.response));
    }
}

function* updateBasicDetails({payload}) {
    try {
        const response = yield call(UsersBackendApiService.updateBasicDetails, payload);
        if (response.success) {
            yield put(updateBasicUserDetailsSuccessAction(response));
        } else {
            yield put(updateBasicUserDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateBasicUserDetailsFailAction(error.response));
    }
}


function* updatePermissions({payload}) {
    try {
        const response = yield call(UsersBackendApiService.updatePermissionAttached, payload);
        if (response.success) {
            yield put(updatePermissionUserDetailsSuccessAction(response));
        } else {
            yield put(updatePermissionUserDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updatePermissionUserDetailsFailAction(error.response));
    }
}


function* updateRoles({payload}) {
    try {
        const response = yield call(UsersBackendApiService.updateRolesAttached, payload);
        if (response.success) {
            yield put(updateRoleUserDetailsSuccessAction(response));
        } else {
            yield put(updateRoleUserDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateRoleUserDetailsFailAction(error.response));
    }
}

function* updateGroups({payload}) {
    try {
        const response = yield call(UsersBackendApiService.updateGroupsAttached, payload);
        if (response.success) {
            yield put(updateGroupUserDetailsSuccessAction(response));
        } else {
            yield put(updateGroupUserDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateGroupUserDetailsFailAction(error.response));
    }
}

function* createNewUser({payload}) {
    try {
        const parsedPayload = {
            ...payload,
            // permissions: payload.selectedPermissions,
            // groups: payload.groups.map(group => group.value),
            // roles: payload.roles.map(role => role.value),
        };
        delete parsedPayload.selectedPermissions;
        const response = yield call(UsersBackendApiService.createNewUser, parsedPayload);
        if (response.success) {
            yield put(createNewUserSuccessAction(response.data));
        } else {
            yield put(createNewUserFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewUserFailAction(error.response));
    }
}

function* UsersSaga() {
    yield takeLatest(GET_USER, userDetails);
    yield takeLatest(GET_USERS, getUsersList);
    yield takeLatest(POST_USER, createNewUser);
    yield takeLatest(PUT_BASIC_USER, updateBasicDetails);
    yield takeLatest(PUT_ROLES_USER, updateRoles);
    yield takeLatest(PUT_GROUPS_USER, updateGroups);
    yield takeLatest(PUT_PERMISSIONS_USER, updatePermissions);
}

export default UsersSaga;