import {put, takeLatest, call} from "redux-saga/effects";

import {GET_SUPPLIER, PUT_SUPPLIER, GET_SUPPLIERS, POST_SUPPLIER} from '../action-types/suppliers.action-type';

import SuppliersBackendApiService from "../../services/backend-api/suppliers.backend-api.service";
import {
    createNewSupplierFailAction,
    createNewSupplierSuccessAction,
    getSupplierDetailsFailAction,
    getSupplierDetailsSuccessAction, getSuppliersListFailAction,
    getSuppliersListSuccessAction, updateSupplierDetailsFailAction, updateSupplierDetailsSuccessAction
} from "../actions/suppliers.action";

function* supplierDetails({payload}) {
    try {
        const response = yield call(SuppliersBackendApiService.getSupplierDetail, payload);
        console.log(response);
        if (response.success) {
            yield put(getSupplierDetailsSuccessAction(response.data));
        } else {
            yield put(getSupplierDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getSupplierDetailsFailAction(error.response));
    }
}

function* getSuppliersList({payload}) {
    try {
        const response = yield call(SuppliersBackendApiService.getSuppliersList, payload);
        if (response.success) {
            yield put(getSuppliersListSuccessAction(response.data));
        } else {
            yield put(getSuppliersListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getSuppliersListFailAction(error.response));
    }
}

function* updateSupplierDetails({payload}) {
    try {
        const response = yield call(SuppliersBackendApiService.updateSupplierDetails, {
            id: payload.id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            contactFirstName: payload.contactFirstName,
            contactLastName: payload.contactLastName,
            websiteLink: payload.websiteLink,
            phone: payload.phone,
            email: payload.email,
            location: payload.location,
            status: payload.status.value,
        });
        if (response.success) {
            yield put(updateSupplierDetailsSuccessAction(response));
        } else {
            yield put(updateSupplierDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateSupplierDetailsFailAction(error.response));
    }
}


function* createNewSupplier({payload}) {
    try {
        const response = yield call(SuppliersBackendApiService.createNewSupplier, payload);
        if (response.success) {
            yield put(createNewSupplierSuccessAction(response));
        } else {
            yield put(createNewSupplierFailAction(response.message));
        }
    } catch (error) {
        yield put(createNewSupplierFailAction(error.response));
    }
}

function* SuppliersSaga() {
    yield takeLatest(GET_SUPPLIER, supplierDetails);
    yield takeLatest(GET_SUPPLIERS, getSuppliersList);
    yield takeLatest(POST_SUPPLIER, createNewSupplier);
    yield takeLatest(PUT_SUPPLIER, updateSupplierDetails);
}

export default SuppliersSaga;
