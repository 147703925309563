import {
    GET_LEAD_TIME_OPTIONS,
    GET_LEAD_TIME_OPTIONS_FAIL,
    GET_LEAD_TIME_OPTIONS_SUCCESS,
    POST_LEAD_TIME_OPTIONS,
    POST_LEAD_TIME_OPTIONS_FAIL,
    POST_LEAD_TIME_OPTIONS_SUCCESS,
    RESET_OPTION_FORM_VALUES,
    UPDATE_OPTION_FORM_VALUES,
    UPDATE_OPTION_VALUES
} from "../action-types/lead-time-options.action-type";

export const updateLeadTimeOptionsStateValues = (payload) => {
    return {
        type: UPDATE_OPTION_VALUES,
        payload,
    };
};

export const updateLeadTimeOptionsFormStateValues = (payload) => {
    return {
        type: UPDATE_OPTION_FORM_VALUES,
        payload,
    };
};

export const resetLeadTimeOptionsInputsValues = (isNew) => ({
    type: RESET_OPTION_FORM_VALUES,
    payload: { isNew },
})

export const createNewLeadTimeOptionsAction = () => {
    return {
        type: POST_LEAD_TIME_OPTIONS,
    };
};

export const createNewLeadTimeOptionsSuccessAction = (payload) => {
    return {
        type: POST_LEAD_TIME_OPTIONS_SUCCESS,
        payload
    };
};

export const createNewLeadTimeOptionsFailAction = (payload) => {
    return {
        type: POST_LEAD_TIME_OPTIONS_FAIL,
        payload,
    };
};

export const getLeadTimeOptionsAction = (payload) => ({
    type: GET_LEAD_TIME_OPTIONS,
    payload
});

export const getLeadTimeOptionsSuccessAction = (payload) => {
    return {
        type: GET_LEAD_TIME_OPTIONS_SUCCESS,
        payload
    };
};

export const getLeadTimeOptionsFailAction = (payload) => {
    return {
        type: GET_LEAD_TIME_OPTIONS_FAIL,
        payload,
    };
};
