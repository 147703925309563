import API from "../interceptor/axios.interceptor.service";

class RolesBackendApiService {
    static async getRoleDetail(id) {
        const url = `/api/v1/roles/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching role detail, please try again!',
            }
        }
    }

    static async createNewRole(payload) {
        const url = `/api/v1/roles`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating the new role!',
            }
        }
    }

    static async getRolesList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/roles/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}&orderKey=${payload.orderKey}&orderDirection=${payload.orderDirection}` : `/api/v1/roles/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching roles list!',
            }
        }
    }

    static async searchRolesList(payload) {
        const url = `/api/v1/roles/?searchTerm=${payload.searchTerm}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while searching for roles list!',
            }
        }
    }

    static async updateRoleDetails(payload) {
        const url = `/api/v1/roles/${payload.id}`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                name: payload.name,
                description: payload.description,
                selectedPermissions: payload.selectedPermissions,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the role detail!',
            }
        }
    }

    static async updateRolesPriority(payload) {
        const url = `/api/v1/roles/update-priority/`;
        try {
            const response = await API.post(url, {
                fromId: payload.fromId, // Currently undefined, check if we should remove it
                toId: payload.toId, // Currently undefined, check if we should remove it
                fromIndex: payload.fromIndex,
                toIndex: payload.toIndex,
                orderKey: payload.orderKey,
                orderDirection: payload.orderDirection,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the roles priority!',
            }
        }
    }
}

export default RolesBackendApiService;