export const UPDATE_VALUES = "ORDER.UPDATE_ORDER_VALUES";
export const UPDATE_FORM_VALUES = "ORDER.UPDATE_ORDER_FORM_VALUES";

export const RESET_FORM_VALUES = "ORDER.RESET_ORDER_FORM_VALUES";

export const GET_ORDER = "ORDER.GET_ORDER";
export const GET_ORDER_SUCCESS = "ORDER.GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "ORDER.GET_ORDER_FAIL";

export const GET_ALL_ORDERS = "ORDER.GET_ALL_ORDERS";
export const GET_ALL_ORDERS_SUCCESS = "ORDER.GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_FAIL = "ORDER.GET_ALL_ORDERS_FAIL";

export const POST_ORDER = "ORDER.POST_ORDER";
export const POST_ORDER_SUCCESS = "ORDER.POST_ORDER_SUCCESS";
export const POST_ORDER_FAIL = "ORDER.POST_ORDER_FAIL";

export const PUT_ORDER = "ORDER.PUT_ORDER";
export const PUT_ORDER_SUCCESS = "ORDER.PUT_ORDER_SUCCESS";
export const PUT_ORDER_FAIL = "ORDER.PUT_ORDER_FAIL";
