import React from 'react'
import { Spin } from 'antd'
import "./Loader.scss";

const LoaderComponent = ({ props }) => {
    return (
        <div className='loader-container'>
            <Spin {...props} />
        </div>
    )
}

export default LoaderComponent