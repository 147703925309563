import {
    GET_LEAD_TIMES_FOR_VENDOR,
    GET_LEAD_TIMES_FOR_VENDOR_FAIL,
    GET_LEAD_TIMES_FOR_VENDOR_SUCCESS,
    POST_LEAD_TIMES_FOR_VENDOR,
    POST_LEAD_TIMES_FOR_VENDOR_FAIL,
    POST_LEAD_TIMES_FOR_VENDOR_SUCCESS,
    RESET_FORM_VALUES,
    UPDATE_FORM_VALUES,
    UPDATE_VALUES
} from "../action-types/lead-times.action-type";

export const updateLeadTimesStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const updateLeadTimesFormStateValues = (payload) => {
    return {
        type: UPDATE_FORM_VALUES,
        payload,
    };
};

export const resetLeadTimesInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
})

export const createNewLeadTimesForVendorAction = (payload) => {
    return {
        type: POST_LEAD_TIMES_FOR_VENDOR,
        payload,
    };
};

export const createNewLeadTimesForVendorSuccessAction = (payload) => {
    return {
        type: POST_LEAD_TIMES_FOR_VENDOR_SUCCESS,
        payload
    };
};

export const createNewLeadTimesForVendorFailAction = (payload) => {
    return {
        type: POST_LEAD_TIMES_FOR_VENDOR_FAIL,
        payload,
    };
};

export const getLeadTimesForVendorListAction = (payload) => ({
    type: GET_LEAD_TIMES_FOR_VENDOR,
    payload
});

export const getLeadTimesForVendorListSuccessAction = (payload) => {
    return {
        type: GET_LEAD_TIMES_FOR_VENDOR_SUCCESS,
        payload
    };
};

export const getLeadTimesForVendorListFailAction = (payload) => {
    return {
        type: GET_LEAD_TIMES_FOR_VENDOR_FAIL,
        payload,
    };
};
