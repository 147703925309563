export const UsersStatus = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}

export const UserFormTabs = {
    USER_INFO: 'user-info',
    PERMISSIONS: 'permissions',
    ROLES: 'roles',
    GROUPS: 'groups',
}
