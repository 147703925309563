import {
    GET_COST_OPTIONS,
    GET_COST_OPTIONS_FAIL,
    GET_COST_OPTIONS_SUCCESS,
    POST_COST_OPTIONS,
    POST_COST_OPTIONS_FAIL,
    POST_COST_OPTIONS_SUCCESS,
    RESET_COST_OPTION_FORM_VALUES,
    UPDATE_COST_OPTION_FORM_VALUES,
    UPDATE_COST_OPTION_VALUES,
} from "../action-types/cost-options.action-type";


export const costOptionInitialState = {
    id: '',
    name: '',
    description: '',
    idx: 0,
}

const initialState = {
    costOptionsInputs: {
        del: [],
        update: [{...costOptionInitialState}]
    },
    costOptions: [],
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const CostOptionsReducer = (state = initialState, { type, payload }) => {
    console.log(state.costOptionsInputs, type, payload);
    switch (type) {
        case UPDATE_COST_OPTION_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_COST_OPTION_FORM_VALUES:
            state = { ...state, costOptionsInputs: { ...state.costOptionsInputs, ...payload} };
            break;
        case RESET_COST_OPTION_FORM_VALUES:
            state = { ...state,  costOptionsInputs: state.costOptions.length ? {del: [], update: state.costOptions} : initialState.costOptionsInputs, isEditing: false };
            break;
        case GET_COST_OPTIONS:
            state = { ...state, loading: true };
            break;
        case GET_COST_OPTIONS_SUCCESS:
            state = { ...state, costOptions: payload.rows, costOptionsInputs: {del: [], update: payload.rows.map((v,idx) => ({...v, idx}))}, loading: false };
            break;
        case GET_COST_OPTIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_COST_OPTIONS:
            state = { ...state, loading: true };
            break;
        case POST_COST_OPTIONS_SUCCESS:
            state = { ...state, costOptions: payload.rows, loading: false, success: 'cost options updated successfully', isEditing: false };
            break;
        case POST_COST_OPTIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default CostOptionsReducer;
