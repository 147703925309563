import BusinessInfoComponent from "../business-info-form-component";
import BusinessContactFormComponent from "../business-contact-form-component";
import {Card, message} from "antd";
import './index.scss';
import FormComponent from "../../../shared/form-component";
import formConstants from "../../../../constants/form.constants";
import {connect} from "react-redux";
import {
    resetVendorInputsValues,
    updateVendorDetailsAction,
    updateVendorFormStateValues,
    updateVendorStateValues
} from "../../../../store/actions/vendors.action";
import {contactInitialState} from "../../../../store/reducers/vendors.reducer";
import FormHeader from "../../../shared/form-header/index";
import BreadcrumbComponent from "../../../shared/breadcrumb/Breadcrumb";
import VendorsTabs from "../../../vendors/components/vendors-tabs";
import {useCallback, useEffect, useState} from "react";

const VendorDetailInfoTabPaneComponent = ({
     updateVendorFormStateValues,
     updateVendorStateValues,
     isEditing,
     loading,
     success,
     error,
     selectedVendorInputs,
     resetVendorInputsValues,
     updateVendorDetailsAction,

}) => {
    const handleChange = ({name, value}) => {
        updateVendorFormStateValues({
            [name]: value,
        });
    }

    const resellerStatusFormFields = [{
        ...formConstants.select,
        name: 'resellerStatus',
        label: ' ',
        showSearch: false,
        value: selectedVendorInputs.resellerStatus,
        placeholder: 'Choose Status',
        showArrow: true,
        filterOption: true,
        onChange: handleChange,
        span: 24,
        options: [{
            value: 'APPROVED',
            label: 'Approved',
        }, {
            value: 'PENDING',
            label: 'Pending',
        }, {
            value: 'DENIED',
            label: 'Denied',
        }],
    }];


    const accountNumberFormFields = [{
        ...formConstants.fullName,
        name: 'accountNumber',
        label: null,
        value: selectedVendorInputs.accountNumber,
        onChange: handleChange,
        placeholder: 'Account Number',
        span: 24,
    }];

    const typeFormFields = [{
        ...formConstants.checkbox,
        name: 'isDistributor',
        value: selectedVendorInputs.isDistributor,
        onChange: handleChange,
        label: 'Distributor',
        span: 24,
    }, {
        ...formConstants.checkbox,
        name: 'isPrepCenter',
        value: selectedVendorInputs.isPrepCenter,
        onChange: handleChange,
        label: 'Prep Center',
        span: 24,
    }];

    const balanceFormFields = [{
        ...formConstants.fullName,
        name: 'balance',
        value: selectedVendorInputs.balance,
        onChange: handleChange,
        label: null,
        placeholder: 'Balance',
        span: 24,
    }];
    const creditFormFields = [{
        ...formConstants.fullName,
        name: 'credit',
        label: null,
        value: selectedVendorInputs.credit,
        onChange: handleChange,
        placeholder: 'Credit',
        span: 24,
    }];
    const minimumPurchaseFormFields = [{
        ...formConstants.fullName,
        name: 'minimumPurchase',
        label: null,
        value: selectedVendorInputs.minimumPurchase,
        onChange: handleChange,
        placeholder: 'Minimum Purchase Amount',
        span: 24,
    }];
    const currencyFormFields = [{
        ...formConstants.select,
        name: 'currency',
        label: null,
        showSearch: false,
        value: selectedVendorInputs.currency,
        onChange: handleChange,
        placeholder: 'Choose Currency',
        showArrow: true,
        filterOption: true,
        span: 24,
        options: [{
            label: 'US Dollars',
            value: 'USD',
        }, {
            label: 'Indian Rupee',
            value: 'INR',
        }],
    }];

    const handleAddContact = () => {
        updateVendorFormStateValues({
            contacts: [
                ...selectedVendorInputs.contacts,
                {idx: selectedVendorInputs.contacts.length, ...contactInitialState},
            ],
        });
    }


    const handleEdit = useCallback(() => {
        updateVendorStateValues({
            isEditing: true,
        })
        setActionButtons([{
            label: 'Cancel',
            type: 'secondary',
            onClick: handleCancel,
        }, {
            label: 'Save',
            type: 'primary',
            onClick: handleSave,
        }])
        // eslint-disable-next-line
    }, [updateVendorStateValues])

    const handleCancel = useCallback(() => {
        resetVendorInputsValues();
        setActionButtons([{
            label: 'Archive',
            type: 'danger',
            onClick: () => console.log('Archived'),
        }, {
            label: 'Edit',
            type: 'primary',
            onClick: handleEdit,
        }])
        // eslint-disable-next-line
    }, [resetVendorInputsValues])

    const handleSave = useCallback(() => {
        updateVendorDetailsAction();
    }, [updateVendorDetailsAction])

    const [actionButtons, setActionButtons] = useState([{
        label: 'Archive',
        type: 'danger',
        onClick: () => message.info('Archived Message'),
    }, {
        label: 'Edit',
        type: 'primary',
        onClick: handleEdit,
    }]);

    useEffect(() => {
        if (!loading) {
            if (success) {
                message.success(success).then(() => {
                    updateVendorStateValues({
                        success: '',
                    });
                    setActionButtons([{
                        label: 'Archive',
                        type: 'danger',
                        onClick: () => console.log('Archived'),
                    }, {
                        label: 'Edit',
                        type: 'primary',
                        onClick: handleEdit,
                    }])
                })
            } else if (error) {
                message.error(error).then(() => {
                    updateVendorStateValues({
                        error: '',
                    });
                    setActionButtons([{
                        label: 'Cancel',
                        type: 'secondary',
                        onClick: handleCancel,
                    }, {
                        label: 'Save',
                        type: 'primary',
                        onClick: handleSave,
                    }])
                })
            }

        }
    }, [loading, success, error, handleCancel, handleEdit, handleSave, updateVendorDetailsAction, updateVendorStateValues])


    return (
        <div>
            <BreadcrumbComponent actionButtons={actionButtons} />
            <Card size="small">
                <VendorsTabs />
                <div className='vendor-detail-info-tab-pane-component'>
                    <div className='vendor-detail-section'>
                        <div className='business-info'>
                            <div className='business-info-header'>
                                <FormHeader label={'Business Info'} />
                            </div>
                            <BusinessInfoComponent />
                        </div>
                        <div className='contact-info'>
                            <div className='contact-info-header'>
                                <FormHeader label={'Contact(s)'} showAddButton={isEditing} buttonLabel={'+ Add contact'} onAddClick={handleAddContact}/>
                            </div>
                            {
                                selectedVendorInputs.contacts ? selectedVendorInputs.contacts.map((contact, idx) => (
                                    <div className='contact-info-form'>
                                        <BusinessContactFormComponent idx={idx} />
                                    </div>
                                )) : 'No Contacts Added!'
                            }
                        </div>
                    </div>
                    <div className='vendor-reseller-detail-section'>
                        <div className="reseller-info">
                            <div className='reseller-info-header'>
                                <FormHeader label={'Reseller Status'}/>
                            </div>
                            <div className="">
                                <FormComponent formFields={resellerStatusFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="account-no">
                            <div className='account-no-header'>
                                <FormHeader label={'Account Number'}/>
                            </div>
                            <div className="">
                                <FormComponent formFields={accountNumberFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="type-info">
                            <div className='type-info-header'>
                                <FormHeader label={'Type'}/>
                            </div>
                            <div className="">
                                <FormComponent formFields={typeFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="balance-info">
                            <div className='balance-info-header'>
                                <FormHeader label={'Balance'}/>
                            </div>
                            <div className="">
                                <FormComponent formFields={balanceFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="credit-info">
                            <div className='credit-info-header'>
                                <FormHeader label={'Credits'} />
                            </div>
                            <div className="">
                                <FormComponent formFields={creditFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="minimum-purchase-info">
                            <div className='minimum-purchase-info-header'>
                                <FormHeader label={'Minimum Purchase'} />
                            </div>
                            <div className="">
                                <FormComponent formFields={minimumPurchaseFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                        <div className="currency-info">
                            <div className='currency-info-header'>
                                <FormHeader label={'Currency'} />
                            </div>
                            <div className="">
                                <FormComponent formFields={currencyFormFields} viewOnly={!isEditing}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

        </div>

    )
}

const mapStateToProps = ({ vendorsState }) => {
    const selectedVendorInputs = vendorsState.selectedVendorInputs;
    return {
        loading: vendorsState.loading,
        isEditing: vendorsState.isEditing,
        success: vendorsState.success,
        id: vendorsState.id,
        error: vendorsState.error,
        selectedVendorInputs,
    }
}

export default connect(mapStateToProps, {
    updateVendorStateValues,
    updateVendorFormStateValues,
    resetVendorInputsValues,
    updateVendorDetailsAction,
})(VendorDetailInfoTabPaneComponent);
