import {
    GET_ORDER,
    GET_ORDER_FAIL,
    GET_ORDER_SUCCESS,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_FAIL,
    GET_ALL_ORDERS_SUCCESS,
    POST_ORDER, POST_ORDER_FAIL,
    POST_ORDER_SUCCESS, PUT_ORDER, PUT_ORDER_FAIL, PUT_ORDER_SUCCESS, RESET_FORM_VALUES, UPDATE_FORM_VALUES,
    UPDATE_VALUES,
} from "../action-types/orders.action-type";

const initialState = {
    orders: [],
    selectedOrder: {

    },
    selectedOrderInputs: {
    },
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const OrdersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_FORM_VALUES:
            console.log({payload});
            state = { ...state, selectedOrderInputs: { ...state.selectedOrderInputs, ...payload} };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedOrderInputs: payload.isNew ? initialState.selectedOrderInputs : state.selectedOrder, isEditing: false };
            break;
        case GET_ORDER:
            state = { ...state, loading: true };
            break;
        case GET_ORDER_SUCCESS:
            const parsedOrder = {
                ...payload.order,
            }
            state = { ...state, selectedOrder: parsedOrder, selectedOrderInputs: parsedOrder, loading: false, success: payload.message };
            console.log(state);
            break;
        case GET_ORDER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_ALL_ORDERS:
            state = { ...state, loading: true };
            break;
        case GET_ALL_ORDERS_SUCCESS:
            state = { ...state, orders: payload.rows, loading: false };
            break;
        case GET_ALL_ORDERS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_ORDER:
            state = { ...state, loading: true };
            break;
        case POST_ORDER_SUCCESS:
            state = { ...state, selectedOrder: payload.order, loading: false, success: 'New order created successfully!', isEditing: false };
            break;
        case POST_ORDER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_ORDER:
            state = { ...state, loading: true };
            break;
        case PUT_ORDER_SUCCESS:
            state = { ...state, selectedOrder: initialState.order, loading: false, isEditing: false, success: 'order updated successfully' };
            break;
        case PUT_ORDER_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default OrdersReducer;
