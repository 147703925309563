import API from "../interceptor/axios.interceptor.service";

class BrandsBackendApiService {
    static async getBrandDetail(id) {
        const url = `/api/v1/brands/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching brand detail, please try again!',
            }
        }
    }

    static async createNewBrand(payload) {
        const url = `/api/v1/brands`;
        try {
            const response = await API.post(url, payload);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong creating the new brand!',
            }
        }
    }

    static async getBrandsList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/brands/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}` : `/api/v1/brands/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching brands list!',
            }
        }
    }

    static async updateBrandDetails(payload) {
        const url = `/api/v1/brands/${payload.id}`;
        try {
            const response = await API.put(url, {
                id: payload.id,
                name: payload.name,
                description: payload.description,
            });
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while updating the brand detail!',
            }
        }
    }
}

export default BrandsBackendApiService;