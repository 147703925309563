import {
    GET_BRAND,
    GET_BRAND_FAIL,
    GET_BRAND_SUCCESS,
    GET_BRANDS,
    GET_BRANDS_FAIL,
    GET_BRANDS_SUCCESS,
    POST_BRAND,
    POST_BRAND_FAIL,
    POST_BRAND_SUCCESS,
    PUT_BRAND,
    PUT_BRAND_FAIL,
    PUT_BRAND_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/brands.action-type";

export const resetBrandInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
});

export const updateBrandStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const getBrandDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_BRAND,
        payload,
    };
};

export const getBrandDetailsSuccessAction = (payload) => {
    return {
        type: GET_BRAND_SUCCESS,
        payload,
    };
};

export const getBrandDetailsFailAction = (payload) => {
    return {
        type: GET_BRAND_FAIL,
        payload,
    };
};


export const updateBrandDetailsAction = (payload) => {
    return {
        type: PUT_BRAND,
        payload,
    };
};

export const updateBrandDetailsSuccessAction = (payload) => {
    return {
        type: PUT_BRAND_SUCCESS,
        payload,
    };
};

export const updateBrandDetailsFailAction = (payload) => {
    return {
        type: PUT_BRAND_FAIL,
        payload,
    };
};


export const createNewBrandAction = (payload) => {
    return {
        type: POST_BRAND,
        payload,
    };
};

export const createNewBrandSuccessAction = (payload) => {
    return {
        type: POST_BRAND_SUCCESS,
        payload
    };
};

export const createNewBrandFailAction = (payload) => {
    return {
        type: POST_BRAND_FAIL,
        payload,
    };
};

export const getBrandsListAction = (payload) => ({
    type: GET_BRANDS,
    payload
});

export const getBrandsListSuccessAction = (payload) => {
    return {
        type: GET_BRANDS_SUCCESS,
        payload
    };
};

export const getBrandsListFailAction = (payload) => {
    return {
        type: GET_BRANDS_FAIL,
        payload,
    };
};
