import {
    GET_PRODUCT,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
    POST_PRODUCT,
    POST_PRODUCT_FAIL,
    POST_PRODUCT_SUCCESS,
    PUT_PRODUCT,
    PUT_PRODUCT_FAIL,
    PUT_PRODUCT_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
} from "../action-types/products.action-type";

export const resetProductInputsValues = (isNew) => ({
    type: RESET_FORM_VALUES,
    payload: { isNew },
});

export const updateProductStateValues = (payload) => {
    return {
        type: UPDATE_VALUES,
        payload,
    };
};

export const getProductDetailsAction = (payload) => {
    console.log(payload);
    return {
        type: GET_PRODUCT,
        payload,
    };
};

export const getProductDetailsSuccessAction = (payload) => {
    return {
        type: GET_PRODUCT_SUCCESS,
        payload,
    };
};

export const getProductDetailsFailAction = (payload) => {
    return {
        type: GET_PRODUCT_FAIL,
        payload,
    };
};


export const updateProductDetailsAction = (payload) => {
    return {
        type: PUT_PRODUCT,
        payload,
    };
};

export const updateProductDetailsSuccessAction = (payload) => {
    return {
        type: PUT_PRODUCT_SUCCESS,
        payload,
    };
};

export const updateProductDetailsFailAction = (payload) => {
    return {
        type: PUT_PRODUCT_FAIL,
        payload,
    };
};


export const createNewProductAction = (payload) => {
    return {
        type: POST_PRODUCT,
        payload,
    };
};

export const createNewProductSuccessAction = (payload) => {
    return {
        type: POST_PRODUCT_SUCCESS,
        payload
    };
};

export const createNewProductFailAction = (payload) => {
    return {
        type: POST_PRODUCT_FAIL,
        payload,
    };
};

export const getProductsListAction = (payload) => ({
    type: GET_PRODUCTS,
    payload
});

export const getProductsListSuccessAction = (payload) => {
    return {
        type: GET_PRODUCTS_SUCCESS,
        payload
    };
};

export const getProductsListFailAction = (payload) => {
    return {
        type: GET_PRODUCTS_FAIL,
        payload,
    };
};
