import React, {useEffect, useState} from 'react';
import {Checkbox, Empty} from 'antd';
import {ACCESS_TYPES} from "../../../constants/Permissions.constant";
import './index.scss';
import GroupsParserService from "../../../services/parsers/groups.parser.service";

const CheckboxGroup = Checkbox.Group;

const accessTypeOptions = [ACCESS_TYPES.CREATE, ACCESS_TYPES.VIEW, ACCESS_TYPES.UPDATE, ACCESS_TYPES.DELETE];

const EffectiveGroupPermission = ({searchedPermissions, groupsList = []}) => {
    const [effectivePermissions, setEffectivePermissions] = useState({});

    useEffect(() => {
        if (groupsList.length) {
            const { parsedGroupsPermissionMap } = GroupsParserService.parseEffectivePermissionList(groupsList);
            setEffectivePermissions(parsedGroupsPermissionMap);
        }
    }, [groupsList]);

    return (
        <div className={'permission-selector-container'}>
            {
                searchedPermissions.map(perm => {
                    if (Object.keys(effectivePermissions).indexOf(perm.name) !== -1) {
                        return (
                            <div className='permission-container'>
                                <div className='permission-name'>
                                    Id: [{perm.id}], Name: [{perm.name}], Type: [{perm.type}]
                                </div>
                                <div className='permission-description'>
                                    {perm.description}
                                </div>
                                <div className='permission-options'>
                                    <CheckboxGroup
                                        options={accessTypeOptions}
                                        value={effectivePermissions[perm.name] || []}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        return null;
                    }
                })
            }
            {
                searchedPermissions.length !== 0 && Object.keys(effectivePermissions).length === 0 && (
                    <div className='d-flx'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                )
            }
        </div>
    );
}

export default EffectiveGroupPermission;