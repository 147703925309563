export const UPDATE_VALUES = "UPDATE_GROUP_VALUES";

export const RESET_FORM_VALUES = "RESET_GROUP_FORM_VALUES";

export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAIL = "GET_GROUP_FAIL";

export const UPDATE_GROUPS_PRIORITY = "UPDATE_GROUPS_PRIORITY";
export const UPDATE_GROUPS_PRIORITY_SUCCESS = "UPDATE_GROUPS_PRIORITY_SUCCESS";
export const UPDATE_GROUPS_PRIORITY_FAIL = "UPDATE_GROUPS_PRIORITY_FAIL";

export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const SEARCH_GROUPS_SUCCESS = "SEARCH_GROUPS_SUCCESS";
export const SEARCH_GROUPS_FAIL = "SEARCH_GROUPS_FAIL";

export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";

export const POST_GROUP = "POST_GROUP";
export const POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS";
export const POST_GROUP_FAIL = "LOGIN_FAIL";

export const PUT_GROUP = "PUT_GROUP";
export const PUT_GROUP_SUCCESS = "PUT_GROUP_SUCCESS";
export const PUT_GROUP_FAIL = "PUT_GROUP_FAIL";