import React, {useEffect, useState} from "react";
import LayoutComponent from "../../components/shared/layout/Layout";
import {Button, message, Modal, Table} from "antd";
import {Link} from "react-router-dom";
import {
    getBrandsListAction,
    resetBrandInputsValues,
    updateBrandStateValues
} from "../../store/actions/brands.action";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";

const BrandsPage = ({ getBrandsListAction, brands, loading, error, success, updateBrandStateValues }) => {

    const navigate = useHistory();

    const [pageNo, setPageNo] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)

    const [orderKey, setOrderKey] = useState('priority')
    const [orderDirection, setOrderDirection] = useState('asc')

    const [showOrderModal, setShowPriorityModal] = useState(false)

    const [showBrandDeleteModal, setShowBrandDeleteModal] = useState(false)
    const [userToDelete, setBrandToDelete] = useState({});

    const userBrandsTableColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'action',
            width: 50,
            render: (id) => (
                <Link to={`/brands/${id}`} key={id}>
                    {id}
                </Link>
            ),
        },{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: {
                compare: (a, b, sortDirection) => {
                    setOrderKey('description')
                    if(sortDirection==='descend') setOrderDirection('desc')
                    if(sortDirection==='ascend') setOrderDirection('asc')
                },
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Button className='margin-2' onClick={() => handleViewBrand(record.id)}>View Brand</Button>
                    {/*<Button danger className='margin-2' onClick={() => handleDeleteConfirmation(record)}>Delete Brand</Button>*/}
                </>
            ),
        }
    ]

    const handleViewBrand = (id) => {
        console.log(showOrderModal)
        navigate.push(`/brands/${id}`);
    }

    const handleDeleteBrand = () => {
        // TODO remove these console from here (using to remove unused variable error)
        console.log(setPageNo, setPageLimit, orderKey, orderDirection, setShowPriorityModal);
        setShowBrandDeleteModal(false)
        // deleteBrandAPI(userToDelete)
    }

    const cancelBrandDelete = () => {
        setBrandToDelete({})
        setShowBrandDeleteModal(false)
    }

    const createUserBrand = () => {
        resetBrandInputsValues(true);
        navigate.push('/brands/new');
    }

    useEffect(() => {
        // Call the API to fetch the brands list
        getBrandsListAction({
            pageNo,
            pageLimit,
            orderKey,
            orderDirection,
        });
    }, [getBrandsListAction, pageNo, pageLimit, orderKey, orderDirection]);

    useEffect(() => {
        // Call the API to fetch the brands list
       if (!loading) {
           if (error) {
               message.error(error).then(() => {
                   updateBrandStateValues({
                       error: '',
                   })
               });
           } else if (success) {
               message.success(success).then(() => {
                   updateBrandStateValues({
                       error: '',
                   })
               });
           }
       }
    }, [loading, error, success, updateBrandStateValues]);

    return (
        <LayoutComponent>
            <div className='d-flx margin-8-bottom gap-8 jc-left'>
                <Button onClick={createUserBrand}>Create New Brand</Button>
            </div>
                <Table
                    dataSource={brands}
                    columns={userBrandsTableColumns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                />
            <Modal
                title={<b>Are you sure?</b>}
                visible={showBrandDeleteModal}
                onOk={handleDeleteBrand}
                onCancel={cancelBrandDelete}
                okText={'Delete'}
                okButtonProps={{ type: 'danger' }}
            >Remove Brand {userToDelete.name}?</Modal>
        </LayoutComponent>
    )
}

const mapStateToProps = ({ brandsState }) => {
    const {
        brands, loading, error, success,
    } = brandsState;

    return {
        brands,
        loading,
        error,
        success,
    }
}

export default connect(mapStateToProps, {
    getBrandsListAction,
    updateBrandStateValues,
})(BrandsPage);
