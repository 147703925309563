import {Button, Card, message} from "antd";
import './index.scss';
import {connect} from "react-redux";
import {
    resetLeadTimesInputsValues,
    updateLeadTimesStateValues,
    updateLeadTimesFormStateValues,
    createNewLeadTimesForVendorAction, getLeadTimesForVendorListAction,
} from "../../../../store/actions/lead-times.action";
import BreadcrumbComponent from "../../../../components/shared/breadcrumb/Breadcrumb";
import VendorsTabs from "../../../../components/vendors/components/vendors-tabs";
import {useCallback, useEffect, useState} from "react";
import {leadTimeInitialState} from "../../../../store/reducers/lead-times.reducer";
import LeadTimeFormItem from "../../../../components/shared/lead-time-form-item";
import {getLeadTimeOptionsAction} from "../../../../store/actions/lead-time-options.action";
import {useParams} from "react-router-dom";

const VendorLeadTimeTabPaneComponent = ({ getLeadTimeOptionsAction, createNewLeadTimesForVendorAction,resetLeadTimesInputsValues, updateLeadTimesStateValues, updateLeadTimesFormStateValues, getLeadTimesForVendorListAction, isEditing, loading, success, error, fbaInputs, fbmInputs,fbaDeletes, fbmDeletes, leadTimeOptions}) => {

    const vendorId = useParams().id;

    const handleEdit = useCallback(() => {
        updateLeadTimesStateValues({
            isEditing: true,
        })
        setActionButtons([{
            label: 'Cancel',
            type: 'secondary',
            onClick: handleCancel,
        }, {
            label: 'Save',
            type: 'primary',
            onClick: handleSave,
        }])
        // eslint-disable-next-line
    }, [updateLeadTimesStateValues])

    const handleCancel = useCallback(() => {
        resetLeadTimesInputsValues();
        setActionButtons([{
            label: 'Edit',
            type: 'primary',
            onClick: handleEdit,
        }])
        // eslint-disable-next-line
    }, [resetLeadTimesInputsValues])

    const handleSave = useCallback(() => {
        console.log({vendorId});
        createNewLeadTimesForVendorAction({vendorId});
    }, [createNewLeadTimesForVendorAction, vendorId])

    const [actionButtons, setActionButtons] = useState([{
        label: 'Edit',
        type: 'primary',
        onClick: handleEdit,
    }]);

    useEffect(() => {
        if (!loading) {
            if (success) {
                message.success(success).then(() => {
                    updateLeadTimesStateValues({
                        success: '',
                    });
                    setActionButtons([{
                        label: 'Edit',
                        type: 'primary',
                        onClick: handleEdit,
                    }])
                })
            } else if (error) {
                message.error(error).then(() => {
                    updateLeadTimesStateValues({
                        error: '',
                    });
                    setActionButtons([{
                        label: 'Cancel',
                        type: 'secondary',
                        onClick: handleCancel,
                    }, {
                        label: 'Save',
                        type: 'primary',
                        onClick: handleSave,
                    }])
                })
            }

        }
    }, [loading, success, error, handleCancel, handleEdit, handleSave, createNewLeadTimesForVendorAction, updateLeadTimesStateValues])

    useEffect(() => {
        // Fetch the list of lead time options
        getLeadTimeOptionsAction();
        // fetch the list of lead times
        getLeadTimesForVendorListAction({vendorId});
        // eslint-disable-next-line
    }, [])

    const handleAddFBMLeadTime = () => {
       updateLeadTimesFormStateValues({
           fbm: {
               del: fbmDeletes,
               updates: [
                   ...fbmInputs,
                   {...leadTimeInitialState, idx: fbmInputs.length + 1, isFBM: true,},
               ]
           },
       });
    }

    const handleAddFBALeadTime = () => {
        updateLeadTimesFormStateValues({
            fba: {
                del: fbaDeletes,
                updates: [
                    ...fbaInputs,
                    {...leadTimeInitialState, idx: fbaInputs.length + 1, isFBA: true},
                ]
            },
        });
    }

    return (
        <div>
            <BreadcrumbComponent actionButtons={actionButtons} />
            <Card size="small">
                <VendorsTabs />
                <div className='vendor-detail-info-tab-pane-component'>
                    <div className='vendor-detail-section'>
                        <div className='contact-info'>
                            <div className='contact-info-label detail-label'>
                                <div className='detail-label-content'>
                                    <div className="label">FBA Lead Time</div>
                                    {
                                        isEditing && (
                                            <Button type='outline' onClick={handleAddFBALeadTime}>+ Add Step</Button>
                                        )
                                    }
                                </div>
                                <div className="divider"></div>
                            </div>
                            {
                                fbaInputs ? fbaInputs.map((item) => (
                                    <div className='contact-info-form'>
                                        <LeadTimeFormItem shipmentType="fba" idx={item.idx} leadTimeOptions={leadTimeOptions}/>
                                    </div>
                                )) : null
                            }
                        </div>
                        <div className='contact-info'>
                            <div className='contact-info-label detail-label'>
                                <div className='detail-label-content'>
                                    <div className="label">FBM Lead Time</div>
                                    {
                                        isEditing && (
                                            <Button type='outline' onClick={handleAddFBMLeadTime}>+ Add Step</Button>
                                        )
                                    }
                                </div>
                                <div className="divider"></div>
                            </div>
                            {
                                fbmInputs ? fbmInputs.map((item) => (
                                    <div className='contact-info-form'>
                                        <LeadTimeFormItem shipmentType={'fbm'} idx={item.idx} leadTimeOptions={leadTimeOptions}/>
                                    </div>
                                )) : null
                            }
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = ({ leadTimesState, leadTimeOptionsState }) => {
    const leadTimeOptions = leadTimeOptionsState.leadTimeOptions.map(val => ({ ...val, value: val.id, label: val.name, }))
    return {
        loading: leadTimesState.loading,
        isEditing: leadTimesState.isEditing,
        success: leadTimesState.success,
        id: leadTimesState.id,
        error: leadTimesState.error,
        fbaInputs: leadTimesState.vendorsLeadTimeInputs.fba.updates.sort((item1, item2) => +item1.order > +item2.order ? -1: 1),
        fbmInputs: leadTimesState.vendorsLeadTimeInputs.fbm.updates.sort((item1, item2) => +item1.order > +item2.order ? -1: 1),
        fbaDeletes: leadTimesState.vendorsLeadTimeInputs.fba.del,
        fbmDeletes: leadTimesState.vendorsLeadTimeInputs.fbm.del,
        leadTimeOptions,
    }
}

export default connect(mapStateToProps, {
    updateLeadTimesStateValues,
    updateLeadTimesFormStateValues,
    resetLeadTimesInputsValues,
    getLeadTimeOptionsAction,
    getLeadTimesForVendorListAction,
    createNewLeadTimesForVendorAction,
})(VendorLeadTimeTabPaneComponent);
