export const UPDATE_OPTION_VALUES = "LEAD_TIME_OPTION.UPDATE_OPTION_VALUES";

export const UPDATE_OPTION_FORM_VALUES = "LEAD_TIME_OPTION.UPDATE_FORM_VALUES";

export const RESET_OPTION_FORM_VALUES = "LEAD_TIME_OPTION.RESET_FORM_OPTION_VALUES";

export const GET_LEAD_TIME_OPTIONS = "LEAD_TIME_OPTION.GET_LEAD_TIME_OPTIONS";
export const GET_LEAD_TIME_OPTIONS_SUCCESS = "LEAD_TIME_OPTION.GET_LEAD_TIME_OPTIONS_SUCCESS";
export const GET_LEAD_TIME_OPTIONS_FAIL = "LEAD_TIME_OPTION.GET_LEAD_TIME_OPTIONS_FAIL";

export const POST_LEAD_TIME_OPTIONS = "LEAD_TIME_OPTION.POST_LEAD_TIME_OPTION";
export const POST_LEAD_TIME_OPTIONS_SUCCESS = "LEAD_TIME_OPTION.POST_LEAD_TIME_OPTION_SUCCESS";
export const POST_LEAD_TIME_OPTIONS_FAIL = "LEAD_TIME_OPTION.POST_LEAD_TIME_OPTION_FAIL";
