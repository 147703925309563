export const UPDATE_VALUES = "UPDATE_CATEGORY_VALUES";

export const RESET_FORM_VALUES = "RESET_CATEGORY_FORM_VALUES";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const POST_CATEGORY = "POST_CATEGORY";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAIL = "LOGIN_FAIL";

export const PUT_CATEGORY = "PUT_CATEGORY";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";
export const PUT_CATEGORY_FAIL = "PUT_CATEGORY_FAIL";