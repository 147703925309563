import API from "../interceptor/axios.interceptor.service";

class PermissionsBackendApiService {
    static async getPermissionDetail(id) {
        const url = `/api/v1/permissions/${id}`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching permission detail, please try again!',
            }
        }
    }

    static async getPermissionsList(payload) {
        const url = payload && payload.pageNo && payload.pageLimit ?  `/api/v1/permissions/?pageNo=${payload.pageNo}&pageLimit=${payload.pageLimit}&type=${payload.type}` : `/api/v1/permissions/`;
        try {
            const response = await API.get(url);
            return response.data;
        } catch (e) {
            const message = e && e.response && e.response.data ? e.response.data.message : e.message;
            return {
                success: false,
                message: message || 'Something went wrong while fetching permissions list!',
            }
        }
    }
}

export default PermissionsBackendApiService;