import {put, takeLatest, call, select} from "redux-saga/effects";

import {GET_ORDER, PUT_ORDER, GET_ALL_ORDERS, POST_ORDER} from '../action-types/orders.action-type';

import OrdersBackendApiService from "../../services/backend-api/orders.backend-api.service";
import {
    createNewOrderFailAction,
    createNewOrderSuccessAction,
    getOrderDetailsFailAction,
    getOrderDetailsSuccessAction, getOrdersListFailAction,
    getOrdersListSuccessAction, updateOrderDetailsFailAction, updateOrderDetailsSuccessAction
} from "../actions/orders.action";

function* orderDetails({payload}) {
    try {
        const response = yield call(OrdersBackendApiService.getOrderDetail, payload);
        console.log(response);
        if (response.success) {
            const parsedPayload = {
                ...response.data,
            }
            yield put(getOrderDetailsSuccessAction(parsedPayload));
        } else {
            yield put(getOrderDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(getOrderDetailsFailAction(error.response));
    }
}

function* getOrdersList({payload}) {
    try {
        const response = yield call(OrdersBackendApiService.getOrdersList, payload);
        console.log({response});
        if (response.success) {
            yield put(getOrdersListSuccessAction({
                ...response.data,
                rows: response.data.rows.map((row) => {
                    return {
                        ...row,
                    }
                })
            }));
        } else {
            yield put(getOrdersListFailAction(response.message));
        }
    } catch (error) {
        console.log(error);
        yield put(getOrdersListFailAction(error.response));
    }
}

function* updateOrderDetails() {
    try {
        const orderPayload = yield select(({ordersState}) => ordersState.selectedOrderInputs);
        const response = yield call(OrdersBackendApiService.updateOrderDetails, {
            ...orderPayload,
        });
        if (response.success) {
            yield put(updateOrderDetailsSuccessAction(response));
        } else {
            yield put(updateOrderDetailsFailAction(response.message));
        }
    } catch (error) {
        yield put(updateOrderDetailsFailAction(error.response));
    }
}


function* createNewOrder() {
    try {
        const orderPayload = yield select(({ordersState}) => ordersState.selectedOrderInputs);
        const response = yield call(OrdersBackendApiService.createNewOrder, orderPayload);
        if (response.success) {
            yield put(createNewOrderSuccessAction(response));
        } else {
            yield put(createNewOrderFailAction(response.message));
        }
    } catch (error) {
        console.log({error});
        yield put(createNewOrderFailAction(error.response));
    }
}

function* OrdersSaga() {
    yield takeLatest(GET_ORDER, orderDetails);
    yield takeLatest(GET_ALL_ORDERS, getOrdersList);
    yield takeLatest(POST_ORDER, createNewOrder);
    yield takeLatest(PUT_ORDER, updateOrderDetails);
}

export default OrdersSaga;
