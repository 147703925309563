export const ACCESS_TYPES = {
    VIEW: 'VIEW',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
}

export const PERMISSIONS_TYPES = {
    RESOURCE: 'RESOURCE',
    RECORD_RESOURCE: 'RECORD_RESOURCE',
}
