import {
    GET_PERMISSION,
    GET_PERMISSION_FAIL,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSIONS,
    GET_PERMISSIONS_FAIL,
    GET_PERMISSIONS_SUCCESS,
    POST_PERMISSION,
    POST_PERMISSION_FAIL,
    POST_PERMISSION_SUCCESS,
    PUT_PERMISSION,
    PUT_PERMISSION_FAIL,
    PUT_PERMISSION_SUCCESS,
    SEARCH_PERMISSIONS, SEARCH_PERMISSIONS_FAIL,
    SEARCH_PERMISSIONS_SUCCESS,
    UPDATE_VALUES,
} from "../action-types/permissions.action-type";

const initialState = {
    permissions: [],
    selectedPermission: {},
    searchedPermissions: [],
    selectedPermissionInputs: {},
    loading: false,
    error: '',
    success: '',
};

const PermissionsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_PERMISSION:
            state = { ...state, loading: true };
            break;
        case GET_PERMISSION_SUCCESS:
            state = { ...state, selectedPermission: payload.permission, selectedPermissionInputs: payload.permission, loading: false, success: payload.message };
            break;
        case GET_PERMISSION_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case SEARCH_PERMISSIONS:
            state = { ...state, loading: true };
            break;
        case SEARCH_PERMISSIONS_SUCCESS:
            state = { ...state, searchedPermissions: payload.rows, selectedPermissionInputs: payload.permission, loading: false, success: payload.message };
            break;
        case SEARCH_PERMISSIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_PERMISSIONS:
            state = { ...state, loading: true };
            break;
        case GET_PERMISSIONS_SUCCESS:
            state = { ...state, permissions: payload.rows, loading: false };
            break;
        case GET_PERMISSIONS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_PERMISSION:
            state = { ...state, loading: true };
            break;
        case POST_PERMISSION_SUCCESS:
            state = { ...state, selectedPermission: initialState.permission, loading: false, success: 'New Permission created successfully!' };
            break;
        case POST_PERMISSION_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_PERMISSION:
            state = { ...state, loading: true };
            break;
        case PUT_PERMISSION_SUCCESS:
            state = { ...state, selectedPermission: initialState.permission, loading: false, success: 'permission updated successfully' };
            break;
        case PUT_PERMISSION_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default PermissionsReducer;
