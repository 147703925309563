import { all, fork } from 'redux-saga/effects';

import AuthSaga from './auth.saga';
import UsersSaga from "./users.saga";
import PermissionsSaga from "./permissions.saga";
import GroupsSaga from "./groups.saga";
import RolesSaga from "./roles.saga";
import SuppliersSaga from "./suppliers.saga";
import BrandsSaga from "./brands.saga";
import CategorySaga from "./category.saga";
import ProductsSaga from "./products.saga";
import VendorsSaga from "./vendors.saga";
import LeadTimesSaga from "./lead-times.saga";
import LeadTimeOptionsSaga from "./lead-time-options.saga";
import CostOptionsSaga from "./cost-options.saga";
import CostsSaga from "./costs.saga";
import OrdersSaga from "./orders.saga";

export default function* rootSaga() {
    yield all([fork(AuthSaga)]);
    yield all([fork(UsersSaga)]);
    yield all([fork(PermissionsSaga)]);
    yield all([fork(GroupsSaga)]);
    yield all([fork(LeadTimesSaga)]);
    yield all([fork(LeadTimeOptionsSaga)]);
    yield all([fork(RolesSaga)]);
    yield all([fork(SuppliersSaga)]);
    yield all([fork(BrandsSaga)]);
    yield all([fork(CategorySaga)]);
    yield all([fork(ProductsSaga)]);
    yield all([fork(VendorsSaga)]);
    yield all([fork(CostOptionsSaga)]);
    yield all([fork(CostsSaga)]);
    yield all([fork(OrdersSaga)]);
}
