import {
    GET_VENDOR,
    GET_VENDOR_FAIL,
    GET_VENDOR_SUCCESS,
    GET_VENDORS,
    GET_VENDORS_FAIL,
    GET_VENDORS_SUCCESS,
    POST_VENDOR, POST_VENDOR_FAIL,
    POST_VENDOR_SUCCESS, PUT_VENDOR, PUT_VENDOR_FAIL, PUT_VENDOR_SUCCESS, RESET_FORM_VALUES, UPDATE_FORM_VALUES,
    UPDATE_VALUES,
} from "../action-types/vendors.action-type";
import {SuppliersStatus} from "../../constants/suppliers.constant";

export const addressInitialState = {
    line1: '',
    line2: '',
    city: '',
    zip: '',
    state: '',
    country: '',
};

export const contactInitialState = {
    title: '',
    isPrimaryContact: false,
    firstName: '',
    lastName: '',
    websiteLink: '',
    email: '',
    phone: '',
    extension: '',
    isAddressSameAsVendor: false,
    address: addressInitialState,
}

const initialState = {
    vendors: [],
    selectedVendor: {
        name: '',
        isDistributor: false,
        isPrepCenter: false,
        resellerStatus: SuppliersStatus.PENDING,
        accountNumber: '',
        websiteLink: '',
        email: '',
        phone: '',
        balance: 0,
        credit: 0,
        currency: '',
        minimumPurchase: 0,
        address: addressInitialState,
        contacts: [{idx: 0, ...contactInitialState}]
    },
    selectedVendorInputs: {
        name: '',
        isDistributor: false,
        isPrepCenter: false,
        resellerStatus: SuppliersStatus.PENDING,
        accountNumber: '',
        websiteLink: '',
        email: '',
        phone: '',
        balance: 0,
        credit: 0,
        currency: '',
        minimumPurchase: 0,
        address: addressInitialState,
        contacts: [{idx: 0, ...contactInitialState}],
    },
    loading: false,
    isEditing: false,
    error: '',
    success: '',
};

const VendorsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case UPDATE_FORM_VALUES:
            console.log({payload});
            state = { ...state, selectedVendorInputs: { ...state.selectedVendorInputs, ...payload} };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedVendorInputs: payload.isNew ? initialState.selectedVendorInputs : state.selectedVendor, isEditing: false };
            break;
        case GET_VENDOR:
            state = { ...state, loading: true };
            break;
        case GET_VENDOR_SUCCESS:
            const parsedVendor = {
                ...payload.vendor,
            }
            state = { ...state, selectedVendor: parsedVendor, selectedVendorInputs: parsedVendor, loading: false, success: payload.message };
            console.log(state);
            break;
        case GET_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_VENDORS:
            state = { ...state, loading: true };
            break;
        case GET_VENDORS_SUCCESS:
            state = { ...state, vendors: payload.rows, loading: false };
            break;
        case GET_VENDORS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_VENDOR:
            state = { ...state, loading: true };
            break;
        case POST_VENDOR_SUCCESS:
            state = { ...state, selectedVendor: payload.vendor, loading: false, success: 'New vendor created successfully!', isEditing: false };
            break;
        case POST_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_VENDOR:
            state = { ...state, loading: true };
            break;
        case PUT_VENDOR_SUCCESS:
            state = { ...state, selectedVendor: initialState.vendor, loading: false, isEditing: false, success: 'vendor updated successfully' };
            break;
        case PUT_VENDOR_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default VendorsReducer;
