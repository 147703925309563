import {
    GET_GROUP,
    GET_GROUP_FAIL,
    GET_GROUP_SUCCESS,
    GET_GROUPS,
    GET_GROUPS_FAIL,
    GET_GROUPS_SUCCESS,
    POST_GROUP,
    POST_GROUP_FAIL,
    POST_GROUP_SUCCESS,
    PUT_GROUP,
    PUT_GROUP_FAIL,
    PUT_GROUP_SUCCESS,
    SEARCH_GROUPS,
    SEARCH_GROUPS_FAIL,
    SEARCH_GROUPS_SUCCESS,
    UPDATE_VALUES,
    RESET_FORM_VALUES,
    UPDATE_GROUPS_PRIORITY_SUCCESS,
    UPDATE_GROUPS_PRIORITY_FAIL,
    UPDATE_GROUPS_PRIORITY,
} from "../action-types/groups.action-type";
import GroupsParserService from "../../services/parsers/groups.parser.service";

const initialState = {
    groups: [],
    selectedGroup: {},
    searchedGroups: [],
    selectedGroupInputs: {
        name: '',
        description: '',
        selectedPermissions: {},
    },
    loading: false,
    error: '',
    success: '',
};

const GroupsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_VALUES:
            state = { ...state, ...payload };
            break;
        case GET_GROUP:
            state = { ...state, loading: true };
            break;
        case RESET_FORM_VALUES:
            state = { ...state,  selectedGroupInputs: payload.isNew ? initialState.selectedGroupInputs : state.selectedGroup };
            break;
        case GET_GROUP_SUCCESS:
            let parsedGroup = GroupsParserService.parseGroup(payload.group);
            state = { ...state, selectedGroup: parsedGroup, selectedGroupInputs: parsedGroup, loading: false, success: payload.message };
            break;
        case GET_GROUP_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case SEARCH_GROUPS:
            state = { ...state, loading: true };
            break;
        case SEARCH_GROUPS_SUCCESS:
            state = { ...state, searchedGroups: payload.rows, selectedGroupInputs: payload.group, loading: false, success: payload.message };
            break;
        case SEARCH_GROUPS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case GET_GROUPS:
            state = { ...state, loading: true };
            break;
        case GET_GROUPS_SUCCESS:
            state = { ...state, groups: payload.rows, loading: false };
            break;
        case GET_GROUPS_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case POST_GROUP:
            state = { ...state, loading: true };
            break;
        case POST_GROUP_SUCCESS:
            state = { ...state, selectedGroup: payload.group, loading: false, success: 'New Group created successfully!' };
            break;
        case POST_GROUP_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case PUT_GROUP:
            state = { ...state, loading: true };
            break;
        case PUT_GROUP_SUCCESS:
            state = { ...state, selectedGroup: payload.group, loading: false, success: 'group updated successfully' };
            break;
        case PUT_GROUP_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        case UPDATE_GROUPS_PRIORITY:
            state = { ...state, loading: true };
            break;
        case UPDATE_GROUPS_PRIORITY_SUCCESS:
            state = { ...state, groups: payload.rows, loading: false };
            break;
        case UPDATE_GROUPS_PRIORITY_FAIL:
            state = {
                ...state,
                error: payload,
                loading: false,
            };
            break;
        default:
            break;
    }
    return state;
};

export default GroupsReducer;
